import { FC } from 'react'
import styled from 'styled-components'

import { Symptom } from 'redux/modules/symptoms'
import IconSundhedDK from 'ui/components/generic/IconSundhedDK'
import IconLink from 'ui/components/generic/IconLink'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

type Props = {
  symptoms: Symptom[]
}

const NoResults: FC<Props> = ({ symptoms }) => {
  const initialSymptoms = symptoms.filter((s) => s.source === 'initial')
  return (
    <Wrapper>
      <Header>Ingen resultater</Header>
      <Text size="sm">
        <p>
          Vi kunne ikke i tilstrækkelig grad kvalificere et svar på baggrund af
          de angivne symptomer. Det kan skyldes flere årsager:
        </p>
        <ul>
          <li>
            At symptomerne er meget generelle og således kan skyldes en række
            forskellige lidelser.
          </li>
          <li>
            At symptomerne opleves anderledes end de fleste. SymptomTjekker
            baserer svar og resultater på de mest udbredte symptomer og
            beskrivelser, men nogle sygdomme kan opleves meget forskelligt fra
            menneske til menneske.
          </li>
          <li>
            At lidelsen eller et eller flere af symptomerne endnu ikke er
            understøttet og kendt af SymptomTjekker.
          </li>
        </ul>
        <Search>
          Via nedenstående link{initialSymptoms.length > 1 ? 's' : ''} kan du
          nemt søge efter dine symptomer på <IconSundhedDK />
        </Search>
        <Actions>
          {initialSymptoms.map((symptom: any) => (
            <Action
              href={`https://www.sundhed.dk/borger/patienthaandbogen/soeg/?SearchTerm=${symptom.name}`}
              target="_blank"
            >
              <IconLink />
              Søg efter "<strong>{symptom.name}</strong>" på sundhed.dk
            </Action>
          ))}
        </Actions>
        <p>
          Hvis du har fundet en fejl eller har en kommentar til interviewet kan
          du kontakte os på{' '}
          <a href="mailto:kontakt@odagroup.dk">kontakt@odagroup.dk</a>
        </p>
      </Text>
    </Wrapper>
  )
}

export default NoResults

const Wrapper = styled.div`
  margin-bottom: 23px;
  padding: 40px;
  border-radius: 25px;
  background-color: #fff;
`

const Header = styled(Text).attrs({ as: 'h1', size: 'lg', weight: 'medium' })``

const Search = styled.div`
  border-top: 1px solid ${(props: any) => props.theme.colors.tones.lighter};
  padding-top: 30px;
  display: flex;
  align-items: baseline;

  margin-top: 30px;

  svg {
    margin-left: 8px;
    width: 120px;
  }
`

const Actions = styled.div`
  padding: 20px 0;
`

const Action = styled(Button).attrs({
  size: 'large',
  color: 'primary',
  component: 'a',
})`
  margin-bottom: 10px;

  svg {
    display: block;
    margin-right: 10px;
  }
`
