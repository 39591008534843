import { createGlobalStyle } from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import styledNormalize from 'styled-normalize'

import responsiveSpacing from './responsiveSpacing'
import responsiveText from './responsiveText'
import fonts from './fonts'

export default createGlobalStyle`
  ${styledNormalize}

  body {
    margin: 0;
    padding: 0;
    color: #333;
    -webkit-font-smoothing: antialiased;
    background-color: ${(props) => props.theme.colors.tones.lightest};
    
    font-family: ${(props) => props.theme.bodyFont};
    font-weight: 200;
    font-size: ${(props) => props.theme.fontSizes.sm.lg.fs}px;
    letter-spacing: ${(props) =>
      props.theme.fontSizes.sm.lg.fs * props.theme.letterSpacing}px;
  }

  p {
    margin: 0;
    ${responsiveSpacing(1.5, 'margin-bottom')}

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    ${responsiveSpacing(2, 'margin-bottom')}
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  h3, h4, h5 {
    ${responsiveSpacing(1, 'margin-bottom')}
  }

  h1 {
    ${responsiveText('lg')}
  }

  h2 {
    ${responsiveText('md')}
  }

  h3 {
    ${responsiveText('sm')}
  }

  h4 {
    ${responsiveText('xs')}
  }

  h5 {
    ${responsiveText('xxs')}
  }

  * {
    box-sizing: border-box;
  }

  img, svg {
    display: block;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }

  strong {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.tones.lightest};

    ::placeholder {
      color: ${(props) => props.theme.colors.tones.light};
      font-weight: 200;
    }

    &:focus {
      outline: none;
    }
  }

  textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.tones.lightest};
    resize: none;

    ::placeholder {
      color: ${(props) => props.theme.colors.tones.light};
      font-weight: 200;
    }

    &:focus {
      outline: none;
    }
  }

  .responsiveTable {
    position: relative;
    width: 100%;
    overflow: hidden;;
    margin-bottom: 30px;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      width: 60px;
      background: linear-gradient(90deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
    }

    .responsiveTableInner {
      overflow-x: auto;
      padding-right: 60px;
    }
  }

  table {
    ${responsiveText('xs')}
    margin-bottom: 30px;

    td {
      padding: 20px;
      vertical-align: top;
    }
  }


  /**
   * Bootstrap utils
   **/

  .noGutters {
    margin-right: 0;
    margin-left: 0;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }

   /**
   * Tweak container padding on small devices
   **/
  ${Container} {

    @media(max-width: ${(props) => props.theme.grid.breakpoints.xs}px) {
      padding-left: ${(props) => props.theme.grid.container.paddingXs}px;
      padding-right: ${(props) => props.theme.grid.container.paddingXs}px;
    }
  }


  ${fonts}
`
