import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/store'

import { showOverlay, hideOverlay, OverlayKey } from '../redux/modules/overlay'

const useOverlay = () => {
  const dispatch = useDispatch()

  return {
    ...useSelector((state: RootState) => state.overlay),
    actions: {
      showOverlay: (key: OverlayKey, data?: any) =>
        dispatch(showOverlay(key, data)),
      hideOverlay: (key: OverlayKey) => dispatch(hideOverlay),
    },
  }
}

export default useOverlay
