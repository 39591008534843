import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Source } from 'redux/modules/symptoms'

import prepareAgeQuery from 'utils/prepareAgeQuery'
import usePatientInfo from './usePatientInfo'
import useSymptoms from './useSymptoms'

const useDiagnosis = () => {
  const { age, gender, interviewId, patient } = usePatientInfo()
  const { symptoms } = useSymptoms()

  const evidence = symptoms
    .filter((symptom) => !!symptom.choiceId)
    .map((symptom) => {
      const ev = {
        id: symptom.id,
        choice_id: symptom.choiceId,
      } as Evidence

      // Only add source if it has a value
      if (symptom.source) {
        ev.source = symptom.source
      }

      return ev
    })

  const { loading, data } = useQuery<DiagnosisResponse>(
    gql`
      query diagnosis {
        diagnosis(input: $input)
          @rest(type: "Diagnosis", path: "diagnosis/", method: "POST") {
          question @type(name: "Question") {
            type
            text
            explication
            instruction
            items @type(name: "QuestionItem") {
              id
              name
              explication
              instruction
              choices @type(name: "QuestionChoice") {
                id
                label
              }
            }
          }
          should_stop
          has_emergency_evidence
          conditions @type(name: "Condition") {
            id
            common_name
            name
            probability

            # details
            description
            descriptionSource
            link
            linkSource
            type
          }
        }
      }
    `,
    {
      variables: {
        input: {
          age: prepareAgeQuery(age),
          sex: gender,
          evidence,
          interviewId,
          extras: {
            disable_groups: false,
            enable_explanations: true,
            enable_third_person_questions: patient === 'other',
          },
        },
      },
      skip: gender === null || age === null,
    }
  )

  return {
    loading,
    data: !loading && data?.diagnosis ? data.diagnosis : null,
  }
}

export default useDiagnosis

export type Diagnosis = {
  question: Question
  conditions: Condition[]
  should_stop: boolean
  has_emergency_evidence: boolean
}

export type Question = {
  type: string
  text: string
  items: QuestionItem[]
  explication?: string
  instruction?: string
}

export type QuestionItem = {
  id: string
  name: string
  explication: string
  instruction: string
  choices: QuestionChoice[]
}

export type QuestionChoice = {
  id: string
  label: string
}

export type Condition = {
  id: string
  common_name: string
  name: string
  probability: number
  description?: string
  descriptionSource?: string
  link?: string
  linkSource?: string
  type?: string
}

type Evidence = {
  id: string
  choice_id?: string
  source?: Source
}

type DiagnosisResponse = {
  diagnosis: Diagnosis
}
