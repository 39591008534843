import { useRef, useCallback, FC, RefObject } from 'react'

import styled from 'styled-components'
import posed from 'react-pose'
import { useWindowSize } from 'react-use'
import { useDispatch } from 'react-redux'

import { showOverlay } from 'redux/modules/overlay'
import uniqBy from 'lodash/uniqBy'
import theme from 'ui/styles/theme'
import { SlideInOutGroup, SlideInOut, transition } from 'ui/styles/poses'
import useSymptoms from 'containers/useSymptoms'
import useSearchedSymptoms, {
  SearchedSymptom,
} from 'containers/useSearchedSymptoms'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

const SearchResults: FC<{ search: string }> = ({ search }) => {
  const wrapper = useRef<HTMLDivElement>(null)
  const { height, width } = useWindowSize(1000, 800)
  const {
    actions: { updateSymptom },
  } = useSymptoms()

  const resultsMaxHeight = getResultsMaxHeight(height, width, wrapper)

  const { data: searchedSymptoms } = useSearchedSymptoms(search)

  let searchResults: SearchedSymptom[] = []

  if (searchedSymptoms) {
    searchResults = uniqBy(searchedSymptoms, 'id')
  }

  const dispatch = useDispatch()

  const suggestSymptom = useCallback(() => {
    dispatch(showOverlay('suggestSymptom', search))
  }, [search, dispatch])

  return (
    <Wrapper ref={wrapper} height={resultsMaxHeight}>
      <SlideInOutGroup withParent={false} animateOnMount>
        {searchedSymptoms && searchedSymptoms.length > 0 && (
          <Results key="results" maxHeight={resultsMaxHeight}>
            {searchResults.map((symptom, index) => (
              <SymptomWrapper key={`${index}-${symptom.id}`}>
                <Symptom
                  onClick={() =>
                    updateSymptom(symptom, 'present', 'initial', [])
                  }
                >
                  {symptom.commonName || symptom.name || symptom.label}
                </Symptom>
              </SymptomWrapper>
            ))}
          </Results>
        )}
        {searchedSymptoms && searchedSymptoms.length === 0 && (
          <NoResults key="noResults">
            <SlideInOut direction="right" amount={20}>
              <Text size="xs" mb={20}>
                <p>Vi fandt ikke noget, som matcher din søgning.</p>
                <p>
                  Prøv eventuelt at formulere dig mere generelt fx.
                  <br />
                  <strong>hånd</strong> i stedet for <strong>højre hånd</strong>
                  <br />
                  <strong>finger</strong> i stedet for{' '}
                  <strong>lillefinger</strong>
                </p>
                <p>Hjælp os med at blive bedre ved at foreslå et symptom</p>
              </Text>
              <Button color="primary" onClick={suggestSymptom}>
                Foreslå symptom
              </Button>
            </SlideInOut>
          </NoResults>
        )}
      </SlideInOutGroup>
    </Wrapper>
  )
}

const getResultsMaxHeight = (
  height: number,
  width: number,
  wrapper: RefObject<HTMLDivElement>
) => {
  // mobile
  let distanceToBottom = height - theme.navHeight - 60

  // desktop
  if (width > theme.grid.breakpoints.xs) {
    // element is 60px, centered vertically and translated up 100 pixels
    const bottom = height / 2 + 30 - 100

    distanceToBottom = height - bottom
  }

  return distanceToBottom - 30
}

export default SearchResults

const ResultsPose = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 50,
    delayChildren: 200,
    transition,
  },
  exit: {
    opacity: 1,
    transition,
  },
  pre: {
    opacity: 1,
    transition,
  },
  flip: transition,
})

const Wrapper = styled.div<{ height: number }>`
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: ${(props) => props.height}px;
  z-index: 1;
  overflow: hidden;
  transition: all 500ms;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: ${(props: any) => props.height - 50}px;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    pointer-events: none;
  }
`

const SymptomWrapper = styled(SlideInOut).attrs({
  direction: 'down',
  amount: 20,
})`
  display: block;
`

const Results = styled(ResultsPose)`
  background-color: #fff;
  max-height: ${(props) => props.maxHeight}px;
  overflow: hidden;
  overflow-y: auto;
`

const NoResults = styled(ResultsPose)`
  display: block;
  padding: 20px;
  background-color: #fff;
`

const Symptom = styled.div`
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.tones.lightest};
  text-align: left;
  cursor: pointer;
`
