import { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export default (size = 2, prop = 'margin-bottom') => css`
  ${prop}: ${props => size * props.theme.spacing.xs}px;

  ${media.sm`
    ${prop}: ${props => size * props.theme.spacing.sm}px;
  `}

  ${media.md`
    ${prop}: ${props => size * props.theme.spacing.md}px;
  `}

  ${media.lg`
    ${prop}: ${props => size * props.theme.spacing.lg}px;
  `}

  ${media.xl`
    ${prop}: ${props => size * props.theme.spacing.xl}px;
  `}
`
