import { include } from 'named-urls'

const routes = {
  interview: include('/', {
    landing: '/',
    patientSelect: 'info/person',
    ageSelect: 'info/alder',
    genderSelect: 'info/koen',
    riskFactors: 'risikofaktorer',
    symptoms: '/symptomer',
    related: 'relaterede/:symptomId?',
    interview: 'interview/:questionIndex?',
    results: 'resultater',
  }),

  conditionList: '/sygdomme/:child(barn)?/:letter?/',

  contentPage: '/:slug',
}

export default routes
