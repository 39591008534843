import React from 'react'
import styled from 'styled-components'

const getDegrees = (direction) => {
  switch (direction) {
    case 'up':
      return -90
    case 'left':
      return 180
    case 'down':
      return 90
    default:
      return 0
  }
}

const Svg = styled.svg`
  transform: rotate(${(props) => getDegrees(props.direction)}deg);
  transition: 500ms;

  path {
    stroke: ${(props) => props.color || '#fff'};
    transition: 500ms;
  }
`

export default (props) => (
  <Svg viewBox="0 0 9 15" fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1 14 7-6.5L1 1"
    />
  </Svg>
)
