import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import IconInfo from 'ui/components/generic/IconInfo'
import IconQuestion from 'ui/components/generic/IconQuestion'
import Text from 'ui/components/generic/Text'

export default class Explication extends Component {
  static propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.oneOf(['instructions', 'info']),
  }

  state = {
    visible: false,
  }

  render() {
    const { text, color, type } = this.props
    const { visible } = this.state

    return (
      <Wrapper
        onClick={(e) => {
          e.stopPropagation()
          this.setState({ visible: !visible })
        }}
        onBlur={() => this.setState({ visible: false })}
        as="button"
        visible={visible}
      >
        {type === 'instructions' ? (
          <IconQuestion color={color} />
        ) : (
          <IconInfo color={color} />
        )}
        {visible && (
          <PopUp visible={visible}>
            <Text
              size="xs"
              dangerouslySetInnerHTML={{ __html: text }}
              align={type === 'instructions' ? 'left' : 'center'}
            />
          </PopUp>
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  z-index: ${(props) => (props.visible ? 18 : 17)};

  ${media.md`
    position: relative;
  `}

  // reset button
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  svg {
    display: inline-block;
    cursor: pointer;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
`

const PopUp = styled.div`
  position: absolute;

  margin-top: 10px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 500ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  color: #333;

  padding: 15px;
  background-color: #fff;

  top: 100%;
  left: 0px;
  transform: translateX(0);
  width: 100%;
  z-index: 18;

  ${media.md`
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 300px;
  `}

  font-weight: normal;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`
