import styled from 'styled-components'

const RoundButton = styled.button`
  // reset button styles
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  display: flex;
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  transition: background-color 0.3s ease-in-out;

  flex-shrink: 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }
`

export default RoundButton
