import { FC } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import useInterviewLogic from 'containers/useInterviewLogic'
import Spinner from 'ui/components/generic/Spinner'

import SingleQuestion from 'ui/components/views/SingleQuestion'
import GroupSingleQuestion from 'ui/components/views/GroupSingleQuestion'
import GroupMultipleQuestion from 'ui/components/views/GroupMultipleQuestion'
import Done from './Done'
import {
  ChoiceId,
  InputSymptom,
  Question,
  Source,
} from 'redux/modules/symptoms'

const Questions: FC<{ onContinue: Function }> = ({ onContinue }) => {
  const {
    loading,
    question,
    shouldStop,
    questionIndex,
    onAnswer,
    onAnswerMultiple,
    getChoiceId,
  } = useInterviewLogic(onContinue)

  return (
    <Wrapper>
      <AnimatePresence exitBeforeEnter initial={false}>
        {loading && !question && (
          <Content key="loading">
            <Spinner />
          </Content>
        )}
        {question && (
          <Content key={questionIndex}>
            {renderQuestion(question, onAnswer, onAnswerMultiple, getChoiceId)}
          </Content>
        )}
        {!loading && shouldStop && (
          <Content key="done">
            <Done onEnd={onContinue} />
          </Content>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

const renderQuestion = (
  question: Question,
  onAnswer: (
    symptom: InputSymptom,
    choiceId: ChoiceId,
    remove: string[] | undefined
  ) => void,
  onAnswerMultiple: (
    symptoms: { symptom: InputSymptom; choiceId: ChoiceId; source: Source }[]
  ) => void,
  getChoiceId: (symptom: any) => ChoiceId | null | undefined
) => {
  if (!question) return null

  switch (question.type) {
    case 'single':
      return (
        <SingleQuestion
          {...question}
          onAnswer={onAnswer}
          getChoiceId={getChoiceId}
        />
      )
    case 'group_single':
      return (
        <GroupSingleQuestion
          {...question}
          onAnswer={onAnswer}
          getChoiceId={getChoiceId}
        />
      )
    case 'group_multiple':
      return (
        <GroupMultipleQuestion
          {...question}
          onAnswer={onAnswerMultiple}
          getChoiceId={getChoiceId}
        />
      )
    default:
      return <pre>type: {question.type}</pre>
  }
}

export default Questions

const initial = {
  opacity: 0,
  y: 30,
}

const animate = {
  opacity: 1,
  y: 0,
}

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(motion.div).attrs({
  initial,
  animate,
  transition: { type: 'tween', duration: 0.5 },
})`
  width: 100%;
  margin: auto;
  text-align: center;
`
