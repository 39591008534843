import { FC } from 'react'

const IconPrint: FC<{ color?: string }> = ({ color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={23}
      fill="none"
      viewBox="0 0 20 23"
      {...rest}
    >
      <path
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 16.898c.932 0 1.398 0 1.765-.163a2.08 2.08 0 0 0 1.083-1.163c.152-.394.152-.894.152-1.895V9.593c0-1.2 0-1.802-.218-2.26a2.08 2.08 0 0 0-.875-.939c-.401-.22-.919-.233-1.907-.234m0 0H4m12 0H4m12 0v-.862c0-1.2 0-1.802-.218-2.26a2.08 2.08 0 0 0-.875-.94c-.427-.233-.987-.233-2.107-.233H7.2c-1.12 0-1.68 0-2.108.234a2.079 2.079 0 0 0-.874.938C4 3.497 4 4.098 4 5.301v.86m0 0c-.988 0-1.507.013-1.908.233a2.079 2.079 0 0 0-.874.938C1 7.792 1 8.394 1 9.596v4.08c0 1.002 0 1.502.152 1.896a2.08 2.08 0 0 0 1.082 1.163c.368.163.834.163 1.766.163m4-6.443h4m-5 4.296h6c.932 0 1.398 0 1.765.163.49.218.88.636 1.083 1.162.152.395.152.895.152 1.896 0 1 0 1.501-.152 1.896a2.08 2.08 0 0 1-1.083 1.162c-.367.163-.833.163-1.765.163H7c-.932 0-1.398 0-1.766-.163a2.079 2.079 0 0 1-1.082-1.162C4 19.473 4 18.972 4 17.972c0-1.001 0-1.501.152-1.896a2.079 2.079 0 0 1 1.082-1.162c.368-.163.834-.163 1.766-.163Z"
      />
    </svg>
  )
}

export default IconPrint
