import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled, { keyframes, css } from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import IconChevronBold from 'ui/components/generic/IconChevronBold'
import DanmarkLogo from 'ui/components/generic/DanmarkLogo'

const steps = {
  '/info': 'Info',
  '/risikofaktorer': 'Risikofaktorer',
  '/symptomer': 'Symptomer',
  '/relaterede': 'Relaterede symptomer',
  '/interview': 'Interview',
  '/resultater': 'Resultat',
}

if (!process.env.REACT_APP_ENABLE_RISK_FACTORS) {
  delete steps['/risikofaktorer']
}

class BottomNavigation extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  }

  isActive = (key) => {
    const {
      location: { pathname },
    } = this.props

    if (pathname === '/' && key === '/') {
      return true
    }
    if (key !== '/' && pathname.indexOf(key) === 0) {
      return true
    }
    return false
  }

  isCompleted = (key) => {
    const activeIndex = Object.keys(steps).findIndex((s) => this.isActive(s))
    const index = Object.keys(steps).findIndex((k) => k === key)
    return index <= activeIndex
  }

  back = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    // don't show bottom navigation on these screens
    const disabledScreens = ['/resultater', '/']

    if (
      !Object.keys(steps)
        .filter((s) => !disabledScreens.includes(s))
        .find((key) => this.isActive(key))
    ) {
      return null
    }

    return (
      <Wrapper>
        <Container fluid>
          <Inner>
            <Column>
              <BackButton
                round
                onClick={this.back}
                hollow
                color="primary"
                size="small"
              >
                <IconChevronBold direction="left" />
              </BackButton>
            </Column>
            <Column>
              <Steps>
                {Object.keys(steps).map((key) => (
                  <Step
                    key={key}
                    active={this.isActive(key)}
                    completed={this.isCompleted(key)}
                  >
                    <Label>{steps[key]}</Label>
                  </Step>
                ))}
              </Steps>
            </Column>
            <Column>
              <Logo>
                <Text size="xxs" as="span">
                  <a
                    href="https://sygeforsikring.dk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DanmarkLogo />
                  </a>
                </Text>
              </Logo>
            </Column>
          </Inner>
        </Container>
      </Wrapper>
    )
  }
}

export default withRouter(BottomNavigation)

const entry = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  height: auto;
  background-color: ${(props) => props.theme.colors.tones.lightest};
  z-index: 10;

  animation: ${entry} 500ms both;
`

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: ${(props) => props.theme.navHeight}px;
  padding-bottom: 20px;

  border-top: 1px solid rgba(225, 225, 225, 0.5);
`

const BackButton = styled(Button)`
  > svg path {
    fill: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    > svg path {
      fill: #fff;
    }
  }
`

const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`

const Label = styled(Text).attrs({ size: 'xxs' })`
  position: absolute;
  bottom: 100%;
  left: -100px;
  right: -100px;
  text-align: center;
  margin-bottom: 12px;

  opacity: 0;
  transform: translateY(-20%);
  transition: 500ms;

  font-weight: ${(props) => props.theme.fontWeights.bold};
`

const Step = styled.div`
  position: relative;

  width: 20px;
  height: 20px;
  margin-right: 50px;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.completed ? props.theme.colors.primary : '#ddd')};

  ${media.xs`
    margin-right: 15px;
  `}

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -52px;
    margin-top: -1px;
    height: 2px;
    width: 50px;

    ${media.xs`
      width: 15px;
      left: -17px;
    `}
  }

  &:before {
    background-color: #ddd;
  }

  &:after  {
    background-color: ${(props) => props.theme.colors.primary};
    width: ${(props) => (props.completed ? 50 : 0)}px;
    transition: 500ms;

    ${media.xs`
      width: ${(props) => (props.completed ? 15 : 0)}px;
    `}
  }

  &:first-child {
    &:after,
    &:before {
      display: none;
    }
  }

  &:last-child  {
    margin-right: 0;
  }

  ${(props) =>
    props.active &&
    css`
      ${Label} {
        opacity: 1;
        transform: translateY(0%);
      }
    `}
`

const Column = styled.div`
  display: flex;
  width: 100%;

  &:first-child,
  &:last-child {
    width: 100px;
  }

  &:last-child {
    justify-content: flex-end;
  }
`

const Logo = styled.div`
  a {
    display: block;
  }

  svg {
    width: 34px;
    height: auto;

    ${media.xs`
      width: 26px;
    `}
  }
`
