import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import withScrollEntry from 'ui/styles/withScrollEntry'
import { SlideIn } from 'ui/styles/utils'
import Text from 'ui/components/generic/Text'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import Button from 'ui/components/generic/Button'

export const query = `
  header
  text
  hasLink
  linkText
  linkType
  internalLink
  externalLink
`

class LargeText extends Component {
  static propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
    hasLink: PropTypes.bool,
    linkText: PropTypes.string,
    linkType: PropTypes.string,
    internalLink: PropTypes.string,
    externalLink: PropTypes.string,
    visible: PropTypes.bool
  }

  render() {
    const {
      header,
      text,
      hasLink,
      linkType,
      linkText,
      internalLink,
      externalLink,
      visible
    } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={10} mdOffset={1} sm={12}>
              {header && <Header>{header}</Header>}
              <SlideIn visible={visible}>
                <Body dangerouslySetInnerHTML={{ __html: text }} />
              </SlideIn>
              {hasLink && (
                <SlideIn visible={visible} delay={200}>
                  <Button
                    color="primary"
                    size="large"
                    component={linkType === 'external' ? 'a' : undefined}
                    to={
                      linkType === 'internal'
                        ? internalLink && internalLink.link
                        : undefined
                    }
                    href={linkType === 'external' ? externalLink : undefined}
                  >
                    {linkText}
                  </Button>
                </SlideIn>
              )}
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default withScrollEntry()(LargeText)

const Wrapper = styled.div`
  ${responsiveSpacing(2, 'padding-top')}
  ${responsiveSpacing(2, 'padding-bottom')}
  background-color: ${props => props.theme.colors.tones.lightest};
  text-align: center;
`

const Body = styled(Text).attrs({
  size: 'md'
})`
  ${responsiveSpacing(1, 'margin-bottom')}
`

const Header = styled(Text).attrs({
  size: 'lg',
  as: 'h2'
})`
  ${responsiveSpacing(1, 'margin-bottom')}
`
