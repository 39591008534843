import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/store'
import {
  removeSymptom,
  updateSymptom,
  updateSymptoms,
  addQuestion,
  InputSymptom,
  ChoiceId,
  Source,
  Question,
} from '../redux/modules/symptoms'

import { reset } from '../redux/modules/patientInfo'

const useSymptoms = () => {
  const dispatch = useDispatch()

  return {
    ...useSelector((state: RootState) => state.symptoms),
    actions: {
      removeSymptom: (symptomId: string) => dispatch(removeSymptom(symptomId)),
      updateSymptom: (
        symptom: InputSymptom,
        choiceId: ChoiceId,
        source: Source,
        remove?: string[]
      ) => dispatch(updateSymptom(symptom, choiceId, source, remove)),
      updateSymptoms: (
        set: { symptom: InputSymptom; choiceId: ChoiceId; source: Source }[],
        remove?: string[]
      ) => dispatch(updateSymptoms(set, remove)),
      reset: () => dispatch(reset()),
      addQuestion: (question: Question) => dispatch(addQuestion(question)),
    },
  }
}

export default useSymptoms
