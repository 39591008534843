import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Wrapper = styled(motion.div)``

const initial = {
  x: 30,
  opacity: 0,
}

const animate = {
  x: 0,
  opacity: 1,
  position: 'relative',
  top: 'auto',
}

const exit = {
  x: -60,
  opacity: 0,
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100vw',
  minHeight: '100vh',
  transitionEnd: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    width: 'auto',
    minHeight: '100vh',
  },
}

class Page extends Component {
  static propTypes = {
    children: PropTypes.node,
  }
  render() {
    return (
      <Wrapper
        initial={initial}
        animate={animate}
        exit={exit}
        transition={{ type: 'tween', duration: 0.5 }}
      >
        {this.props.children}
      </Wrapper>
    )
  }
}

export default Page
