import React, { Component } from 'react'
import ReactMDSpinner from 'react-md-spinner'

import theme from 'ui/styles/theme'

class Spinner extends Component {
  render() {
    return <ReactMDSpinner singleColor={theme.colors.primary} {...this.props} />
  }
}

export default Spinner
