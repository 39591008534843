import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default (
  prop = 'suggestedSymptom',
  functionProp = 'suggestSymptom'
) => WrappedComponent =>
  class WithNav extends Component {
    render() {
      return (
        <Mutation
          mutation={gql`
            mutation SuggestSymptom($title: String, $comment: String) {
              suggestSymptom(title: $title, comment: $comment) {
                errors
              }
            }
          `}
        >
          {(submitForm, { loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  {...{
                    [functionProp]: submitForm,
                    [`${prop}Loading`]: loading,
                    [`${prop}`]:
                      !loading && !error && data && data.suggestSymptom
                  }}
                />
              )
            }
            return <p>Error: {error.message}</p>
          }}
        </Mutation>
      )
    }
  }
