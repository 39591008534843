import React, { FC } from 'react'
import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import usePatientInfo from 'containers/usePatientInfo'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: ${(props) => props.theme.navHeight}px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 10px;
  }

  ${media.xs`
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0 !important;
    }
  `}
`

type Props = {
  gender: Gender
  onChange: Function
}

export type Gender = 'male' | 'female' | null

const GenderSelect: FC<Props> = ({ gender, onChange }) => {
  const { patient, isChild } = usePatientInfo()

  const text =
    patient === 'self'
      ? 'Vælg venligst dit køn'
      : isChild
      ? 'Vælg venligst barnets køn'
      : 'Vælg venligst personens køn'

  return (
    <Wrapper>
      <Container fluid>
        <Text as="p" weight="bold">
          {text}
        </Text>
        <Buttons>
          <Button
            color={gender === 'male' ? 'primaryDark' : 'primary'}
            onClick={() => onChange('male')}
          >
            {isChild ? 'Dreng' : 'Mand'}
          </Button>
          <Button
            color={gender === 'female' ? 'primaryDark' : 'primary'}
            onClick={() => onChange('female')}
          >
            {isChild ? 'Pige' : 'Kvinde'}
          </Button>
        </Buttons>
      </Container>
    </Wrapper>
  )
}

export default GenderSelect
