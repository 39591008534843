import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import withPost from 'containers/withPost'
import withSiteMeta from 'containers/withSiteMeta'
import Modules from 'ui/components/Modules'
import Seo from 'ui/components/Seo'
import NotFound from 'ui/components/NotFound'
import Spinner from 'ui/components/generic/Spinner'

const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.navHeight}px;
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

class Page extends Component {
  render() {
    const { post, loading, type } = this.props

    return (
      <Wrapper>
        {!loading && <Seo seo={post.seo} />}
        {loading && (
          <Loading>
            <Spinner />
          </Loading>
        )}
        {!loading && post && post.modules && (
          <Modules modules={post.modules} post={post} type={type} />
        )}
      </Wrapper>
    )
  }
}

Page.propTypes = {
  post: PropTypes.shape({
    seo: PropTypes.object,
    modules: PropTypes.array,
  }),
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default compose(
  withRouter,
  withSiteMeta(),
  withPost(
    (props) => props.slug || props.match.params.slug,
    () => 'page',
    () => <NotFound />
  )
)(Page)
