import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import withGlobalSeo from 'containers/withGlobalSeo'

class GlobalSeo extends Component {
  render() {
    const { globalSeo } = this.props

    if (!globalSeo) return null

    const {
      title,
      metaDescription,
      facebookTitle,
      facebookDescription,
      facebookImage,
    } = globalSeo

    let meta = [
      { name: 'description', content: metaDescription },
      { property: 'og:title', content: facebookTitle },
      { property: 'og:description', content: facebookDescription },
    ]

    if (facebookImage) {
      meta = meta.concat([
        { property: 'og:image', content: facebookImage.sourceUrl },
        {
          property: 'og:image:width',
          content: facebookImage.mediaDetails.width,
        },
        {
          property: 'og:image:height',
          content: facebookImage.mediaDetails.height,
        },
      ])
    }

    return <Helmet title={title} meta={meta} />
  }
}

GlobalSeo.propTypes = {
  globalSeo: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      faceookTitle: PropTypes.string,
      facebookDescription: PropTypes.string,
      facebookImage: PropTypes.shape({
        sourceUrl: PropTypes.string,
        mediaDetails: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      }),
    }),
    PropTypes.bool,
  ]),
}

export default withGlobalSeo()(GlobalSeo)
