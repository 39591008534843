import { FC, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { useInterval } from 'react-use'
import Text from 'ui/components/generic/Text'

const usps = ['Tjek dine symptomer anonymt på 2 minutter', 'Udviklet af læger']

const UspSlider: FC = () => {
  const [slides, setSlides] = useState<{
    current: number
    prev: number | null
  }>({ current: 0, prev: null })
  const { current, prev } = slides

  const nextSlide = useCallback(() => {
    const next = current >= usps.length - 1 ? 0 : current + 1
    setSlides({ prev: current, current: next })
  }, [current, setSlides])

  useInterval(() => {
    nextSlide()
  }, 3000)

  return (
    <Wrapper>
      {usps.map((usp, index) => (
        <Usp key={index} active={index === current} prev={index === prev}>
          {usp}
        </Usp>
      ))}
    </Wrapper>
  )
}

export default UspSlider

const Wrapper = styled.div`
  position: relative;
  height: 30px;
`

const Usp = styled(Text).attrs({ size: 'xs' })<{
  prev: boolean
  active: boolean
}>`
  display: flex;
  align-content: center;
  justify-content: center;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  text-align: left;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin: 5px 0;
  transition: 0ms;
  transform: translateX(30px);
  opacity: 0;

  ${(props) =>
    props.prev &&
    css`
      transition: 500ms;
      transform: translateX(-10px);
    `}

  ${(props) =>
    props.active &&
    css`
      transition: 500ms;
      transform: translateX(0);
      opacity: 1;
    `}
`
