import React from 'react'
import styled from 'styled-components'
import { reverse } from 'named-urls'

import routes from 'routes'
import Button from 'ui/components/generic/Button'
import useSymptoms from 'containers/useSymptoms'
import IconView from 'ui/components/generic/IconView'
import IconRestart from 'ui/components/generic/IconRestart'
import IconPrint from 'ui/components/generic/IconPrint'
import { media } from 'styled-bootstrap-grid'

const Actions: React.FC = () => {
  const {
    actions: { reset },
  } = useSymptoms()
  return (
    <Wrapper>
      <Button to={reverse(routes.interview.genderSelect)} color="primary">
        <IconView />
        Gennemse mine svar
      </Button>
      <Button
        onClick={reset}
        to={reverse(routes.interview.landing)}
        color="primary"
      >
        <IconRestart />
        Start forfra
      </Button>
      <Button color="primary" as="a" onClick={() => window.print()}>
        <IconPrint />
        Udskriv
      </Button>
    </Wrapper>
  )
}

export default Actions

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
    align-items: center;
  justify-content: center;
`}

  a {
    margin-bottom: 10px;
    svg {
      display: block;
      margin-right: 10px;
    }
  }
`
