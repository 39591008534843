import { FC } from 'react'
import styled from 'styled-components'

import Button from 'ui/components/generic/Button'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Text from 'ui/components/generic/Text'

const Done: FC<{ onEnd: Function }> = ({ onEnd }) => {
  return (
    <Content>
      <PaddedContent>
        <SectionHeader>
          <strong>Tak. Nu har vi sammenholdt de data, du har indtastet.</strong>
        </SectionHeader>
        <Section>
          Følgende resultat udgør hverken en diagnose eller en komplet
          diagnose-screening. Servicen kender hverken alle kendte sygdomme eller
          symptomer og der kan således være forhold, som overses eller ikke
          screenes for.
        </Section>
        <Section>Du bør altid tale med en læge, hvis du føler dig syg.</Section>
        <Action>
          <Button onClick={onEnd} color="primary">
            Jeg er indforstået
          </Button>
        </Action>
      </PaddedContent>
    </Content>
  )
}

export default Done

const Content = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`

const SectionHeader = styled(Text).attrs({
  size: 'md',
  mb: 20,
  weight: 'medium',
})``

const Section = styled(Text).attrs({
  as: 'p',
  mb: 20,
  size: 'sm',
  weight: 'normal',
})``

const Action = styled.div`
  padding-top: 30px;
`
