import { FC } from 'react'

const IconRestart: FC<{ color?: string }> = ({ color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={19}
      fill="none"
      viewBox="0 0 17 19"
      {...rest}
    >
      <path
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M11 6h5V1m-.29 13.357a8 8 0 1 1-.19-8.991"
      />
    </svg>
  )
}

export default IconRestart
