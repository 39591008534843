import { connect } from 'react-redux'
import { ignoreEmergency } from 'redux/modules/patientInfo'

export default connect(
  (state) => ({
    patientInfo: {
      ...state.patientInfo,
      isChild: state.patientInfo.age && state.patientInfo.age < 18,
    },
  }),
  {
    ignoreEmergency,
  }
)
