import React from 'react'
import PropTypes from 'prop-types'

const IconSearch = ({ color }) => (
  <svg
    width="17"
    height="15"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 15"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color || '#fff'}
      strokeWidth="1.33"
      transform="translate(1 1)"
    >
      <circle cx="5.667" cy="5.667" r="5.5" />
      <path strokeLinecap="round" d="M10.5 9.5l4 3" />
    </g>
  </svg>
)

IconSearch.propTypes = {
  color: PropTypes.string
}

export default IconSearch
