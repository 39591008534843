import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { push } from 'utils/gtm'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Text from 'ui/components/generic/Text'
import Triage from './Triage'
import Cancer from './screenings/Cancer'
import Recap from './Recap'
import Conditions from './Conditions'
import Feedback from './Feedback'
import Actions from './Actions'
import Disclaimer from './Disclaimer'
import MedicinTjekkerBanner from './MedicinTjekkerBanner'
import useDiagnosis from 'containers/useDiagnosis'
import useSymptoms from 'containers/useSymptoms'
import NoResults from './NoResults'

const Results: React.FC = () => {
  const { symptoms } = useSymptoms()
  const { data: diagnosis, loading: diagnosisLoading } = useDiagnosis()

  const track = useCallback(() => {
    if (diagnosis) {
      const conditions = (diagnosis as any).conditions.filter(
        (condition: any) => condition.probability > 0.2
      )
      if (conditions.length === 0) {
        push({ event: 'noMatchingConditions', symptoms })
      } else {
        push({ event: 'matchingConditions', conditions, symptoms })
      }
    }
  }, [diagnosis, symptoms])

  useEffect(() => {
    track()
  }, [track])

  useEffect(() => {
    if (diagnosisLoading && diagnosis) {
      track()
    }
  }, [diagnosis, diagnosisLoading, track])

  if (!diagnosis) return null

  const conditions = diagnosis.conditions.filter(
    (condition) => condition.probability > 0.2
  )

  return (
    <Wrapper>
      <PaddedContent large>
        <Header>Resultat</Header>
        <Triage />
        {conditions.length > 0 && (
          <Conditions conditions={diagnosis.conditions} />
        )}
        {conditions.length === 0 && <NoResults symptoms={symptoms} />}
        {conditions.length > 0 && <Disclaimer />}
        <Cancer />
        {conditions.length > 0 && <Feedback />}
        <Recap />
        <Actions />
        <MedicinTjekkerBanner />
      </PaddedContent>
    </Wrapper>
  )
}

export default Results

const Wrapper = styled.div`
  padding-top: ${(props: any) => props.theme.navHeight + 50}px;
  padding-bottom: ${(props: any) => props.theme.navHeight + 30}px;
`

const Header = styled(Text).attrs({ as: 'h1', size: 'xxxl' })`
  text-align: center;
`
