import { FC, ReactElement, useCallback } from 'react'
import styled from 'styled-components'
import { push } from 'utils/gtm'
import { media } from 'styled-bootstrap-grid'

import type { Condition as ConditionType } from 'containers/useDiagnosis'
import IconChevronMedium from 'ui/components/generic/IconChevronMedium'
import Text from 'ui/components/generic/Text'

import { probabilityOffsets } from './Conditions'
import useDrawer from 'containers/useDrawer'
import RoundButton from 'ui/components/generic/RoundButton'

const Conditions: FC<{ conditions: ConditionType[]; header: ReactElement }> = ({
  conditions,
  header,
}) => {
  const {
    actions: { showDrawer },
  } = useDrawer()

  const showConditionDetails = useCallback(
    (condition: ConditionType) => {
      showDrawer('conditionDetails', {
        id: condition.id,
        probability: condition.probability,
        customData: {
          link: condition.link,
          linkSource: condition.linkSource,
          description: condition.description,
        },
      })
      push({
        event: 'expandCondition',
        value: condition,
      })
    },
    [showDrawer]
  )

  const getConditionTitle = (c: ConditionType) => {
    const name = c.name
    const commonName = c.common_name

    return `${commonName} ${name !== commonName ? `(${name})` : ''}`
  }

  const getProbabilityLabel = (probability: number) => {
    const { high, medium, low } = probabilityOffsets

    if (probability >= high) {
      return 'Høj grad'
    }

    if (probability >= medium) {
      return 'Nogen grad'
    }

    if (probability >= low) {
      return 'Lav grad'
    }

    return null
  }

  if (conditions.length === 0) return null

  return (
    <Wrapper>
      <Header>{header}</Header>
      {conditions.map((condition) => (
        <Condition
          key={condition.id}
          onClick={() => showConditionDetails(condition)}
        >
          <Title>{getConditionTitle(condition)}</Title>
          <Meta>
            <ProbabilityLabel>
              {getProbabilityLabel(condition.probability)}
            </ProbabilityLabel>
            <ProbabilityBar>
              <ProbabilityBarFill width={condition.probability * 100} />
            </ProbabilityBar>
            <ExpandButton>
              <Icon direction="right" color="#fff" />
            </ExpandButton>
          </Meta>
        </Condition>
      ))}
    </Wrapper>
  )
}

export default Conditions

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Condition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  margin-bottom: 10px;
  padding: 15px;

  border-radius: 15px;
  background-color: #fff;

  cursor: pointer;

  ${media.md`
    border-radius: 20px;
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
  `}
`

const Header = styled.div`
  text-align: center;
  color: #fff;
  margin-bottom: 30px;

  u {
    text-decoration: underline;
  }
`

const Title = styled(Text).attrs({ size: 'lg' })`
  position: relative;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  flex-grow: 1;
  margin-bottom: 10px;

  ${media.md`
    margin-bottom: 0px;
  `}
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`

const ProbabilityLabel = styled(Text).attrs({ size: 'xs' })`
  color: ${(props) => props.theme.colors.tones.light};
  margin-right: 10px;
`

const ProbabilityBar = styled.div`
  width: 100px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.tones.lighter};
  border-radius: 8px;
  overflow: hidden;

  margin-right: 15px;

  ${media.lg`
    margin-right: 45px;
  `}
`

const ProbabilityBarFill = styled.div<{ width: number }>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme.colors.primary};
  transition: width 0.3s ease-in-out;
`

const Icon = styled(IconChevronMedium)`
  margin-left: 3px;
  width: 5px;

  ${media.md`
    width: 9px;
  `}
`

const ExpandButton = styled(RoundButton)`
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -15px;

  height: 30px;
  width: 30px;

  ${media.md`
    position: relative;    
    top: auto;
    right: auto;
    margin-top: 0;

    width: 50px;
    height: 50px;
  `}
`
