import React, { FC } from 'react'
import styled from 'styled-components'

import { Switch, Route, useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import { AnimatePresence } from 'framer-motion'
import { reverse } from 'named-urls'

import routes from 'routes'
import useSiteMeta from 'containers/useSiteMeta'

import theme from 'ui/styles/theme'

import GlobalStyles from 'ui/styles/global'
import GridView from 'ui/components/generic/GridView'

import Analytics from 'ui/components/Analytics'
import Page from 'ui/components/Page'
import Navigation from 'ui/components/Navigation'
import BottomNavigation from 'ui/components/BottomNavigation'
import Overlay from 'ui/components/Overlay'
import GlobalSeo from 'ui/components/GlobalSeo'
import ResetHandler from 'ui/components/ResetHandler'
import Spinner from 'ui/components/generic/Spinner'

import ContentPage from 'ui/components/routes/ContentPage'
import Interview from 'ui/components/routes/Interview'
import List from 'ui/components/routes/List'
import Drawer from 'ui/components/Drawer'

const App: FC = () => {
  const { loading: siteMetaLoading } = useSiteMeta()
  const location = useLocation()

  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme.grid}>
        <>
          <GlobalSeo />
          <Analytics />
          <BaseCSS />
          <ResetHandler />
          <GlobalStyles />
          {siteMetaLoading && (
            <Loading>
              <Spinner />
            </Loading>
          )}
          {!siteMetaLoading && (
            <>
              <Navigation />
              <Overlay />
              <Drawer />
              <Content>
                <AnimatePresence initial={false}>
                  <Switch location={location} key={getLocationKey(location)}>
                    <Route path={routes.conditionList}>
                      <Page key="list-conditions">
                        <List type="conditions" />
                      </Page>
                    </Route>

                    <Route
                      exact
                      path={[
                        ...Object.keys(routes.interview)
                          .filter((k) => k !== 'toString')
                          .map((k) => routes.interview[k]),
                      ]}
                    >
                      <Page key="interview">
                        <Interview />
                      </Page>
                    </Route>

                    <Route path={routes.contentPage}>
                      <Page key={location.pathname}>
                        <ContentPage />
                      </Page>
                    </Route>
                  </Switch>
                </AnimatePresence>
              </Content>
            </>
          )}
          <BottomNavigation />
          {process.env.NODE_ENV === 'development' && <GridView />}
        </>
      </GridThemeProvider>
    </ThemeProvider>
  )
}

export default App

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

const Content = styled.div`
  overflow: hidden;
`

const getLocationKey = (location: any) => {
  if (location.pathname.indexOf(reverse(routes.conditionList)) === 0)
    return 'conditionList'
  return location.pathname
}
