import { FC } from 'react'

import styled from 'styled-components'
import Button from 'ui/components/generic/Button'
import MTLogo from 'ui/components/generic/MTLogo'
import Text from 'ui/components/generic/Text'

const MedicinTjekkerBanner: FC = () => {
  return (
    <Wrapper>
      <HeaderBox>
        <Header>Nu kan du også tjekke din medicin og dele din erfaring</Header>
      </HeaderBox>
      <Box>
        <Text size="xs" as="p">
          Vidste du, at du på medicintjekker.dk kan ét samlet overblik over den
          information, der findes om godkendt medicin. Koblet sammen med 500.000
          brugeres erfaring. Trygt, anonymt og uafhængigt.
        </Text>
        <Actions>
          <Button
            href="https://medicintjekker.dk"
            color="primary"
            component="a"
            target="_blank"
          >
            Gå til medicintjekker.dk
          </Button>
          <MTLogo />
        </Actions>
      </Box>
    </Wrapper>
  )
}

export default MedicinTjekkerBanner

const Wrapper = styled.div`
  margin-top: 20px;
  padding-top: 30px;

  border-top: 1px solid ${({ theme }) => theme.colors.tones.lighter};

  p {
    line-height: 1.5;
  }
`

const Box = styled.div`
  padding: 20px;
  margin-bottom: 1px;

  overflow: hidden;

  background-color: #fff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`

const HeaderBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

const Header = styled(Text).attrs({
  as: 'h2',
})`
  margin-bottom: 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: none;
    width: 120px;
    height: auto;
    margin-left: 20px;
  }
`
