import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import withScrollEntry from 'ui/styles/withScrollEntry'
import { SlideIn } from 'ui/styles/utils'
import Text from 'ui/components/generic/Text'
import Step from './Step'

export const query = `
    header
    body
    steps {
      header
      body
    }
  `

class Process extends Component {
  static propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    steps: PropTypes.array,
    visible: PropTypes.bool
  }

  render() {
    const { header, body, steps, visible } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
              <SlideIn visible={visible}>
                <Header>{header}</Header>
                <Body dangerouslySetInnerHTML={{ __html: body }} />
              </SlideIn>
              <SlideIn visible={visible} delay={200}>
                {steps.map((step, index) => (
                  <Step key={index} step={step} index={index} />
                ))}
              </SlideIn>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default withScrollEntry()(Process)

const Wrapper = styled.div``

const Body = styled(Text)``

const Header = styled(Text).attrs({
  size: 'lg',
  as: 'h2'
})``
