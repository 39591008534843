import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

const SUBMIT_RATING = gql`
  mutation submitRating {
    submitRating(input: $input)
      @rest(type: "Post", path: "rate", method: "POST") {
      success
    }
  }
`

export default () => {
  const [submitRating, { loading, data, error }] = useMutation<{
    submitRating: {
      success: boolean
    }
  }>(SUBMIT_RATING)

  return {
    mutate: submitRating,
    loading,
    data: data && data.submitRating,
    error,
  }
}
