import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import usePatientInfo from 'containers/usePatientInfo'
import useTriage from 'containers/useTriage'

import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

const Results = () => {
  const { data: triage } = useTriage()
  const patientInfo = usePatientInfo()

  const getText = () => {
    if (!triage) return ''
    const { triage_level } = triage
    const { patient, isChild } = patientInfo

    switch (triage_level) {
      case 'emergency_ambulance':
        return (
          <span>
            Nogle af de angivne symptomer er alvorlige. Hvis{' '}
            {patient === 'self' ? 'du' : isChild ? 'barnet' : 'personen'}{' '}
            oplever de angivne symptomer bør du ringe efter en ambulance med det
            samme. <br />
            <br />
            <Button href="tel:112" color="primary" component="a">
              Ring 112
            </Button>
          </span>
        )
      case 'emergency':
        return `Nogle af de angivne symptomer er alvorlige. Hvis ${
          patient === 'self' ? 'du' : isChild ? 'barnet' : 'personen'
        } oplever de angivne symptomer bør du straks kontakte din læge eller lægevagten.`
      case 'consultation_24':
        return `Nogle af de angivne symptomer kan være alvorlige. ${
          patient === 'self' ? 'Du' : isChild ? 'Du' : 'Personen'
        } bør kontakte en læge indenfor de næste 24 timer. Hvis dine symptomer pludselig forværres bør du kontakte din egen læge eller lægevagten med det samme.`
      case 'consultation':
        return `Nogle af de angivne symptomer kan give anledning til, at ${
          patient === 'self' ? 'du' : isChild ? 'dy' : 'personen'
        } bør opsøge en læge inden for den nærmeste fremtid.`
      case 'self_care':
        return `Ingen af de angivne symptomer giver anledning til at søge læge lige nu. Man bør altid søge læge hvis man føler sig syg eller hvis de oplevede symptomer forværres.`
      default:
        return ''
    }
  }

  if (!triage) return null

  const { triage_level, serious } = triage
  const seriousLevels = ['emergency_ambulance', 'emergency']

  // If not serious, don't show anything
  if (!seriousLevels.includes(triage_level)) return null

  return (
    <Wrapper>
      <Header>Bemærk!</Header>
      <Body>{getText()}</Body>
      {serious.length > 0 && (
        <Symptoms>
          <Text size="xs" as="h4">
            Alvorlige symptomer:
          </Text>
          {serious.map((symptom) => (
            <SymptomText key={symptom.id} isEmergency={symptom.is_emergency}>
              {symptom.common_name}
            </SymptomText>
          ))}
        </Symptoms>
      )}
    </Wrapper>
  )
}

export default withRouter(Results)

const Wrapper = styled.div`
  border: 8px solid ${(props) => props.theme.colors.primary};
  border-radius: 25px;
  padding: 20px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
`

const Header = styled(Text).attrs({ size: 'xl', weight: 'medium' })`
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.tones.dark};
`

const Body = styled(Text).attrs({ size: 'sm' })`
  color: ${(props) => props.theme.colors.tones.medium};
`

const Symptoms = styled.div`
  margin-top: 20px;
`

const SymptomText = styled(Text).attrs({ size: 'xs', weight: 'medium' })<{
  isEmergency?: boolean
}>`
  position: relative;
  margin-bottom: 10px;
  padding-left: 30px;

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: '!';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.isEmergency
        ? props.theme.colors.primary
        : props.theme.colors.tones.light};
    transform: translateY(-50%);
    color: #fff;
  }
`
