import React, { FC } from 'react'
import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'

import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: ${(props) => props.theme.navHeight}px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 10px;
  }

  ${media.xs`
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0 !important;
    }
  `}
`

type Props = {
  patient: Patient
  onChange: Function
}

export type Patient = 'self' | 'other' | null

const PatientSelect: FC<Props> = ({ patient, onChange }) => {
  return (
    <Wrapper>
      <Container fluid>
        <Text as="p" weight="bold">
          Hvem vedrører interviewet?
        </Text>
        <Buttons>
          <Button
            color={patient === 'self' ? 'primaryDark' : 'primary'}
            onClick={() => onChange('self')}
          >
            Mig selv
          </Button>
          <Button
            color={patient === 'other' ? 'primaryDark' : 'primary'}
            onClick={() => onChange('other')}
          >
            En anden
          </Button>
        </Buttons>
      </Container>
    </Wrapper>
  )
}

export default PatientSelect
