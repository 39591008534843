import theme from 'ui/styles/theme'

const Logo = () => {
  return (
    <svg
      width={381}
      height={82}
      viewBox="0 0 381 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_59)">
        <path
          d="M38.98 77.96C60.5081 77.96 77.96 60.5081 77.96 38.98C77.96 17.4519 60.5081 0 38.98 0C17.4519 0 0 17.4519 0 38.98C0 60.5081 17.4519 77.96 38.98 77.96Z"
          fill={theme.colors.primary}
        />
        <path
          d="M98.13 63.57C97.67 63.24 97.08 63.07 96.37 63.07C96.08 63.07 95.79 63.1 95.51 63.16C95.23 63.22 94.98 63.31 94.76 63.45C94.54 63.58 94.37 63.77 94.24 63.99C94.11 64.22 94.04 64.5 94.04 64.84C94.04 65.16 94.13 65.42 94.32 65.62C94.51 65.82 94.76 65.98 95.07 66.11C95.38 66.24 95.74 66.34 96.14 66.42C96.54 66.5 96.94 66.59 97.35 66.69C97.76 66.79 98.16 66.9 98.56 67.03C98.96 67.16 99.31 67.34 99.63 67.56C99.94 67.78 100.2 68.06 100.38 68.4C100.57 68.74 100.66 69.16 100.66 69.68C100.66 70.23 100.54 70.7 100.29 71.09C100.04 71.48 99.73 71.8 99.34 72.05C98.95 72.3 98.52 72.48 98.04 72.59C97.56 72.7 97.09 72.76 96.61 72.76C96.03 72.76 95.48 72.69 94.96 72.54C94.44 72.39 93.99 72.17 93.6 71.88C93.21 71.59 92.9 71.21 92.67 70.75C92.44 70.29 92.33 69.75 92.33 69.12H93.63C93.63 69.56 93.71 69.93 93.88 70.24C94.05 70.55 94.27 70.81 94.55 71.02C94.83 71.22 95.15 71.37 95.51 71.47C95.88 71.57 96.25 71.61 96.64 71.61C96.95 71.61 97.26 71.58 97.58 71.52C97.89 71.46 98.18 71.36 98.43 71.22C98.68 71.08 98.88 70.89 99.04 70.65C99.19 70.41 99.27 70.1 99.27 69.72C99.27 69.36 99.18 69.07 98.99 68.85C98.8 68.63 98.55 68.45 98.24 68.31C97.93 68.17 97.57 68.06 97.17 67.97C96.77 67.88 96.37 67.79 95.96 67.7C95.55 67.61 95.15 67.5 94.75 67.38C94.35 67.26 94 67.1 93.68 66.9C93.36 66.7 93.11 66.45 92.93 66.13C92.74 65.82 92.65 65.42 92.65 64.95C92.65 64.43 92.76 63.98 92.97 63.59C93.18 63.21 93.47 62.89 93.82 62.65C94.17 62.4 94.57 62.22 95.02 62.1C95.47 61.98 95.93 61.92 96.4 61.92C96.93 61.92 97.42 61.98 97.88 62.11C98.33 62.24 98.73 62.43 99.08 62.7C99.43 62.97 99.69 63.31 99.89 63.72C100.09 64.13 100.2 64.62 100.22 65.19H98.92C98.86 64.44 98.59 63.9 98.13 63.57Z"
          fill="black"
        />
        <path
          d="M105.09 73.51C104.94 73.88 104.8 74.19 104.66 74.44C104.52 74.69 104.37 74.9 104.2 75.06C104.03 75.22 103.84 75.34 103.63 75.41C103.42 75.48 103.18 75.52 102.91 75.52C102.76 75.52 102.62 75.51 102.48 75.49C102.33 75.47 102.19 75.44 102.06 75.39V74.26C102.17 74.31 102.29 74.35 102.43 74.38C102.57 74.41 102.69 74.43 102.78 74.43C103.03 74.43 103.24 74.37 103.41 74.25C103.58 74.13 103.71 73.96 103.79 73.74L104.3 72.48L101.33 65.03H102.72L104.91 71.16H104.94L107.04 65.03H108.34L105.09 73.51Z"
          fill="black"
        />
        <path
          d="M115.02 74.62C114.46 75.23 113.58 75.53 112.38 75.53C112.03 75.53 111.68 75.49 111.31 75.41C110.95 75.33 110.62 75.21 110.32 75.03C110.02 74.85 109.78 74.63 109.59 74.35C109.4 74.07 109.29 73.73 109.27 73.32H110.5C110.51 73.54 110.58 73.73 110.71 73.89C110.84 74.04 111 74.17 111.19 74.27C111.38 74.37 111.58 74.44 111.81 74.48C112.03 74.52 112.25 74.54 112.45 74.54C112.86 74.54 113.2 74.47 113.48 74.33C113.76 74.19 113.99 74 114.18 73.75C114.36 73.5 114.5 73.21 114.58 72.86C114.66 72.51 114.7 72.13 114.7 71.71V71.22H114.67C114.46 71.68 114.14 72.02 113.71 72.24C113.28 72.46 112.82 72.57 112.34 72.57C111.78 72.57 111.29 72.47 110.88 72.27C110.46 72.07 110.12 71.79 109.84 71.45C109.56 71.11 109.35 70.71 109.21 70.25C109.07 69.79 109 69.3 109 68.79C109 68.34 109.06 67.89 109.17 67.42C109.29 66.95 109.48 66.53 109.75 66.14C110.02 65.75 110.38 65.44 110.82 65.2C111.26 64.95 111.81 64.83 112.46 64.83C112.93 64.83 113.37 64.93 113.77 65.14C114.17 65.35 114.48 65.66 114.7 66.08H114.71V65.01H115.87V71.87C115.86 73.1 115.58 74.01 115.02 74.62ZM113.45 71.25C113.73 71.08 113.96 70.86 114.14 70.58C114.31 70.3 114.44 69.99 114.52 69.65C114.6 69.3 114.64 68.95 114.64 68.61C114.64 68.28 114.6 67.96 114.52 67.64C114.44 67.32 114.32 67.03 114.15 66.78C113.98 66.52 113.76 66.32 113.49 66.16C113.22 66.01 112.89 65.93 112.5 65.93C112.1 65.93 111.77 66.01 111.48 66.15C111.19 66.29 110.97 66.5 110.79 66.75C110.61 67 110.48 67.29 110.4 67.62C110.32 67.95 110.28 68.29 110.28 68.65C110.28 68.99 110.31 69.33 110.38 69.67C110.45 70.01 110.56 70.31 110.73 70.59C110.89 70.87 111.11 71.09 111.38 71.26C111.65 71.43 111.99 71.51 112.39 71.51C112.81 71.51 113.16 71.42 113.45 71.25Z"
          fill="black"
        />
        <path
          d="M123.02 72.03C122.46 72.45 121.75 72.67 120.9 72.67C120.3 72.67 119.78 72.57 119.34 72.38C118.9 72.19 118.53 71.92 118.23 71.57C117.93 71.22 117.71 70.81 117.56 70.32C117.41 69.84 117.33 69.31 117.31 68.74C117.31 68.17 117.4 67.65 117.57 67.17C117.74 66.7 117.99 66.29 118.3 65.94C118.61 65.59 118.99 65.32 119.42 65.13C119.85 64.94 120.32 64.84 120.83 64.84C121.5 64.84 122.05 64.98 122.49 65.25C122.93 65.52 123.28 65.88 123.55 66.3C123.82 66.73 124 67.19 124.1 67.69C124.2 68.19 124.24 68.67 124.22 69.13H118.61C118.6 69.46 118.64 69.77 118.73 70.07C118.82 70.37 118.96 70.63 119.15 70.85C119.34 71.08 119.59 71.26 119.89 71.39C120.19 71.53 120.54 71.59 120.95 71.59C121.47 71.59 121.9 71.47 122.23 71.23C122.56 70.99 122.78 70.62 122.89 70.13H124.11C123.95 70.97 123.58 71.6 123.02 72.03ZM122.72 67.21C122.61 66.95 122.46 66.72 122.27 66.54C122.08 66.35 121.86 66.2 121.6 66.09C121.34 65.98 121.06 65.92 120.75 65.92C120.43 65.92 120.14 65.98 119.89 66.09C119.63 66.2 119.41 66.35 119.23 66.55C119.05 66.74 118.9 66.97 118.79 67.22C118.68 67.48 118.62 67.75 118.6 68.04H122.91C122.9 67.75 122.84 67.47 122.72 67.21Z"
          fill="black"
        />
        <path
          d="M124.75 66.1V65.01H126.01V63.89C126.01 63.28 126.19 62.82 126.54 62.51C126.89 62.2 127.41 62.04 128.08 62.04C128.2 62.04 128.33 62.05 128.48 62.07C128.63 62.09 128.76 62.12 128.88 62.16V63.23C128.77 63.19 128.66 63.16 128.53 63.15C128.4 63.14 128.29 63.13 128.18 63.13C127.88 63.13 127.65 63.19 127.48 63.3C127.32 63.42 127.23 63.64 127.23 63.97V65.01H128.68V66.1H127.23V72.51H126V66.1H124.75Z"
          fill="black"
        />
        <path
          d="M129.62 67.22C129.77 66.74 130.01 66.33 130.32 65.97C130.63 65.62 131.01 65.34 131.47 65.14C131.92 64.94 132.45 64.84 133.04 64.84C133.64 64.84 134.16 64.94 134.61 65.14C135.06 65.34 135.44 65.62 135.75 65.97C136.06 66.32 136.29 66.74 136.45 67.22C136.61 67.7 136.68 68.21 136.68 68.76C136.68 69.31 136.6 69.82 136.45 70.3C136.3 70.78 136.06 71.19 135.75 71.54C135.44 71.89 135.06 72.17 134.61 72.37C134.16 72.57 133.64 72.67 133.04 72.67C132.45 72.67 131.93 72.57 131.47 72.37C131.02 72.17 130.63 71.9 130.32 71.54C130.01 71.19 129.78 70.77 129.62 70.3C129.46 69.83 129.39 69.31 129.39 68.76C129.39 68.21 129.46 67.7 129.62 67.22ZM130.87 69.98C130.99 70.33 131.16 70.63 131.37 70.86C131.58 71.1 131.83 71.28 132.12 71.4C132.4 71.53 132.71 71.59 133.03 71.59C133.35 71.59 133.65 71.53 133.94 71.4C134.22 71.28 134.47 71.09 134.69 70.86C134.9 70.62 135.07 70.33 135.19 69.98C135.31 69.63 135.37 69.22 135.37 68.77C135.37 68.32 135.31 67.91 135.19 67.56C135.07 67.21 134.9 66.91 134.69 66.67C134.48 66.43 134.23 66.25 133.94 66.12C133.65 65.99 133.35 65.93 133.03 65.93C132.71 65.93 132.41 65.99 132.12 66.12C131.83 66.25 131.59 66.43 131.37 66.67C131.16 66.91 130.99 67.21 130.87 67.56C130.75 67.91 130.69 68.32 130.69 68.77C130.69 69.22 130.75 69.62 130.87 69.98Z"
          fill="black"
        />
        <path
          d="M139.23 65.01V66.59H139.26C139.56 65.98 139.93 65.53 140.36 65.24C140.8 64.95 141.35 64.81 142.01 64.83V66.13C141.52 66.13 141.1 66.2 140.75 66.33C140.4 66.47 140.12 66.66 139.91 66.92C139.7 67.18 139.54 67.5 139.45 67.87C139.35 68.24 139.3 68.67 139.3 69.15V72.48H138.07V64.98H139.23V65.01Z"
          fill="black"
        />
        <path
          d="M143.89 70.83C144.01 71.02 144.16 71.17 144.35 71.28C144.54 71.39 144.75 71.47 144.99 71.52C145.23 71.57 145.47 71.59 145.72 71.59C145.91 71.59 146.12 71.58 146.33 71.55C146.54 71.52 146.74 71.47 146.92 71.39C147.1 71.31 147.25 71.2 147.36 71.05C147.48 70.9 147.53 70.71 147.53 70.48C147.53 70.16 147.41 69.92 147.17 69.76C146.93 69.59 146.63 69.46 146.26 69.36C145.89 69.26 145.5 69.17 145.08 69.08C144.65 69 144.26 68.88 143.9 68.73C143.54 68.58 143.23 68.37 142.99 68.1C142.75 67.83 142.63 67.45 142.63 66.97C142.63 66.59 142.71 66.27 142.88 66C143.05 65.73 143.27 65.51 143.53 65.34C143.8 65.17 144.1 65.05 144.43 64.96C144.76 64.88 145.09 64.84 145.42 64.84C145.84 64.84 146.24 64.88 146.59 64.95C146.95 65.02 147.26 65.15 147.54 65.33C147.82 65.51 148.04 65.75 148.2 66.05C148.36 66.35 148.46 66.73 148.49 67.19H147.26C147.24 66.95 147.18 66.75 147.07 66.59C146.96 66.43 146.83 66.3 146.66 66.21C146.5 66.11 146.31 66.04 146.12 66C145.92 65.96 145.72 65.93 145.52 65.93C145.34 65.93 145.15 65.94 144.96 65.97C144.77 66 144.6 66.05 144.45 66.12C144.3 66.19 144.17 66.29 144.07 66.41C143.97 66.53 143.92 66.69 143.92 66.88C143.92 67.09 143.99 67.27 144.14 67.41C144.29 67.55 144.48 67.67 144.71 67.76C144.94 67.85 145.2 67.93 145.49 67.99C145.78 68.05 146.07 68.12 146.36 68.19C146.67 68.26 146.97 68.34 147.27 68.44C147.56 68.54 147.83 68.67 148.05 68.82C148.27 68.97 148.46 69.18 148.6 69.42C148.74 69.66 148.81 69.96 148.81 70.32C148.81 70.77 148.72 71.15 148.53 71.45C148.34 71.75 148.09 71.99 147.79 72.17C147.49 72.35 147.14 72.48 146.76 72.55C146.38 72.62 146 72.66 145.62 72.66C145.2 72.66 144.81 72.62 144.43 72.53C144.05 72.44 143.72 72.3 143.43 72.1C143.14 71.9 142.91 71.64 142.73 71.32C142.56 71 142.46 70.6 142.44 70.14H143.67C143.71 70.41 143.77 70.64 143.89 70.83Z"
          fill="black"
        />
        <path
          d="M150.27 63.66V62.15H151.5V63.66H150.27ZM151.5 65.01V72.51H150.27V65.01H151.5Z"
          fill="black"
        />
        <path
          d="M154.72 62.15V68.28L158.14 65H159.79L156.82 67.73L160.01 72.5H158.44L155.87 68.58L154.71 69.65V72.49H153.48V62.15H154.72Z"
          fill="black"
        />
        <path
          d="M162.05 65.01V66.59H162.08C162.38 65.98 162.75 65.53 163.18 65.24C163.62 64.95 164.17 64.81 164.83 64.83V66.13C164.34 66.13 163.92 66.2 163.57 66.33C163.22 66.47 162.94 66.66 162.73 66.92C162.52 67.18 162.36 67.5 162.27 67.87C162.17 68.24 162.12 68.67 162.12 69.15V72.48H160.89V64.98H162.05V65.01Z"
          fill="black"
        />
        <path
          d="M165.84 63.66V62.15H167.07V63.66H165.84ZM167.07 65.01V72.51H165.84V65.01H167.07Z"
          fill="black"
        />
        <path
          d="M170.15 65.01V66.2H170.18C170.69 65.29 171.5 64.84 172.62 64.84C173.11 64.84 173.52 64.91 173.85 65.04C174.18 65.18 174.44 65.36 174.65 65.61C174.85 65.85 175 66.14 175.08 66.47C175.16 66.8 175.2 67.17 175.2 67.58V72.51H173.97V67.43C173.97 66.97 173.83 66.6 173.56 66.33C173.29 66.06 172.92 65.92 172.44 65.92C172.06 65.92 171.74 65.98 171.46 66.09C171.18 66.21 170.95 66.37 170.77 66.58C170.59 66.79 170.45 67.04 170.36 67.33C170.27 67.62 170.22 67.93 170.22 68.27V72.5H168.99V65H170.15V65.01Z"
          fill="black"
        />
        <path
          d="M182.67 74.62C182.11 75.23 181.23 75.53 180.03 75.53C179.68 75.53 179.33 75.49 178.96 75.41C178.6 75.33 178.27 75.21 177.97 75.03C177.67 74.85 177.43 74.63 177.24 74.35C177.05 74.07 176.94 73.73 176.92 73.32H178.15C178.16 73.54 178.23 73.73 178.36 73.89C178.49 74.04 178.65 74.17 178.84 74.27C179.03 74.37 179.23 74.44 179.46 74.48C179.68 74.52 179.9 74.54 180.1 74.54C180.51 74.54 180.85 74.47 181.13 74.33C181.41 74.19 181.64 74 181.83 73.75C182.01 73.5 182.15 73.21 182.23 72.86C182.31 72.51 182.35 72.13 182.35 71.71V71.22H182.32C182.11 71.68 181.79 72.02 181.36 72.24C180.93 72.46 180.47 72.57 179.99 72.57C179.43 72.57 178.94 72.47 178.53 72.27C178.11 72.07 177.77 71.79 177.49 71.45C177.21 71.11 177 70.71 176.86 70.25C176.72 69.79 176.65 69.3 176.65 68.79C176.65 68.34 176.71 67.89 176.82 67.42C176.94 66.95 177.13 66.53 177.4 66.14C177.67 65.75 178.03 65.44 178.47 65.2C178.91 64.95 179.46 64.83 180.11 64.83C180.58 64.83 181.02 64.93 181.42 65.14C181.82 65.35 182.13 65.66 182.35 66.08H182.36V65.01H183.52V71.87C183.51 73.1 183.23 74.01 182.67 74.62ZM181.1 71.25C181.38 71.08 181.61 70.86 181.79 70.58C181.96 70.3 182.09 69.99 182.17 69.65C182.25 69.3 182.29 68.95 182.29 68.61C182.29 68.28 182.25 67.96 182.17 67.64C182.09 67.32 181.97 67.03 181.8 66.78C181.63 66.52 181.41 66.32 181.14 66.16C180.87 66.01 180.54 65.93 180.15 65.93C179.75 65.93 179.42 66.01 179.13 66.15C178.84 66.29 178.62 66.5 178.44 66.75C178.26 67 178.13 67.29 178.05 67.62C177.97 67.95 177.93 68.29 177.93 68.65C177.93 68.99 177.96 69.33 178.03 69.67C178.1 70.01 178.21 70.31 178.38 70.59C178.54 70.87 178.76 71.09 179.03 71.26C179.3 71.43 179.64 71.51 180.04 71.51C180.47 71.51 180.82 71.42 181.1 71.25Z"
          fill="black"
        />
        <path
          d="M190.68 72.03C190.12 72.45 189.41 72.67 188.56 72.67C187.96 72.67 187.44 72.57 187 72.38C186.56 72.19 186.19 71.92 185.89 71.57C185.59 71.22 185.37 70.81 185.22 70.32C185.07 69.84 184.99 69.31 184.97 68.74C184.97 68.17 185.06 67.65 185.23 67.17C185.4 66.7 185.65 66.29 185.96 65.94C186.27 65.59 186.65 65.32 187.08 65.13C187.51 64.94 187.98 64.84 188.49 64.84C189.16 64.84 189.71 64.98 190.15 65.25C190.59 65.52 190.94 65.88 191.21 66.3C191.48 66.73 191.66 67.19 191.76 67.69C191.86 68.19 191.9 68.67 191.88 69.13H186.27C186.26 69.46 186.3 69.77 186.39 70.07C186.48 70.37 186.62 70.63 186.81 70.85C187 71.08 187.25 71.26 187.55 71.39C187.85 71.53 188.2 71.59 188.61 71.59C189.13 71.59 189.56 71.47 189.89 71.23C190.22 70.99 190.44 70.62 190.55 70.13H191.77C191.6 70.97 191.24 71.6 190.68 72.03ZM190.38 67.21C190.27 66.95 190.12 66.72 189.93 66.54C189.74 66.36 189.52 66.2 189.26 66.09C189 65.98 188.72 65.92 188.41 65.92C188.09 65.92 187.8 65.98 187.55 66.09C187.29 66.2 187.07 66.35 186.89 66.55C186.71 66.74 186.56 66.97 186.45 67.22C186.34 67.48 186.28 67.75 186.26 68.04H190.57C190.56 67.75 190.49 67.47 190.38 67.21Z"
          fill="black"
        />
        <path
          d="M194.32 65.01V66.2H194.35C194.86 65.29 195.67 64.84 196.79 64.84C197.28 64.84 197.69 64.91 198.02 65.04C198.35 65.18 198.61 65.36 198.82 65.61C199.02 65.85 199.17 66.14 199.25 66.47C199.33 66.8 199.37 67.17 199.37 67.58V72.51H198.14V67.43C198.14 66.97 198 66.6 197.73 66.33C197.46 66.06 197.09 65.92 196.61 65.92C196.23 65.92 195.91 65.98 195.63 66.09C195.35 66.21 195.12 66.37 194.94 66.58C194.76 66.79 194.62 67.04 194.53 67.33C194.44 67.62 194.39 67.93 194.39 68.27V72.5H193.16V65H194.32V65.01Z"
          fill="black"
        />
        <path
          d="M206.89 62.15V65.89H205.9V62.15H206.89ZM208.92 62.15V65.89H207.93V62.15H208.92Z"
          fill="black"
        />
        <path
          d="M216.89 72.51V71.49H216.86C216.66 71.91 216.34 72.21 215.9 72.39C215.46 72.57 214.99 72.67 214.46 72.67C213.88 72.67 213.37 72.56 212.94 72.35C212.51 72.14 212.15 71.85 211.87 71.49C211.58 71.13 211.37 70.72 211.22 70.24C211.08 69.77 211 69.26 211 68.73C211 68.2 211.07 67.7 211.21 67.22C211.35 66.75 211.56 66.33 211.85 65.98C212.14 65.63 212.49 65.35 212.92 65.14C213.35 64.93 213.85 64.83 214.42 64.83C214.61 64.83 214.82 64.85 215.04 64.89C215.26 64.93 215.48 64.99 215.71 65.09C215.94 65.19 216.14 65.3 216.34 65.45C216.54 65.6 216.7 65.79 216.84 66.01H216.87V62.15H218.1V72.5H216.89V72.51ZM212.46 69.85C212.55 70.18 212.69 70.48 212.88 70.74C213.07 71 213.31 71.21 213.61 71.36C213.9 71.51 214.25 71.59 214.65 71.59C215.07 71.59 215.42 71.51 215.71 71.34C216 71.17 216.24 70.96 216.42 70.69C216.6 70.42 216.74 70.12 216.82 69.78C216.9 69.44 216.94 69.1 216.94 68.75C216.94 68.38 216.9 68.03 216.81 67.68C216.72 67.34 216.58 67.03 216.4 66.77C216.21 66.51 215.97 66.3 215.67 66.14C215.37 65.98 215.01 65.9 214.58 65.9C214.16 65.9 213.81 65.98 213.52 66.15C213.23 66.32 213 66.53 212.82 66.8C212.65 67.07 212.52 67.38 212.44 67.73C212.36 68.08 212.32 68.44 212.32 68.8C212.33 69.17 212.37 69.51 212.46 69.85Z"
          fill="black"
        />
        <path
          d="M226.67 72.48C226.46 72.61 226.16 72.67 225.79 72.67C225.47 72.67 225.22 72.58 225.03 72.4C224.84 72.22 224.75 71.93 224.75 71.52C224.41 71.93 224.02 72.22 223.57 72.4C223.12 72.58 222.63 72.67 222.11 72.67C221.77 72.67 221.45 72.63 221.15 72.55C220.85 72.47 220.58 72.35 220.36 72.19C220.14 72.03 219.96 71.81 219.83 71.54C219.7 71.27 219.63 70.95 219.63 70.58C219.63 70.16 219.7 69.81 219.85 69.54C220 69.27 220.19 69.05 220.42 68.88C220.66 68.71 220.93 68.58 221.23 68.5C221.53 68.41 221.85 68.34 222.17 68.28C222.51 68.21 222.83 68.16 223.13 68.13C223.43 68.1 223.7 68.05 223.93 67.99C224.16 67.93 224.35 67.84 224.48 67.71C224.61 67.58 224.68 67.41 224.68 67.18C224.68 66.91 224.63 66.69 224.53 66.53C224.43 66.36 224.3 66.24 224.14 66.15C223.98 66.06 223.8 66.01 223.6 65.98C223.4 65.95 223.21 65.94 223.01 65.94C222.49 65.94 222.05 66.04 221.7 66.24C221.35 66.44 221.16 66.81 221.13 67.36H219.9C219.92 66.9 220.02 66.5 220.19 66.19C220.36 65.87 220.6 65.61 220.89 65.41C221.18 65.21 221.51 65.07 221.88 64.98C222.25 64.89 222.65 64.85 223.08 64.85C223.42 64.85 223.75 64.87 224.09 64.92C224.42 64.97 224.73 65.07 225 65.22C225.27 65.37 225.49 65.58 225.65 65.85C225.81 66.12 225.9 66.47 225.9 66.91V70.76C225.9 71.05 225.92 71.26 225.95 71.4C225.98 71.54 226.1 71.6 226.29 71.6C226.4 71.6 226.52 71.58 226.67 71.53V72.48ZM224.66 68.62C224.51 68.74 224.3 68.82 224.05 68.87C223.8 68.92 223.54 68.97 223.26 69C222.98 69.03 222.71 69.07 222.43 69.12C222.15 69.16 221.9 69.23 221.68 69.33C221.46 69.43 221.28 69.56 221.14 69.74C221 69.92 220.93 70.16 220.93 70.47C220.93 70.67 220.97 70.85 221.05 70.98C221.13 71.12 221.24 71.23 221.37 71.32C221.5 71.41 221.65 71.47 221.83 71.51C222 71.55 222.19 71.57 222.38 71.57C222.79 71.57 223.13 71.52 223.42 71.4C223.71 71.28 223.95 71.15 224.13 70.98C224.31 70.81 224.45 70.63 224.54 70.43C224.63 70.23 224.67 70.05 224.67 69.87V68.62H224.66Z"
          fill="black"
        />
        <path
          d="M228.97 65.01V66.2H229C229.51 65.29 230.32 64.84 231.44 64.84C231.93 64.84 232.34 64.91 232.67 65.04C233 65.18 233.26 65.36 233.47 65.61C233.67 65.85 233.82 66.14 233.9 66.47C233.98 66.8 234.02 67.17 234.02 67.58V72.51H232.79V67.43C232.79 66.97 232.65 66.6 232.38 66.33C232.11 66.06 231.74 65.92 231.26 65.92C230.88 65.92 230.56 65.98 230.28 66.09C230 66.21 229.77 66.37 229.59 66.58C229.41 66.79 229.27 67.04 229.18 67.33C229.09 67.62 229.04 67.93 229.04 68.27V72.5H227.81V65H228.97V65.01Z"
          fill="black"
        />
        <path
          d="M237.03 65.01V66.11H237.06C237.62 65.26 238.43 64.83 239.48 64.83C239.94 64.83 240.36 64.93 240.74 65.12C241.12 65.31 241.38 65.64 241.54 66.11C241.79 65.7 242.12 65.39 242.53 65.17C242.94 64.95 243.39 64.84 243.89 64.84C244.27 64.84 244.61 64.88 244.91 64.96C245.21 65.04 245.48 65.17 245.69 65.34C245.91 65.51 246.08 65.74 246.2 66.01C246.32 66.29 246.38 66.62 246.38 67V72.5H245.15V67.58C245.15 67.35 245.13 67.13 245.09 66.93C245.05 66.73 244.98 66.55 244.87 66.4C244.76 66.25 244.62 66.13 244.43 66.04C244.24 65.95 244 65.91 243.7 65.91C243.09 65.91 242.61 66.08 242.26 66.43C241.91 66.78 241.74 67.24 241.74 67.82V72.49H240.51V67.57C240.51 67.33 240.49 67.11 240.45 66.9C240.41 66.69 240.33 66.52 240.22 66.37C240.11 66.22 239.97 66.1 239.79 66.02C239.61 65.94 239.38 65.9 239.1 65.9C238.74 65.9 238.43 65.97 238.18 66.12C237.92 66.27 237.72 66.44 237.56 66.64C237.4 66.84 237.28 67.05 237.21 67.27C237.14 67.49 237.1 67.67 237.1 67.81V72.48H235.87V64.98H237.03V65.01Z"
          fill="black"
        />
        <path
          d="M254.88 72.48C254.67 72.61 254.37 72.67 254 72.67C253.68 72.67 253.43 72.58 253.24 72.4C253.05 72.22 252.96 71.93 252.96 71.52C252.62 71.93 252.23 72.22 251.78 72.4C251.33 72.58 250.84 72.67 250.32 72.67C249.98 72.67 249.66 72.63 249.36 72.55C249.06 72.47 248.79 72.35 248.57 72.19C248.35 72.03 248.17 71.81 248.04 71.54C247.91 71.27 247.84 70.95 247.84 70.58C247.84 70.16 247.91 69.81 248.06 69.54C248.21 69.27 248.4 69.05 248.63 68.88C248.87 68.71 249.14 68.58 249.44 68.5C249.74 68.41 250.06 68.34 250.38 68.28C250.72 68.21 251.04 68.16 251.34 68.13C251.64 68.1 251.91 68.05 252.14 67.99C252.37 67.93 252.56 67.84 252.69 67.71C252.82 67.58 252.89 67.41 252.89 67.18C252.89 66.91 252.84 66.69 252.74 66.53C252.64 66.36 252.51 66.24 252.35 66.15C252.19 66.06 252.01 66.01 251.81 65.98C251.61 65.95 251.42 65.94 251.22 65.94C250.7 65.94 250.26 66.04 249.91 66.24C249.56 66.44 249.37 66.81 249.34 67.36H248.11C248.13 66.9 248.23 66.5 248.4 66.19C248.57 65.87 248.81 65.61 249.1 65.41C249.39 65.21 249.72 65.07 250.09 64.98C250.46 64.89 250.86 64.85 251.29 64.85C251.63 64.85 251.96 64.87 252.3 64.92C252.63 64.97 252.94 65.07 253.21 65.22C253.48 65.37 253.7 65.58 253.86 65.85C254.02 66.12 254.11 66.47 254.11 66.91V70.76C254.11 71.05 254.13 71.26 254.16 71.4C254.19 71.54 254.31 71.6 254.5 71.6C254.61 71.6 254.73 71.58 254.88 71.53V72.48ZM252.88 68.62C252.73 68.74 252.52 68.82 252.27 68.87C252.02 68.92 251.76 68.97 251.48 69C251.2 69.03 250.93 69.07 250.65 69.12C250.37 69.16 250.12 69.23 249.9 69.33C249.68 69.43 249.5 69.56 249.36 69.74C249.22 69.92 249.15 70.16 249.15 70.47C249.15 70.67 249.19 70.85 249.27 70.98C249.35 71.12 249.46 71.23 249.59 71.32C249.72 71.41 249.87 71.47 250.05 71.51C250.22 71.55 250.41 71.57 250.6 71.57C251.01 71.57 251.35 71.52 251.64 71.4C251.93 71.28 252.17 71.15 252.35 70.98C252.53 70.81 252.67 70.63 252.76 70.43C252.85 70.23 252.89 70.05 252.89 69.87V68.62H252.88Z"
          fill="black"
        />
        <path
          d="M257.14 65.01V66.59H257.17C257.47 65.98 257.84 65.53 258.27 65.24C258.71 64.95 259.26 64.81 259.92 64.83V66.13C259.43 66.13 259.01 66.2 258.66 66.33C258.31 66.47 258.03 66.66 257.82 66.92C257.61 67.18 257.45 67.5 257.36 67.87C257.26 68.24 257.21 68.67 257.21 69.15V72.48H255.98V64.98H257.14V65.01Z"
          fill="black"
        />
        <path
          d="M262.16 62.15V68.28L265.58 65H267.23L264.26 67.73L267.45 72.5H265.88L263.31 68.58L262.15 69.65V72.49H260.92V62.15H262.16Z"
          fill="black"
        />
        <path
          d="M270.02 62.15V65.89H269.03V62.15H270.02ZM272.05 62.15V65.89H271.06V62.15H272.05Z"
          fill="black"
        />
        <path
          d="M64.23 35.28C63.24 21.36 51.26 21.8 51.26 21.8C42.95 21.81 38.97 28.62 38.97 28.62C38.97 28.62 34.97 21.83 26.66 21.84C26.66 21.84 14.68 21.43 13.73 35.35C13.73 35.35 12.66 45.07 27.89 53.35C27.89 53.35 36.32 58.52 39.02 63.26C40.78 60.16 44.97 56.87 47.67 54.96L42.98 50.27C42.77 50.06 42.62 49.82 42.51 49.57C41.25 50.32 39.8 50.73 38.29 50.73C36.08 50.73 34.01 49.87 32.45 48.31C30.89 46.75 30.03 44.67 30.03 42.47C30.03 40.26 30.89 38.19 32.45 36.63C34.01 35.07 36.09 34.21 38.29 34.21C40.5 34.21 42.57 35.07 44.13 36.63C45.69 38.19 46.55 40.27 46.55 42.47C46.55 43.98 46.14 45.42 45.39 46.69C45.64 46.8 45.88 46.95 46.09 47.16L51.48 52.55C65.39 44.33 64.23 35.28 64.23 35.28Z"
          fill="white"
        />
        <path
          d="M38.29 36.04C36.57 36.04 34.96 36.71 33.74 37.92C32.53 39.13 31.86 40.75 31.86 42.47C31.86 44.19 32.53 45.8 33.74 47.01C34.95 48.22 36.57 48.89 38.29 48.89C40.01 48.89 41.62 48.22 42.83 47.01C44.04 45.8 44.71 44.18 44.71 42.47C44.71 40.75 44.04 39.14 42.83 37.92C41.62 36.71 40.01 36.04 38.29 36.04Z"
          fill="white"
        />
        <path
          d="M111.06 20.2H119.72V48.99H114.11V29.51C114.11 28.95 114.12 28.17 114.13 27.16C114.14 26.15 114.15 25.37 114.15 24.83L108.69 48.99H102.84L97.42 24.83C97.42 25.38 97.43 26.16 97.44 27.16C97.45 28.17 97.46 28.95 97.46 29.51V48.98H91.85V20.2H100.6L105.84 42.84L111.06 20.2Z"
          fill="black"
        />
        <path
          d="M139.1 28.13C140.58 28.8 141.81 29.85 142.78 31.28C143.65 32.55 144.22 34.01 144.48 35.68C144.63 36.66 144.69 38.07 144.66 39.91H129.15C129.24 42.05 129.98 43.54 131.38 44.4C132.23 44.93 133.25 45.2 134.45 45.2C135.72 45.2 136.75 44.87 137.54 44.22C137.97 43.87 138.35 43.38 138.69 42.76H144.37C144.22 44.02 143.53 45.31 142.31 46.61C140.4 48.68 137.73 49.72 134.3 49.72C131.47 49.72 128.97 48.85 126.8 47.1C124.63 45.35 123.55 42.51 123.55 38.58C123.55 34.89 124.53 32.06 126.48 30.1C128.44 28.13 130.97 27.15 134.09 27.15C135.95 27.13 137.62 27.46 139.1 28.13ZM130.77 32.94C129.98 33.75 129.49 34.85 129.29 36.24H138.88C138.78 34.76 138.28 33.64 137.4 32.88C136.51 32.11 135.41 31.73 134.1 31.73C132.67 31.72 131.56 32.13 130.77 32.94Z"
          fill="black"
        />
        <path
          d="M159.16 28.04C160.16 28.61 160.98 29.39 161.6 30.39V20.24H167.24V48.99H161.83V46.04C161.04 47.3 160.13 48.22 159.12 48.79C158.11 49.36 156.84 49.65 155.33 49.65C152.84 49.65 150.75 48.64 149.05 46.63C147.35 44.62 146.5 42.04 146.5 38.89C146.5 35.26 147.34 32.4 149.01 30.32C150.68 28.24 152.92 27.2 155.72 27.2C157.02 27.19 158.16 27.47 159.16 28.04ZM160.53 43.21C161.35 42.04 161.76 40.52 161.76 38.66C161.76 36.06 161.1 34.19 159.79 33.07C158.98 32.39 158.04 32.05 156.98 32.05C155.35 32.05 154.16 32.67 153.4 33.9C152.64 35.13 152.26 36.66 152.26 38.48C152.26 40.45 152.65 42.02 153.42 43.2C154.19 44.38 155.37 44.97 156.95 44.97C158.51 44.96 159.71 44.38 160.53 43.21Z"
          fill="black"
        />
        <path
          d="M178.43 25.16H172.79V20.02H178.43V25.16ZM172.79 27.7H178.43V48.99H172.79V27.7Z"
          fill="black"
        />
        <path
          d="M196.96 35.33C196.86 34.54 196.59 33.82 196.16 33.18C195.53 32.32 194.56 31.89 193.24 31.89C191.36 31.89 190.07 32.82 189.38 34.68C189.01 35.67 188.83 36.98 188.83 38.62C188.83 40.18 189.01 41.43 189.38 42.38C190.05 44.15 191.3 45.04 193.14 45.04C194.45 45.04 195.37 44.69 195.92 43.99C196.47 43.29 196.8 42.38 196.92 41.26H202.6C202.47 42.95 201.86 44.55 200.76 46.06C199.01 48.5 196.43 49.71 193 49.71C189.57 49.71 187.05 48.69 185.43 46.66C183.81 44.63 183.01 41.99 183.01 38.76C183.01 35.11 183.9 32.27 185.69 30.24C187.48 28.21 189.94 27.19 193.08 27.19C195.75 27.19 197.94 27.79 199.64 28.99C201.34 30.19 202.35 32.3 202.66 35.34H196.96V35.33Z"
          fill="black"
        />
        <path
          d="M212.59 25.16H206.95V20.02H212.59V25.16ZM206.95 27.7H212.59V48.99H206.95V27.7Z"
          fill="black"
        />
        <path
          d="M235.55 28.92C236.94 30.07 237.64 31.98 237.64 34.65V48.99H231.94V36.04C231.94 34.92 231.79 34.06 231.49 33.46C230.95 32.37 229.91 31.82 228.39 31.82C226.52 31.82 225.23 32.62 224.53 34.22C224.17 35.07 223.99 36.15 223.99 37.46V48.98H218.42V27.74H223.81V30.85C224.52 29.76 225.2 28.97 225.83 28.49C226.97 27.63 228.42 27.2 230.17 27.2C232.36 27.19 234.15 27.77 235.55 28.92Z"
          fill="black"
        />
        <path
          d="M262.18 20.2V25.3H253.57V48.99H247.52V25.3H238.87V20.2H262.18Z"
          fill="black"
        />
        <path
          d="M263.27 53.07C263.58 53.1 263.8 53.11 263.93 53.12C264.06 53.13 264.18 53.13 264.28 53.13C264.83 53.13 265.24 53 265.52 52.73C265.8 52.46 265.94 51.95 265.94 51.18V27.8H271.51V51.28C271.51 53.43 271.09 55.02 270.24 56.05C269.39 57.08 267.73 57.59 265.26 57.59C265.09 57.59 264.84 57.58 264.51 57.57C264.18 57.56 263.76 57.54 263.27 57.53V53.07ZM271.51 25.16H265.94V20.02H271.51V25.16Z"
          fill="black"
        />
        <path
          d="M291.11 28.13C292.59 28.8 293.82 29.85 294.79 31.28C295.66 32.55 296.23 34.01 296.49 35.68C296.64 36.66 296.7 38.07 296.67 39.91H281.16C281.25 42.05 281.99 43.54 283.39 44.4C284.24 44.93 285.26 45.2 286.46 45.2C287.73 45.2 288.76 44.87 289.55 44.22C289.98 43.87 290.36 43.38 290.7 42.76H296.38C296.23 44.02 295.54 45.31 294.32 46.61C292.41 48.68 289.74 49.72 286.31 49.72C283.48 49.72 280.98 48.85 278.81 47.1C276.64 45.35 275.56 42.51 275.56 38.58C275.56 34.89 276.54 32.06 278.49 30.1C280.44 28.14 282.98 27.15 286.1 27.15C287.96 27.13 289.63 27.46 291.11 28.13ZM282.79 32.94C282 33.75 281.51 34.85 281.31 36.24H290.9C290.8 34.76 290.3 33.64 289.42 32.88C288.53 32.11 287.43 31.73 286.12 31.73C284.68 31.72 283.57 32.13 282.79 32.94Z"
          fill="black"
        />
        <path
          d="M299.87 20.3H305.34V35.83L312.36 27.8H319.28L311.65 35.73L319.58 48.99H312.81L307.65 39.89L305.34 42.29V48.99H299.87V20.3Z"
          fill="black"
        />
        <path
          d="M322.56 20.3H328.03V35.83L335.05 27.8H341.97L334.34 35.73L342.27 48.99H335.5L330.34 39.89L328.03 42.29V48.99H322.56V20.3Z"
          fill="black"
        />
        <path
          d="M359.17 28.13C360.65 28.8 361.88 29.85 362.85 31.28C363.72 32.55 364.29 34.01 364.55 35.68C364.7 36.66 364.76 38.07 364.73 39.91H349.22C349.31 42.05 350.05 43.54 351.45 44.4C352.3 44.93 353.32 45.2 354.52 45.2C355.79 45.2 356.82 44.87 357.61 44.22C358.04 43.87 358.42 43.38 358.76 42.76H364.44C364.29 44.02 363.6 45.31 362.38 46.61C360.47 48.68 357.8 49.72 354.37 49.72C351.54 49.72 349.04 48.85 346.87 47.1C344.7 45.35 343.62 42.51 343.62 38.58C343.62 34.89 344.6 32.06 346.55 30.1C348.5 28.14 351.04 27.15 354.16 27.15C356.02 27.13 357.69 27.46 359.17 28.13ZM350.84 32.94C350.05 33.75 349.56 34.85 349.36 36.24H358.95C358.85 34.76 358.35 33.64 357.47 32.88C356.58 32.11 355.48 31.73 354.17 31.73C352.74 31.72 351.63 32.13 350.84 32.94Z"
          fill="black"
        />
        <path
          d="M379.81 27.2C379.88 27.21 380.04 27.22 380.29 27.23V32.93C379.94 32.89 379.63 32.87 379.35 32.85C379.08 32.84 378.85 32.83 378.69 32.83C376.45 32.83 374.95 33.56 374.18 35.02C373.75 35.84 373.54 37.1 373.54 38.81V48.99H367.93V27.7H373.24V31.41C374.1 29.99 374.85 29.02 375.49 28.5C376.53 27.63 377.89 27.19 379.55 27.19C379.65 27.19 379.74 27.19 379.81 27.2Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_59">
          <rect width="380.29" height="81.18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
