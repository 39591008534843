import React, { Component } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'

import Text from 'ui/components/generic/Text'
import withScrollEntry from 'ui/styles/withScrollEntry'
import { SlideIn } from 'ui/styles/utils'

const Index = styled.div`
  font-weight: ${props => props.theme.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
`

const Wrapper = styled(SlideIn)`
  padding: 30px 0;
`

const StepHeader = styled(Text).attrs({ size: 'sm', as: 'h3' })`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 30px;
`
const StepBody = styled(Text).attrs({ size: 'sm' })``

class Step extends Component {
  render() {
    const { step, visible, index } = this.props

    return (
      <Wrapper visible={visible}>
        <Row>
          <Col sm={3} md={2}>
            <Index>{index + 1}</Index>
          </Col>
          <Col sm={9} md={10}>
            {step.header && <StepHeader>{step.header}</StepHeader>}
            {step.body && (
              <StepBody dangerouslySetInnerHTML={{ __html: step.body }} />
            )}
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default withScrollEntry()(Step)
