import { FC, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { usePrevious } from 'react-use'

import usePatientInfo from 'containers/usePatientInfo'
import useOverlay from 'containers/useOverlay'
import useSiteMeta from 'containers/useSiteMeta'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import InfoBar from './InfoBar'
import responsiveText from 'ui/styles/responsiveText'

type Props = {
  onContinue: Function
}
const LandingPage: FC<Props> = ({ onContinue }) => {
  const { acceptedTerms } = usePatientInfo()
  const prevAcceptedTerms = usePrevious(acceptedTerms)

  const {
    actions: { showOverlay },
  } = useOverlay()

  const handleClick = useCallback(() => {
    if (acceptedTerms) {
      onContinue()
    } else {
      showOverlay('terms')
    }
  }, [acceptedTerms, showOverlay, onContinue])

  useEffect(() => {
    if (prevAcceptedTerms === false && acceptedTerms) onContinue()
  }, [acceptedTerms, prevAcceptedTerms, onContinue])

  const {
    data: { aboutSlug },
  } = useSiteMeta()

  return (
    <Wrapper>
      <PaddedContent>
        <Content>
          <h1>Tjek dine symptomer på 2 minutter</h1>
          <Text as="p" size="md">
            Her får du mulighed for helt anonymt at indtaste dine eller en
            andens symptomer og få en indikation af, hvad symptomerne kan være
            tegn på
          </Text>
          <Actions>
            <Button onClick={handleClick} color="primary">
              Start
            </Button>
            <Or>eller</Or>
            <SecondaryLink to={`/${aboutSlug}`}>
              Læs mere om SymptomTjekker
            </SecondaryLink>
          </Actions>
        </Content>
      </PaddedContent>
      <InfoBar visible />
    </Wrapper>
  )
}

export default LandingPage

const Wrapper = styled.div`
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`

const Content = styled.div`
  text-align: center;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Or = styled(Text).attrs({ size: 'sm' })`
  margin: 15px 0;
  color: ${(props) => props.theme.colors.tones.dark};
`

const SecondaryLink = styled(Link)`
  ${responsiveText('sm')}
  font-weight: bold;
  color: ${(props) => props.theme.colors.tones.dark};
`
