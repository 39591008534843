import React from 'react'
import styled from 'styled-components'

import ConditionGroup from './ConditionGroup'
import { Condition } from 'containers/useDiagnosis'
import Text from 'ui/components/generic/Text'
import { media } from 'styled-bootstrap-grid'
import theme from 'ui/styles/theme'

export const probabilityOffsets = {
  high: 0.8,
  medium: 0.5,
  low: 0.2,
}

type Props = {
  conditions: Condition[]
}

const Results: React.FC<Props> = ({ conditions }) => {
  const { high, medium, low } = probabilityOffsets
  const filteredConditions = conditions.filter(
    (condition) => condition.probability > low
  )

  const highProbability = filteredConditions.filter(
    (condition) => condition.probability >= high
  )
  const mediumProbability = filteredConditions.filter(
    (condition) =>
      condition.probability < high && condition.probability >= medium
  )
  const lowProbability = filteredConditions.filter(
    (condition) => condition.probability < medium
  )

  return (
    <Wrapper>
      <Box>
        <Figure>
          <Count>{filteredConditions.length}</Count>
          <Label>resultat{filteredConditions.length > 1 ? 'er' : ''}</Label>
        </Figure>
        <ConditionGroup
          conditions={highProbability}
          header={
            <div>
              De angivne symptomer passer{' '}
              <strong>
                <u>i høj grad</u>
              </strong>{' '}
              på disse sygdomme
            </div>
          }
        />
        <ConditionGroup
          conditions={mediumProbability}
          header={
            <div>
              De angivne symptomer passer <strong>i nogen grad</strong> til
              disse sygdomme
            </div>
          }
        />
        <ConditionGroup
          conditions={lowProbability}
          header={
            <div>
              De angivne symptomer passer <strong>i lav grad</strong> til disse
              sygdomme
            </div>
          }
        />
      </Box>
    </Wrapper>
  )
}

export default Results

const Wrapper = styled.div`
  padding-top: 86px;
  padding-bottom: 50px;
`

const Box = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 70px 20px 20px 20px;
  border-radius: 25px;

  ${media.md`
    padding: 114px 20px 20px 20px;
  `}
`

const Figure = styled.div`
  position: absolute;
  top: -50px;
  left: 50%;
  width: 100px;
  height: 100px;
  padding: 5px;

  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 50%;
  background-color: #fff;

  ${media.md`
    top: -86px;
    left: 50%;
    width: 173px;
    height: 173px;
    padding: 8px;
  `}

  &:after {
    position: absolute;
    content: '';
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    top: 5px;
    left: 5px;
    border: 5px solid ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    background-color: transparent;

    ${media.md`
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      top: 8px;
      left: 8px;
      border: 8px solid ${() => theme.colors.primary};
    `}
  }
`

const Count = styled(Text).attrs({ size: 'xl', weight: 'bold' })`
  margin: 0;
  line-height: 1;
`

const Label = styled.div`
  margin: 0;
  font-size: 12px;

  ${media.md`
    font-size: 22px;
  `}
`
