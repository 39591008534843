import styled from 'styled-components'
import Text from 'ui/components/generic/Text'

const Feedback = () => {
  return (
    <Wrapper>
      <Box>
        <Header>Gå til din læge, hvis du er i tvivl</Header>
        <Content size="sm">
          SymptomTjekker stiller{' '}
          <strong>
            <em>ikke en diagnose og er ikke en erstatning for lægen</em>
          </strong>
          . Du skal altid <em>kontakte din læge</em>, hvis du bliver syg eller
          har ondt. I Sygeforsikringen "danmark” har vi hverken adgang til dine
          søgninger eller resultater, ligesom vi ikke har nogen lægefaglig
          ekspertise ansat til at hjælpe dig videre. Dit helbred er noget, du
          fortsat skal kontakte din egen læge for at drøfte.
        </Content>
      </Box>
    </Wrapper>
  )
}

export default Feedback

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Box = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 25px;
  overflow: hidden;
`

const Header = styled(Text).attrs({
  as: 'h1',
  size: 'lg',
})`
  font-weight: ${(props) => props.theme.fontWeights.medium};
`

const Content = styled(Text)`
  color: ${(props) => props.theme.colors.tones.medium};
`
