import React from 'react'
import posed, { PoseGroup } from 'react-pose'

export const SlideInOutGroup = props => (
  <PoseGroup preEnterPose="pre" animateOnMount {...props} />
)

export const transition = {
  duration: 700,
  ease: 'easeInOut'
}

export const SlideInOut = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    transition,
    staggerChildren: 50,
    delayChildren: 200
  },
  exit: {
    opacity: 0,
    x: ({ direction, directionExit, amount }) => {
      if (direction === 'left' || directionExit === 'left') {
        return `-${amount || 50}px`
      } else if (direction === 'right' || directionExit === 'right') {
        return `${amount || 50}px`
      }
      return '0px'
    },
    y: ({ direction, directionExit, amount }) => {
      if (
        direction === 'up' ||
        directionExit === 'up' ||
        typeof direction === 'undefined'
      ) {
        return `-${amount || 50}px`
      } else if (direction === 'down' || directionExit === 'down') {
        return `${amount || 50}px`
      }
      return '0px'
    },
    transition
  },
  pre: {
    opacity: 0,
    x: ({ direction, amount }) => {
      if (direction === 'left') {
        return `${amount || 50}px`
      } else if (direction === 'right') {
        return `-${amount || 50}px`
      }
      return '0px'
    },
    y: ({ direction, amount }) => {
      if (direction === 'up' || typeof direction === 'undefined') {
        return `${amount || 50}px`
      } else if (direction === 'down') {
        return `-${amount || 50}px`
      }
      return '0px'
    }
  },
  flip: {
    transition
  }
})

export const FadeInOut = posed.div({
  enter: {
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0,
    transition
  },
  pre: {
    opacity: 0
  },
  flip: {
    transition
  }
})
