import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  padding: 50px 0;

  img {
    max-width: 100%;
  }
`

class Image extends Component {
  render() {
    const {
      image: { sourceUrl, altText }
    } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={10} mdOffset={1}>
              <img src={sourceUrl} alt={altText} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default Image
