import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { reverse } from 'named-urls'
import { Link, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import useList from 'containers/useList'
import routes from 'routes'

import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import Spinner from 'ui/components/generic/Spinner'
import PaddedContent from 'ui/components/generic/PaddedContent'

const List = (props) => {
  const { type } = props

  const params = useParams()

  const letter = params.letter || 'a'
  const child = params.child
  const isChild = params.child === 'barn'

  const { data: list, loading: listLoading } = useList(type, letter, isChild)

  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'æ',
    'ø',
    'å',
  ]

  return (
    <Wrapper>
      <PaddedContent large>
        <Header>
          {type === 'conditions'
            ? 'Understøttede sygdomme'
            : 'Understøttede symptomer'}
        </Header>
        {type === 'conditions' && (
          <Text size="sm" mb={30}>
            <p>
              Følgende er en liste over sygdomme som indgår i SymptomTjekkers
              database. SymptomTjekker opdateres løbende med præciseringer, nye
              sygdomme, symptomer og spørgsmål. Der er derfor tale om en
              dynamisk database, hvorfor nedenstående liste alene er vejledende.
              Der kan optræde sygdomme på listen, som endnu ikke er koblet
              sammen med symptomer eller interviewspørgsmål. Du kan derfor ikke
              bruge SymptomTjekker til at udelukke sygdomme, men bør altid søge
              sundhedsfaglig hjælp, hvis du føler dig syg eller mistænker en
              særlig sygdom.
            </p>
          </Text>
        )}
        <Box>
          <Label>Aldersgruppe:</Label>
          <Buttons>
            <Button
              color="primary"
              hollow={!isChild}
              to={reverse(
                type === 'conditions'
                  ? routes.conditionList
                  : routes.symptomList,
                { letter, child: 'barn' }
              )}
            >
              Børn (u12)
            </Button>{' '}
            <Button
              color="primary"
              hollow={isChild}
              to={reverse(
                type === 'conditions'
                  ? routes.conditionList
                  : routes.symptomList,
                { letter, child: undefined }
              )}
            >
              Voksne
            </Button>
          </Buttons>
        </Box>
        <Box>
          {letters.map((l) => (
            <Letter selected={l === letter} key={l}>
              <Link
                to={reverse(
                  type === 'conditions'
                    ? routes.conditionList
                    : routes.symptomList,
                  { letter: l, child }
                )}
              >
                {l}
              </Link>
            </Letter>
          ))}
        </Box>
        <AnimatePresence>
          {listLoading && <Spinner />}
          <ItemsContainer>
            {list && (
              <Items key={letter} {...transitions}>
                {list.map((item) => (
                  <Item key={item.id}>
                    <ItemContent>
                      {item.commonName || item.name}
                      {type === 'conditions' &&
                      item.commonName &&
                      item.name &&
                      item.name !== item.commonName ? (
                        <ProName>&nbsp;({item.name})</ProName>
                      ) : (
                        ''
                      )}
                    </ItemContent>
                  </Item>
                ))}
                {list.length === 0 && (
                  <Item key="no-results">
                    Ingen resultater under dette bogstav.
                  </Item>
                )}
              </Items>
            )}
          </ItemsContainer>
        </AnimatePresence>
      </PaddedContent>
    </Wrapper>
  )
}

export default List

const Wrapper = styled.div`
  padding: 100px 0;
`

const Header = styled.h1``

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;

  &:after {
    content: '';
    flex: auto;
  }
`

const Buttons = styled.div`
  ${media.xs`
    width: 100%;
    display: flex;
    margin-top: 10px;

    > a {
      min-width: 130px;
      width: auto;
      margin: 0 !important;
      margin-right: 5px !important;
    }
  `}
`

const Letter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  background-color: #fff;
  margin-right: 1px;

  width: 30px;
  height: 30px;

  border-radius: 50%;

  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary : 'transparent'};

  a {
    color: ${(props) => (props.selected ? '#fff' : props.theme.colors.primary)};

    transition: 500ms;
  }

  transition: 500ms;
`

const Label = styled(Text).attrs({ size: 'sm', weight: 'bold' })`
  margin-right: 10px;
`

const transitions = {
  initial: {
    x: 30,
    opacity: 0,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    type: 'tween',
    duration: 0.5,
  },
}

const Items = styled(motion.ul)`
  padding: 0;
  margin: 0;
`

const ItemsContainer = styled.div`
  position: relative;
`

const Item = styled.li`
  display: flex;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 4px;

  word-wrap: break-word;

  strong {
    &::first-letter {
      text-transform: uppercase;
    }
  }
`

const ItemContent = styled(Text).attrs({
  size: 'sm',
  weight: 'bold',
})`
  width: 100%;
`

const ProName = styled.span`
  font-weight: normal;

  ${media.xs`
    display: none;
  `}
`
