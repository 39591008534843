import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { scrollToModule } from 'utils/scrollTo'
import getRelativeUrl from 'utils/getRelativeUrl'

import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'

export const query = `
    header
    body
    buttonAction
    buttonText
    internalLink
`

class Hero extends Component {
  static propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    buttonAction: PropTypes.string,
    buttonText: PropTypes.string,
    internalLink: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.background = React.createRef()

    this.state = {
      width: null,
      height: null
    }
  }

  componentDidMount() {
    this.setBackgroundSize()
    window.addEventListener('resize', this.setBackgroundSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setBackgroundSize)
  }

  setBackgroundSize = () => {
    if (this.background.current) {
      const { width, height } = this.state
      const { clientWidth, clientHeight } = this.background.current
      if (width !== clientWidth || height !== clientHeight) {
        this.setState({
          width: clientWidth,
          height: clientHeight
        })
      }
    }
  }

  scrollDown = () => {
    scrollToModule(1)
  }

  render() {
    const { header, body, buttonAction, buttonText, internalLink } = this.props

    return (
      <Wrapper>
        <Background ref={this.background}></Background>
        <Container fluid>
          <Row>
            <Col sm={10} smOffset={1} md={8} mdOffset={2}>
              <Caption>
                <Header>{header}</Header>
                <Body dangerouslySetInnerHTML={{ __html: body }} />
                {buttonAction && buttonAction !== 'none' && (
                  <Actions>
                    <Button
                      color="primary"
                      size="large"
                      onClick={
                        buttonAction === 'scroll' ? this.scrollDown : undefined
                      }
                      to={
                        buttonAction === 'internalLink'
                          ? getRelativeUrl(internalLink)
                          : undefined
                      }
                    >
                      {buttonText}
                    </Button>
                  </Actions>
                )}
              </Caption>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default Hero

const entry = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${props => props.theme.navHeight}px);
  align-items: center;
  position: relative;
`

const Header = styled(Text).attrs({
  size: 'xl',
  as: 'h1',
  font: 'display'
})`
  font-weight: ${props => props.theme.fontWeights.bold};
  animation: ${entry} 800ms both;
`

const Body = styled(Text).attrs({
  size: 'md',
  as: 'div'
})`
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: 50px;
  animation: ${entry} 600ms 200ms both;
`

const Actions = styled.div`
  animation: ${entry} 400ms 400ms both;
`

const Background = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const Caption = styled.div`
  text-align: center;
`
