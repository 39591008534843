import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithGlobalSeo extends Component {
    render() {
      return (
        <Query
          query={gql`
            {
              optionsGlobalSeo {
                title
                metaDescription
                facebookTitle
                facebookDescription
                facebookImage {
                  mediaDetails {
                    width
                    height
                  }
                  sourceUrl
                }
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  globalSeoLoading={loading}
                  globalSeo={!loading && !error && data.optionsGlobalSeo}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
