import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { compose } from 'redux'

import withSymptoms from 'containers/withSymptoms'
import withTriage from 'containers/withTriage'
import withPatientInfo from 'containers/withPatientInfo'
import withOverlay from 'containers/withOverlay'

import Button from 'ui/components/generic/Button'
import Spinner from 'ui/components/generic/Spinner'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: ${(props) => props.theme.navHeight}px;
`

const Symptoms = styled.div`
  margin-top: 20px;
`

const Symptom = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 5px;
  font-weight: bold;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button {
    margin-top: 10px;
  }

  ${media.xs`
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0 !important;
    }
  `}
`

class EarlyTriage extends Component {
  static propTypes = {
    ignoreEmergency: PropTypes.bool,
    hideOverlay: PropTypes.func,
    triage: PropTypes.shape({
      triage_level: PropTypes.string,
      serious: PropTypes.shape({
        is_emergency: PropTypes.bool,
        common_name: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }
  continue = () => {
    const { ignoreEmergency, hideOverlay } = this.props
    hideOverlay()
    ignoreEmergency()
  }

  render() {
    const {
      triage,
      patientInfo: { isChild, patient },
    } = this.props

    if (!triage) return <Spinner />

    const { triage_level, serious } = triage

    const shownSymptoms = serious.filter((s) => s.is_emergency)

    return (
      <Wrapper level={triage_level}>
        <PaddedContent large>
          {triage_level === 'emergency_ambulance' && (
            <div>
              <Text size="md">
                Nogle af de angivne symptomer kan være alvorlige. Hvis{' '}
                {patient === 'self' ? 'du' : isChild ? 'barnet' : 'personen'}{' '}
                oplever de angivne symptomer, bør du afbryde interviewet og
                ringe efter en ambulance med det samme.
              </Text>
            </div>
          )}

          {triage_level === 'emergency' && (
            <div>
              <Text size="md">
                Nogle af de angivne symptomer kan være alvorlige. Hvis{' '}
                {patient === 'self' ? 'du' : isChild ? 'barnet' : 'personen'}{' '}
                oplever de angivne symptomer, bør du afbryde interviewet og
                straks kontakte en læge eller lægevagten.
              </Text>
            </div>
          )}

          {shownSymptoms.length > 0 && (
            <Symptoms>
              <Text bold size="xs" as="h4">
                Alvorlige symptomer:
              </Text>
              {shownSymptoms.map((symptom) => (
                <Symptom key={symptom.id}>{symptom.common_name}</Symptom>
              ))}
            </Symptoms>
          )}

          <Actions>
            {triage_level === 'emergency_ambulance' && (
              <Button color="primary" href="tel:112">
                Ring 112
              </Button>
            )}
          </Actions>
          <Actions>
            <Button color="secondary" onClick={this.continue}>
              Fortsæt interviewet
            </Button>
          </Actions>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default compose(
  withOverlay,
  withPatientInfo,
  withSymptoms,
  withTriage()
)(EarlyTriage)
