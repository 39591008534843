import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import prepareAgeQuery from 'utils/prepareAgeQuery'
import usePatientInfo from './usePatientInfo'
import useSymptoms from './useSymptoms'

export const useConditionExplanation = (conditionId: string) => {
  const { age, gender } = usePatientInfo()
  const { symptoms } = useSymptoms()

  const { loading, error, data } = useQuery<ConditionExplanationResponse>(
    gql`
      query conditionExplanation($input: ConditionExplanationInput!) {
        conditionExplanation(input: $input)
          @rest(
            type: "ConditionExplanation"
            path: "explain/"
            method: "POST"
          ) {
          supporting_evidence @type(name: "ExplanationItem") {
            id
            name
            common_name
          }
          conflicting_evidence @type(name: "ExplanationItem") {
            id
            name
            common_name
          }
          unconfirmed_evidence @type(name: "ExplanationItem") {
            id
            name
            common_name
          }
        }
      }
    `,
    {
      variables: {
        input: {
          age: prepareAgeQuery(age),
          sex: gender,
          target: conditionId,
          evidence: symptoms.map((symptom) => ({
            id: symptom.id,
            choice_id: symptom.choiceId,
            source: symptom.source,
          })),
        },
      },
      ssr: false,
    }
  )

  return {
    loading,
    data: data?.conditionExplanation,
    error,
  }
}

type ExplanationItem = {
  id: string
  name: string
  common_name: string
}

type ConditionExplanationResponse = {
  conditionExplanation: {
    supporting_evidence: ExplanationItem[]
    conflicting_evidence: ExplanationItem[]
    unconfirmed_evidence: ExplanationItem[]
  }
}
