import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isInternal from 'utils/isInternal'
import getRelativeUrl from 'utils/getRelativeUrl'

class FlexibleLink extends Component {
  static propTypes = {
    href: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { href, children, ...rest } = this.props

    const internal = href && isInternal(href)

    if (internal) {
      return (
        <Link to={getRelativeUrl(href)} {...rest}>
          {children}
        </Link>
      )
    }

    return (
      <a href={href} {...rest}>
        {children}
      </a>
    )
  }
}

export default FlexibleLink
