import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/store'

import { showDrawer, hideDrawer, DrawerKey } from '../redux/modules/drawer'

const useDrawer = () => {
  const dispatch = useDispatch()

  return {
    ...useSelector((state: RootState) => state.drawer),
    actions: {
      showDrawer: (key: DrawerKey, data?: any) =>
        dispatch(showDrawer(key, data)),
      hideDrawer: () => {
        dispatch(hideDrawer())
      },
    },
  }
}

export type ContentType = RootState['drawer']['content']

export default useDrawer
