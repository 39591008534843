import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reverse } from 'named-urls'

import routes from 'routes'
import { setAge, setGender, setPatient } from 'redux/modules/patientInfo'

import Page from 'ui/components/Page'
import GenderSelect, { Gender } from 'ui/components/views/GenderSelect'
import PatientSelect, { Patient } from 'ui/components/views/PatientSelect'
import AgeSelect from 'ui/components/views/AgeSelect'
import LandingPage from './LandingPage'
import Symptoms from './Symptoms'
import SuggestedSymptoms from './SuggestedSymptoms'
import RiskFactors from './RiskFactors'
import Questions from './Questions'
import Results from './Results'
import { RootState } from 'redux/store'

const Interview = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const patientInfo = useSelector((state: RootState) => state.patientInfo)

  const next = (current: string) => {
    const routeNames = Object.keys(routes.interview)
    const index = routeNames.findIndex((route) => route === current)
    const nextRoute = routeNames[index + 1]

    if (nextRoute) {
      push(reverse(routes.interview[nextRoute]))
    }
  }

  return (
    <Switch location={location}>
      <Route exact path={routes.interview.landing}>
        <Page key="landing">
          <LandingPage onContinue={() => next('landing')} />
        </Page>
      </Route>
      <Route exact path={routes.interview.symptoms}>
        <Page key="symptoms">
          <Symptoms onContinue={() => next('symptoms')} />
        </Page>
      </Route>
      <Route exact path={routes.interview.patientSelect}>
        <Page key="patientSelect">
          <PatientSelect
            onChange={(patient: Patient) => {
              dispatch(setPatient(patient))
              next('patientSelect')
            }}
            patient={patientInfo.patient}
          />
        </Page>
      </Route>
      <Route exact path={routes.interview.genderSelect}>
        <Page key="genderSelect">
          <GenderSelect
            gender={patientInfo.gender}
            onChange={(gender: Gender) => {
              dispatch(setGender(gender))
              next('genderSelect')
            }}
          />
        </Page>
      </Route>
      <Route exact path={routes.interview.ageSelect}>
        <Page key="ageSelect">
          <AgeSelect
            onChange={(age: number | null) => {
              dispatch(setAge(age))
              next('ageSelect')
            }}
            age={patientInfo.age}
          />
        </Page>
      </Route>
      {process.env.REACT_APP_ENABLE_RISK_FACTORS && (
        <Route exact path={routes.interview.riskFactors}>
          <Page key="riskFactors">
            <RiskFactors onContinue={() => next('riskFactors')} />
          </Page>
        </Route>
      )}
      <Route exact path={routes.interview.related}>
        <Page key="related">
          <SuggestedSymptoms onContinue={() => next('related')} />
        </Page>
      </Route>
      <Route exact path={routes.interview.interview}>
        <Page key="interview">
          <Questions onContinue={() => next('interview')} />
        </Page>
      </Route>
      <Route path={routes.interview.results}>
        <Page key="results">
          <Results />
        </Page>
      </Route>
    </Switch>
  )
}

export default Interview
