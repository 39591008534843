import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const useCustomScreenings = () => {
  const query = gql`
    query CustomScreenings {
      optionsCustomScreenings {
        cancer {
          screenings {
            title
            text
            url
            image {
              sourceUrl
            }
            rules {
              symptoms {
                symptom
                choiceId
              }
            }
          }
        }
      }
    }
  `

  const { data, loading, error } = useQuery<CustomScreenings>(query)

  if (error) {
    console.error('Error fetching custom screenings', error)
  }

  return {
    data,
    loading,
    error,
  }
}

export default useCustomScreenings

export type CustomScreenings = {
  optionsCustomScreenings: {
    cancer: {
      screenings: {
        title: string
        text: string
        url: string
        image: {
          sourceUrl: string
        }
        rules: {
          symptoms: {
            symptom: string
            choiceId: string
          }[]
        }
      }[]
    }
  }
}
