import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'ui/styles/theme'

const IconStar = ({ stroke, fill, ...rest }) => (
  <Svg
    width="34"
    height="31"
    viewBox="0 0 34 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.278 1c.875.015 3.912.232 6.168 2.548 1.263 1.297 2.308 3.255 2.536 6.256.034.274.295 3.003-2.37 6.374-1.296 1.64-3.315 3.45-6.475 5.169-1.234.822-4.943 3.25-6.259 5.528-.364.63-.646 1.426-.866 2.276-.219-.848-.501-1.645-.866-2.276-1.48-2.563-6.012-5.324-6.256-5.47C.26 16.199.976 10.14 1.014 9.863 1.667.84 9.356 1.017 9.704 1.029c3.736.008 6.113 2.047 7.264 3.371 1.272-1.395 3.638-3.378 7.31-3.4z"
      fill={fill || 'transparent'}
      fill-rule="nonzero"
      stroke={stroke || theme.colors.primary}
      stroke-width="2"
      stroke-linecap="round"
    />
  </Svg>
)

const Svg = styled.svg`
  width: 30px;
  height: auto;

  path {
    transition: all 250ms;
  }
`

IconStar.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
}

IconStar.defaultProps = {
  stroke: '#000',
  fill: 'transparent',
}

export default IconStar
