import React from 'react'
import PropTypes from 'prop-types'

const IconInfo = ({ color, ...rest }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={0}
    fill={color || '#abb8c4'}
    stroke={color || '#abb8c4'}
    width={16}
    {...rest}
  >
    <circle
      fill="none"
      stroke={color || '#abb8c4'}
      strokeWidth={2}
      cx={8}
      cy={8}
      r={7}
    />
    <path d="M7 7h2v5H7zm0-3h2v2H7z" stroke="none" />
  </svg>
)

IconInfo.propTypes = {
  color: PropTypes.string
}

export default IconInfo
