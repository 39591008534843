import { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Container, media, Row, Col } from 'styled-bootstrap-grid'
import { useKey } from 'react-use'

import usePatientInfo from 'containers/usePatientInfo'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import RangeSlider from 'ui/components/generic/RangeSlider'

type Props = {
  age: number | null
  onChange: Function
}

const AgeSelect: FC<Props> = ({ age, onChange }) => {
  const { patient } = usePatientInfo()

  const minAgeSelf = ageToIndex(12)
  const minAgeOther = ageToIndex(0)
  const maxAge = ageToIndex(120)
  const minAge = patient === 'self' ? minAgeSelf : minAgeOther

  const text =
    patient === 'self'
      ? 'Angiv venligst din alder.'
      : 'Angiv venligst personens alder.'

  const [selectedAge, setSelectedAge] = useState(
    age || indexToAge(Math.floor((maxAge + minAge) / 2))
  )

  const handleChange = useCallback(
    (value) => {
      setSelectedAge(indexToAge(value))
    },
    [setSelectedAge]
  )

  const select = useCallback(() => {
    if (selectedAge !== null) {
      onChange(selectedAge)
    }
  }, [selectedAge, onChange])

  useKey(
    'ArrowLeft',
    () => {
      setSelectedAge(selectedAge - 1)
    },
    undefined,
    [selectedAge]
  )

  useKey(
    'ArrowRight',
    () => {
      setSelectedAge(selectedAge + 1)
    },
    undefined,
    [selectedAge]
  )

  useKey(
    'Enter',
    () => {
      select()
    },
    undefined,
    [select]
  )

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col md={8} mdOffset={2} lg={6} lgOffset={3}>
            <Section>
              <Text as="p" weight="bold">
                {text}
              </Text>
              <Age>
                {selectedAge > 0 ? (
                  <>
                    {selectedAge >= 1 ? selectedAge : selectedAge * 12}{' '}
                    {selectedAge >= 1
                      ? 'år'
                      : selectedAge === 1 / 12
                      ? 'måned'
                      : 'måneder'}
                  </>
                ) : (
                  'Nyfødt'
                )}
              </Age>
              <RangeSlider
                onChange={handleChange}
                min={minAge}
                max={maxAge}
                value={ageToIndex(selectedAge)}
              />
            </Section>
            <div>
              <Button
                onClick={select}
                disabled={selectedAge === null}
                color="primary"
              >
                Videre
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default AgeSelect

const indexToAge = (value: number) => {
  return value < 12 ? value / 12 : value - 11
}

const ageToIndex = (value: number) => {
  return value < 1 ? value * 12 : value + 11
}

const Wrapper = styled.div`
  height: calc(100vh - ${(props) => props.theme.navHeight}px);
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: ${(props) => props.theme.navHeight}px;
`

const Age = styled(Text).attrs({
  size: 'xl',
})`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
`

const Section = styled.div`
  margin-bottom: 40px;

  input {
    text-align: center;
    font-size: 28px;
    width: 300px;

    ${media.xs`
      width: 100%;
      font-size: 20px;
    `}
  }
`
