import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const useSiteMeta = () => {
  const { loading, data } = useQuery(gql`
    query SiteMeta {
      siteMeta {
        id
        aboutSlug
        notFoundSlug
        gtmId
        enableCoronaWarning
      }
    }
  `)

  return { loading, data: data ? data.siteMeta : null }
}

export default useSiteMeta
