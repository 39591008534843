import React from 'react'
import styled from 'styled-components'

const getDegrees = direction => {
  switch (direction) {
    case 'up':
      return 0
    case 'left':
      return -90
    case 'down':
      return 180
    default:
      return 90
  }
}

const Svg = styled.svg`
  width: 10px;
  height: auto;

  transform: rotate(${props => getDegrees(props.direction)}deg);
  transition: 500ms;

  path {
    fill: ${props => props.color || '#fff'};
    transition: 500ms;
  }
`

export default props => (
  <Svg xmlns="http://www.w3.org/2000/Svg" viewBox="0 0 256 256" {...props}>
    <path d="M128 48.907l-128 128 30.187 30.186L128 109.28l97.813 97.813L256 176.907z" />
  </Svg>
)
