import React, { Component } from 'react'

export default WrappedComponent =>
  class ComponentWithScrollBehavior extends Component {
    scrollToTop() {
      // wait for exit animation
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }

    componentDidMount() {
      this.scrollToTop()
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.match.url !== this.props.match.url) {
        this.scrollToTop()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
