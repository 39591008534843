import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import ButtonComponent from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'
import Explication from 'ui/components/generic/Explication'

const Button = styled(ButtonComponent)``

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  p {
    text-align: center;
    display: flex;
    align-items: center;
  }
`

const Question = styled(Text)`
  position: relative;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    flex-direction: ${(props) => (props.count > 3 ? 'column' : 'row')};
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 50px;
  `}
`

const Option = styled.div`
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0 !important;
  margin-bottom: 10px;

  ${media.md`
    margin-left: 10px !important;

    &:first-child {
      margin-left: 0 !important;
    }
  `}
`

class GroupSingleQuestion extends Component {
  static propTypes = {
    text: PropTypes.string,
    items: PropTypes.array,
    onAnswer: PropTypes.func,
    getChoiceId: PropTypes.func,
    explication: PropTypes.string,
  }

  render() {
    const {
      text,
      items,
      onAnswer,
      getChoiceId,
      explication,
      instruction,
    } = this.props

    const sortedItems = sortItems(items)

    return (
      <Wrapper>
        <PaddedContent>
          <Question mb={30} size="md" weight="bold">
            {text} {explication && <Explication text={explication} />}
            {instruction && (
              <Explication
                text={instruction.join('<br /><br />')}
                type="instructions"
              />
            )}
          </Question>
        </PaddedContent>
        <PaddedContent>
          <Options count={sortedItems.length}>
            {sortedItems.map((item) => (
              <Option key={item.id}>
                <Button
                  id={item.id}
                  onClick={() =>
                    onAnswer(
                      item,
                      'present',
                      items.filter((i) => i.id !== item.id).map((i) => i.id)
                    )
                  }
                  color={
                    getChoiceId(item) === 'present' ? 'secondary' : 'primary'
                  }
                  explication={
                    !['Ja', 'Nej'].includes(item.name) && item.explication
                  }
                >
                  {item.name}
                </Button>
              </Option>
            ))}
          </Options>
        </PaddedContent>
      </Wrapper>
    )
  }
}

export default GroupSingleQuestion

const sortItems = (items) => {
  // handle special cases

  // - Headache severity
  const mild = 's_1781'
  const moderate = 's_1780'
  const severe = 's_1193'
  if (
    items.length === 3 &&
    items.every((i) => [mild, moderate, severe].includes(i.id)) // check that every item is one of the three severities
  ) {
    return [
      items.find((i) => i.id === mild),
      items.find((i) => i.id === moderate),
      items.find((i) => i.id === severe),
    ]
  }

  return items
}
