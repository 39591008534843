import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  height: ${props => props.distance * 50}px;

  ${media.xs`
    height: ${props => props.distance * 20}px;
  `}
`
const Distance = ({ distance }) => <Wrapper distance={distance} />

Distance.propTypes = {
  distance: PropTypes.number
}

export const query = `
  distance
`

export default Distance
