import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import negations from 'utils/negations'

import prepareAgeQuery from 'utils/prepareAgeQuery'
import usePatientInfo from './usePatientInfo'
import useSymptoms from './useSymptoms'

const useSuggestedSymptoms = (riskFactors: boolean = false) => {
  const { gender, age } = usePatientInfo()

  const { symptoms } = useSymptoms()
  const result = useQuery(
    gql`
      query suggestedSymptoms {
        suggestedSymptoms(input: $input)
          @rest(type: "SuggestedSymptom", path: "suggest/", method: "POST") {
          id
          name
          common_name
        }
      }
    `,
    {
      variables: {
        input: {
          age: prepareAgeQuery(age),
          sex: gender,
          evidence: symptoms
            .filter((s) =>
              riskFactors
                ? s.source === 'initial' || s.source === 'suggest'
                : s.source === 'initial' || s.source === 'predefined'
            )
            .map((symptom) => ({
              id: symptom.id,
              choice_id: 'present',
              source: symptom.source,
            })),
          suggest_method: riskFactors ? 'risk_factors' : 'symptoms',
        },
      },
      skip: gender === null || age === null,
    }
  )

  const preparedData = prepare(
    result?.data?.suggestedSymptoms as SuggestedSymptom[] | undefined
  )

  return {
    data: preparedData,
    loading: result.loading,
  }
}

export default useSuggestedSymptoms

const prepare = (data?: SuggestedSymptom[]) => {
  if (!data) return data

  return data.map((s) => {
    if (negations[s.id]) {
      return {
        ...s,
        common_name: negations[s.id],
        negate: true,
      }
    }
    return s
  })
}

export type SuggestedSymptom = {
  id: string
  commonName: string
  name: string
}
