import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const useList = (
  type: 'conditions' | 'symptoms',
  letter: string,
  child = false
) => {
  const { data, loading } = useQuery(
    type === 'conditions' ? conditionQuery : symptomQuery,
    { variables: { letter, child }, fetchPolicy: 'network-only' }
  )

  return {
    data: !loading && data.list,
    loading,
  }
}

export default useList

const symptomQuery = gql`
  query symptomList($letter: String!, $child: Boolean!) {
    list(letter: $letter, child: $child)
      @rest(
        type: "SymptomListItem"
        path: "list/symptoms/{args.letter}?child={args.child}"
      ) {
      id
      name
      commonName
    }
  }
`

const conditionQuery = gql`
  query conditionList($letter: String!, $child: Boolean!) {
    list(letter: $letter, child: $child)
      @rest(
        type: "ConditionListItem"
        path: "list/conditions/{args.letter}?child={args.child}"
      ) {
      id
      name
      commonName
    }
  }
`
