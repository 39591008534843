import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithTerms extends Component {
    render() {
      return (
        <Query
          query={gql`
            {
              optionsTerms {
                header
                body
                consent
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  termsLoading={loading}
                  terms={!loading && !error && data.optionsTerms}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
