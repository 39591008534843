import React from 'react'
import PropTypes from 'prop-types'

const IconInfo = ({ color, ...rest }) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle
        stroke="#ABB8C4"
        strokeLinecap="round"
        strokeLinejoin="round"
        cx={7}
        cy={7}
        r={7}
      />
      <path
        d="M7.605 8.525v-.143c0-.22.068-.41.204-.572.135-.161.368-.352.698-.572.374-.257.658-.523.853-.798.194-.275.291-.65.291-1.127 0-.403-.106-.763-.319-1.078a2.097 2.097 0 0 0-.908-.737c-.392-.176-.856-.264-1.391-.264-.565 0-1.052.12-1.463.357-.41.239-.724.561-.94.968a2.826 2.826 0 0 0-.325 1.348h1.573c0-.403.106-.728.319-.973.213-.246.495-.369.847-.369.308 0 .554.082.737.247.183.165.275.38.275.644 0 .22-.059.414-.176.583-.117.169-.304.334-.561.495-.403.235-.699.47-.885.704-.188.235-.281.561-.281.979v.308h1.452Zm-.682 2.541a.902.902 0 0 0 .649-.253.846.846 0 0 0 .264-.638.837.837 0 0 0-.259-.633.908.908 0 0 0-.654-.247.908.908 0 0 0-.654.248.837.837 0 0 0-.259.632c0 .257.088.47.264.638a.902.902 0 0 0 .649.253Z"
        fill="#ABB8C4"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

IconInfo.propTypes = {
  color: PropTypes.string,
}

export default IconInfo
