export default (screenings, symptoms) => {
  const matches = []

  screenings.forEach(screening => {
    // find at least one matching rule
    const match = screening.rules.find(rule => {
      const ruleMatches = rule.symptoms.filter(symptom =>
        symptoms.find(
          s => s.id === symptom.symptom && s.choiceId === symptom.choiceId
        )
      )

      // Make sure all rules match
      return ruleMatches.length === rule.symptoms.length
    })
    if (match) {
      matches.push({
        screening,
        match
      })
    }
  })
  return matches
}
