import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled, { keyframes } from 'styled-components'

import { acceptTerms } from 'redux/modules/patientInfo'
import { hideOverlay } from 'redux/modules/overlay'
import responsiveSpacing from 'ui/styles/responsiveSpacing'
import withTerms from 'containers/withTerms'
import Text from 'ui/components/generic/Text'
import Loading from 'ui/components/generic/Loading'
import Button from 'ui/components/generic/Button'

class Terms extends Component {
  constructor(props) {
    super(props)

    this.state = { accepted: props.accepted }
  }

  handleCheckbox = e => {
    this.setState({ accepted: e.target.checked })
  }

  accept = () => {
    const { acceptTerms, hideOverlay } = this.props

    if (this.state.accepted) {
      acceptTerms()
      hideOverlay()
    }
  }

  render() {
    const { terms, termsLoading } = this.props
    const { accepted } = this.state

    return (
      <Wrapper>
        {termsLoading && <Loading />}
        {!termsLoading && terms && (
          <Content>
            <Container fluid>
              <Row>
                <Col
                  sm={10}
                  smOffset={1}
                  md={8}
                  mdOffset={2}
                  lg={6}
                  lgOffset={3}
                >
                  <Header dangerouslySetInnerHTML={{ __html: terms.header }} />
                  <Body dangerouslySetInnerHTML={{ __html: terms.body }} />
                  <Row>
                    <Col xs={2} sm={1}>
                      <Checkbox
                        onChange={this.handleCheckbox}
                        id="termsCheckbox"
                      />
                    </Col>
                    <Col xs={10} sm={11}>
                      <Consent
                        as="label"
                        htmlFor="termsCheckbox"
                        dangerouslySetInnerHTML={{ __html: terms.consent }}
                      />
                    </Col>
                  </Row>
                  <Action>
                    <Button
                      color="primary"
                      disabled={!accepted}
                      onClick={this.accept}
                    >
                      Acceptér
                    </Button>
                  </Action>
                </Col>
              </Row>
            </Container>
          </Content>
        )}
      </Wrapper>
    )
  }
}

Terms.propTypes = {
  terms: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      header: PropTypes.string,
      body: PropTypes.string
    })
  ]),
  termsLoading: PropTypes.bool,
  hideOverlay: PropTypes.func.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired
}

export default compose(
  withTerms(),
  connect(
    state => ({
      accepted: state.patientInfo.acceptedTerms
    }),
    {
      acceptTerms,
      hideOverlay
    }
  )
)(Terms)

const entry = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  padding-top: ${props => props.theme.navHeight}px;
`

const Content = styled.div`
  ${responsiveSpacing(0.5, 'padding-top')}
  ${responsiveSpacing(0.5, 'padding-bottom')}
`

const Header = styled(Text).attrs({ as: 'h1' })`
  animation: ${entry} 700ms both;
`
const Body = styled(Text).attrs({ size: 'xs' })`
  animation: ${entry} 700ms 100ms both;
  font-weight: ${props => props.theme.fontWeights.normal};
  ${responsiveSpacing(2, 'margin-bottom')}
`

const Consent = styled(Body)`
  display: block;
  font-weight: ${props => props.theme.fontWeights.bold};
  animation-delay: 300ms;
  cursor: pointer;
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  animation: ${entry} 700ms 200ms both;
  display: block;
  height: auto;
  margin-top: 2px;
`

const Action = styled.div`
  animation: ${entry} 700ms 400ms both;
`
