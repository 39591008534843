import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class HeadComponent extends Component {
  render() {
    const { seo, noIndex } = this.props
    if (!seo) return null

    let meta = [
      { name: 'description', content: seo.metaDescription },
      { property: 'og:site_name', content: seo.metaTitle },
      { property: 'og:image', content: seo.ogImage },
      { property: 'og:locale', content: seo.locale },
      { property: 'og:title', content: seo.ogTitle },
      { property: 'og:description', content: seo.ogDescription },
      { property: 'twitter:image', content: seo.twitterImage },
      { property: 'twitter:title', content: seo.twitterTitle },
      { property: 'twitter:description', content: seo.twitterDescription },
    ]

    if (noIndex) {
      meta = meta.concat({ name: 'robots', content: 'noindex' })
    }
    return <Helmet title={seo.metaTitle} meta={meta} />
  }
}
