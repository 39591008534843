import { useState, useRef, useCallback, useEffect, FC } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import theme from 'ui/styles/theme'
import IconChevronMedium from 'ui/components/generic/IconChevronMedium'

import getMatchingScreenings from 'utils/getMatchingScreenings'

import Text from 'ui/components/generic/Text'
import useSymptoms from 'containers/useSymptoms'
import useCustomScreenings, {
  CustomScreenings,
} from 'containers/useCustomScreenings'
import usePatientInfo from 'containers/usePatientInfo'

type ScreeningComponentProps = {
  screening: CustomScreenings['optionsCustomScreenings']['cancer']['screenings'][number]
}

const ScreeningComponent = ({ screening }: ScreeningComponentProps) => {
  const [expanded, setExpanded] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const updateHeight = useCallback(() => {
    if (contentRef.current) {
      const clientHeight = contentRef.current.clientHeight
      if (clientHeight !== contentHeight) {
        setContentHeight(clientHeight)
      }
    }
  }, [contentHeight])

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [updateHeight])

  return (
    <>
      <FoldableHeader onClick={() => setExpanded(!expanded)}>
        {screening.title}
        <ExpandIndicator>
          <Text size="sm" as="span" weight="medium">
            {expanded ? 'Luk' : 'Fold ud'}
          </Text>
          <IconChevronMedium
            direction={expanded ? 'up' : 'down'}
            color={theme.colors.primary}
          />
        </ExpandIndicator>
      </FoldableHeader>
      <Foldable height={expanded ? contentHeight : 0}>
        <Screening ref={contentRef}>
          <ScreeningHeader>
            <Row>
              <Col sm={3}>
                <img src={screening.image.sourceUrl} alt={screening.title} />
              </Col>
              <Col sm={9}>
                <h2>{screening.title}</h2>
                <Text
                  size="xs"
                  dangerouslySetInnerHTML={{ __html: screening.text }}
                  mb={20}
                />
                {screening.url && (
                  <Text size="xs">
                    <a href={screening.url} target="_blank" rel="noreferrer">
                      Læs mere om {screening.title}
                    </a>
                  </Text>
                )}
              </Col>
            </Row>
          </ScreeningHeader>
        </Screening>
      </Foldable>
    </>
  )
}

const Cancer: FC = () => {
  const { symptoms } = useSymptoms()
  const { data: customScreenings } = useCustomScreenings()
  const patientInfo = usePatientInfo()

  if (
    !customScreenings ||
    !customScreenings.optionsCustomScreenings ||
    !customScreenings.optionsCustomScreenings.cancer ||
    patientInfo.isChild
  )
    return null

  const matchingScreenings = getMatchingScreenings(
    customScreenings.optionsCustomScreenings.cancer.screenings,
    symptoms
  )

  if (matchingScreenings.length === 0) return null

  return (
    <Wrapper>
      <Header>
        <Text size="lg" weight="medium">
          De 7 tegn
        </Text>
        <MainLogo src="/images/de7tegn.png" alt="De 7 tegn" />
      </Header>
      {matchingScreenings.map(({ screening }) => (
        <ScreeningComponent key={screening.title} screening={screening} />
      ))}
      <Footer>
        <a href="https://www.cancer.dk/de7tegn/">Læs om De 7 tegn →</a>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="41"
          height="22"
          viewBox="0 0 41 22"
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#ED1C24">
              <path d="M30 0c-3.1 0-5.8 1.2-7.8 3.2l-1.6 1.6L19 3.2c-2-2-4.8-3.2-7.8-3.2C5.1 0 .2 4.9.2 11c0 6 4.9 11 11 11 3.1 0 5.8-1.3 7.8-3.3l1.6-1.5 1.5 1.5c2.1 2 4.8 3.3 7.9 3.3 6 0 11-4.9 11-11S36.1 0 30 0M16.9 16.6c-1.5 1.5-3.5 2.3-5.7 2.3-4.4 0-8-3.5-8-7.9s3.6-8 8-8c2.2 0 4.2.9 5.6 2.3l1.6 1.6-1.9 1.9L15 7.2c-1-.9-2.3-1.5-3.8-1.5-2.9 0-5.3 2.4-5.3 5.3s2.3 5.3 5.3 5.3c1.5 0 2.8-.6 3.8-1.6l1.6-1.5 1.9 1.9-1.6 1.5zm-4.1-4c-.4.4-.9.7-1.6.7-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2.6 0 1.2.2 1.6.6l1.6 1.6-1.6 1.5zm11.9.6l1.6 1.5c.9 1 2.3 1.6 3.8 1.6 2.9 0 5.2-2.3 5.2-5.3 0-2.9-2.3-5.3-5.2-5.3-1.5 0-2.8.6-3.8 1.5l-1.6 1.6-1.9-1.9 1.6-1.6C25.9 3.9 27.8 3 30 3c4.4 0 7.9 3.6 7.9 8s-3.5 7.9-7.9 7.9c-2.2 0-4.2-.8-5.7-2.3l-1.5-1.5 1.9-1.9zm-4.1-.3L18.7 11l1.9-1.9 1.9 1.9-1.9 1.9zm6.3-1.9l1.6-1.6c.4-.3.9-.6 1.6-.6 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-.6 0-1.2-.3-1.6-.7L26.9 11z"></path>
            </g>
          </g>
        </svg>
      </Footer>
    </Wrapper>
  )
}

export default Cancer

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  margin-bottom: 50px;
  border-radius: 25px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 40px;
`

const MainLogo = styled.img`
  width: 30px;
  height: auto;
`

const Footer = styled(Text).attrs({ size: 'xs' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ce2127;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  &,
  a,
  svg path {
    fill: #fff;
    color: #fff;
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`

const FoldableHeader = styled(Text).attrs({
  as: 'h2',
  size: 'md',
  weight: 'medium',
})`
  position: relative;
  margin-bottom: 0;
  padding: 40px;
  padding-right: 167px;
  border-top: 1px solid #f5f5f5;
  cursor: pointer;

  @media (max-width: 767px) {
    padding-right: 80px;
  }
`

const ExpandIndicator = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 40px;
  bottom: 0;

  svg {
    width: 9px;
  }

  span {
    display: block;
    margin-right: 20px;

    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Foldable = styled.div<{ height: number }>`
  overflow: hidden;
  transition: height 500ms;
  height: ${(props) => props.height}px;
`

const Screening = styled.div`
  border-top: 1px solid #f5f5f5;
  padding: 40px;
`

const ScreeningHeader = styled.div`
  img {
    max-width: 100%;
  }
`
