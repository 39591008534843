import useWindowSize from 'react-use/lib/useWindowSize'
import theme from '../ui/styles/theme'

type Breakpoint = keyof typeof theme.grid.breakpoints

const getBreakpointFromWidth = (width: number): Breakpoint => {
  const { breakpoints } = theme.grid
  const breakpointKeys = Object.keys(breakpoints) as Breakpoint[]

  let breakpoint = breakpointKeys
    // Sort from largest to smallest
    .sort((a, b) => (breakpoints[a] < breakpoints[b] ? 1 : -1))

    // Find first matching breakpoint
    .find((key) => width >= breakpoints[key])

  // If no match, we're at the smallest breakpoint, which should be the last in the list - use that
  if (!breakpoint) {
    breakpoint = breakpointKeys[breakpointKeys.length - 1]
  }
  return breakpoint
}

const useDimensions = () => {
  const { width, height } = useWindowSize(1200, 800)

  return {
    breakpoint: getBreakpointFromWidth(width),
    width,
    height,
  }
}

export default useDimensions
