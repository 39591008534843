import { connect } from 'react-redux'
import { showOverlay, hideOverlay } from 'redux/modules/overlay'

export default connect(
  state => ({
    overlayVisible: state.overlay.visible,
    overlayContent: state.overlay.content,
    overlayData: state.overlay.data
  }),
  { showOverlay, hideOverlay }
)
