import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'unfetch/polyfill'
import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import { store } from './redux/store'
import getClient from './apiClient/client'
import App from './App'

Bugsnag.start({
  apiKey: 'c9d6f7c37e48766391a1e3f065bc814f',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
  collectUserIp: false,
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const wrap = (AppComponent: typeof App) => (
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={getClient()}>
        <Provider store={store}>
          <BrowserRouter>
            <AppComponent />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

const rootElement = document.getElementById('root')!
if (rootElement.hasChildNodes()) {
  hydrate(wrap(App), rootElement)
} else {
  render(wrap(App), rootElement)
}

// Hot reload if possible
if ((module as any).hot) {
  ;(module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(wrap(NextApp), rootElement)
  })
}
