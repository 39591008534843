import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uniqid } from 'uuid'

export type State = {
  age: number | null
  gender: 'male' | 'female' | null
  acceptedTerms: boolean
  interviewId: string
  ignoreEmergency: boolean
  patient: 'self' | 'other' | null
}

const initialState: State = {
  age: null,
  gender: null,
  acceptedTerms: false,
  interviewId: uniqid(),
  ignoreEmergency: false,
  patient: null,
}

export const patientInfo = createSlice({
  name: 'patientInfo',
  initialState,
  reducers: {
    setAge: (state, action: PayloadAction<State['age']>) => {
      state.age = action.payload
    },
    setGender: (state, action: PayloadAction<State['gender']>) => {
      state.gender = action.payload
    },
    setPatient: (state, action: PayloadAction<State['patient']>) => {
      state.patient = action.payload
    },
    acceptTerms: (state) => {
      state.acceptedTerms = true
    },
    ignoreEmergency: (state) => {
      state.ignoreEmergency = true
    },
    reset: (state) => ({
      ...initialState,
      acceptedTerms: state.acceptedTerms,
      interviewId: uniqid(),
    }),
  },
})

export const {
  setAge,
  setGender,
  setPatient,
  acceptTerms,
  ignoreEmergency,
  reset,
} = patientInfo.actions

export default patientInfo.reducer
