export default {
  bodyFont: '"DM Sans", sans-serif',
  displayFont: '"DM Sans", Helvetica, serif',
  letterSpacing: -0.02,
  fontSizes: {
    xxxl: {
      xs: {
        fs: 30,
        lh: 35,
      },
      sm: {
        fs: 46,
        lh: 50,
      },
      md: {
        fs: 50,
        lh: 55,
      },
      lg: {
        fs: 50,
        lh: 55,
      },
      xl: {
        fs: 80,
        lh: 104,
      },
    },
    xxl: {
      xs: {
        fs: 30,
        lh: 35,
      },
      sm: {
        fs: 46,
        lh: 50,
      },
      md: {
        fs: 50,
        lh: 55,
      },
      lg: {
        fs: 50,
        lh: 55,
      },
      xl: {
        fs: 50,
        lh: 55,
      },
    },
    xl: {
      xs: {
        fs: 24,
        lh: 30,
      },
      sm: {
        fs: 30,
        lh: 35,
      },
      md: {
        fs: 35,
        lh: 45,
      },
      lg: {
        fs: 35,
        lh: 45,
      },
      xl: {
        fs: 35,
        lh: 45,
      },
    },
    lg: {
      xs: {
        fs: 20,
        lh: 22,
      },
      sm: {
        fs: 22,
        lh: 26,
      },
      md: {
        fs: 22,
        lh: 26,
      },
      lg: {
        fs: 28,
        lh: 35,
      },
      xl: {
        fs: 28,
        lh: 35,
      },
    },
    md: {
      xs: {
        fs: 18,
        lh: 24,
      },
      sm: {
        fs: 18,
        lh: 24,
      },
      md: {
        fs: 22,
        lh: 28,
      },
      lg: {
        fs: 22,
        lh: 28,
      },
      xl: {
        fs: 22,
        lh: 28,
      },
    },
    sm: {
      xs: {
        fs: 16,
        lh: 22,
      },
      sm: {
        fs: 16,
        lh: 22,
      },
      md: {
        fs: 18,
        lh: 24,
      },
      lg: {
        fs: 18,
        lh: 24,
      },
      xl: {
        fs: 18,
        lh: 24,
      },
    },
    xs: {
      xs: {
        fs: 12,
        lh: 16,
      },
      sm: {
        fs: 12,
        lh: 16,
      },
      md: {
        fs: 14,
        lh: 18,
      },
      lg: {
        fs: 16,
        lh: 22,
      },
      xl: {
        fs: 16,
        lh: 22,
      },
    },
    xxs: {
      xs: {
        fs: 12,
        lh: 16,
      },
      sm: {
        fs: 12,
        lh: 16,
      },
      md: {
        fs: 12,
        lh: 16,
      },
      lg: {
        fs: 12,
        lh: 16,
      },
      xl: {
        fs: 12,
        lh: 16,
      },
    },
  },
  fontWeights: {
    thin: 100,
    light: 200,
    normal: 400,
    medium: 500,
    bold: 700,
    black: 700,
  },
  colors: {
    tones: {
      lightest: '#F5F5F5',
      lighter: '#E1E1E1',
      light: '#969696',
      medium: '#797878',
      dark: '#373737',
      darker: '#323232',
    },
    primary: '#CB1B2B',
    primaryDark: '#A50005',
    primaryDarker: '#7A2929',
    secondary: '#37B7A0',
    severity: {
      mild: '#448444',
      moderate: '#E0BF4A',
      severe: '#7A2929',
    },
    danger: '#7A2929',
    triage: {
      self_care: '#448444',
      consultation: '#448444',
      consultation_24: '#E0BF4A',
      emergency: '#7A2929',
      emergency_ambulance: '#7A2929',
    },
    sst: 'rgb(11,92, 139)',
  },
  spacing: {
    xs: 10,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 20,
  },
  navHeight: 80,
  navHeightXs: 100,
  grid: {
    breakpoints: {
      // defaults below
      xl: 1600,
      lg: 1200,
      md: 768,
      sm: 576,
      xs: 575,
    },
    row: {
      padding: 10, // default 15
    },
    col: {
      padding: 10, // default 15
    },
    container: {
      padding: 30, // default 15
      paddingXs: 20,
    },
  },
}
