import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

// reducers
import overlay, { State as OverlayState } from './modules/overlay'
import drawer, { State as DrawerState } from './modules/drawer'
import patientInfo, { State as PatientInfoState } from './modules/patientInfo'
import symptoms, { State as SymptomsState } from './modules/symptoms'

// middleware
import localStorage, { loadPersistentState } from './middleware/localStorage'

export const createStore = () => {
  return configureStore({
    reducer: {
      overlay,
      drawer,
      patientInfo,
      symptoms,
    },
    middleware: getDefaultMiddleware().concat(localStorage),
    preloadedState: process.env.IS_SERVER ? {} : loadPersistentState(),
  })
}

export const store = createStore()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
  overlay: OverlayState
  drawer: DrawerState
  patientInfo: PatientInfoState
  symptoms: SymptomsState
}
export type AppDispatch = typeof store.dispatch
