import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import prepareAgeQuery from 'utils/prepareAgeQuery'
import usePatientInfo from './usePatientInfo'

export const useCondition = (id: string) => {
  const { age } = usePatientInfo()
  const { loading, error, data } = useQuery<ConditionResponse>(
    gql`
      query condition($condition: String!, $age: String!) {
        condition(condition: $condition, age: $age)
          @rest(
            type: "Condition"
            path: "conditions/{args.condition}?age.value={args.age.value}&age.unit={args.age.unit}"
            method: "GET"
          ) {
          name
          common_name
          categories
          prevalence
          acuteness
          severity
          extras @type(name: "ConditionExtras") {
            hint
            icd10_code
          }
          triage_level
          link
          source
        }
      }
    `,
    {
      variables: {
        condition: id,
        age: prepareAgeQuery(age),
      },
      ssr: false,
    }
  )

  return {
    loading,
    data: data?.condition,
    error,
  }
}

type ConditionResponse = {
  condition: {
    name: string
    common_name: string
    categories: string[]
    prevalence: string
    acuteness: string
    severity: string
    extras: {
      hint: string
      icd10_code: string
    }
    triage_level: string
    link: string
    source: string
  }
}
