import { FC, useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Typed from 'react-typed'
import posed from 'react-pose'
import { useKey, useDebounce } from 'react-use'
import { useSelector, useDispatch } from 'react-redux'

import usePatientInfo from 'containers/usePatientInfo'
import { showOverlay } from 'redux/modules/overlay'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import IconSearch from 'ui/components/generic/IconSearch'
import { SlideInOutGroup, transition } from 'ui/styles/poses'
import SearchResults from './SearchResults'
import { RootState } from 'redux/store'
import responsiveText from 'ui/styles/responsiveText'

type Props = {
  symptoms: any
  autoSearch: boolean
  onSearch: Function
}

const SearchInput: FC<Props> = ({ symptoms, autoSearch, onSearch }) => {
  const { acceptedTerms } = usePatientInfo()

  const [searchFocused, setSearchFocused] = useState(false)
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')

  const wrapper = useRef<HTMLDivElement>(null)
  const searchInput = useRef<HTMLInputElement>(null)
  const typed = useRef<any>(null)

  const overlayVisible = useSelector(
    (state: RootState) => state.overlay.visible
  )
  const dispatch = useDispatch()

  const showAssistant =
    (!searchFocused && !value && !search && symptoms.length === 0) ||
    !acceptedTerms

  useEffect(() => {
    setSearch('')
    setValue('')
  }, [symptoms.length, setSearch, setValue])

  useEffect(() => {
    onSearch(search)
  }, [search, onSearch])

  useKey(
    (e) => !!e.key.match(/^[a-z0-9]$/i),
    () => {
      if (
        document.activeElement !== searchInput.current &&
        !overlayVisible &&
        searchInput.current
      ) {
        searchInput.current.focus()
      }
    }
  )

  const handleReturnKey = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        setSearch(value)
        searchInput.current?.blur()
      }
    },
    [setSearch, value, searchInput]
  )

  const handleInputChange = useCallback(
    (e) => {
      if (acceptedTerms) {
        setValue(e.target.value)
      }
    },
    [acceptedTerms]
  )

  useDebounce(
    () => {
      if (autoSearch) {
        setSearch(value)
      }
    },
    500,
    [value]
  )

  const handleBlur = useCallback(() => {
    setSearchFocused(false)
    typed.current.start()
  }, [])

  const handleFocus = useCallback(() => {
    setSearchFocused(true)
    typed.current.stop()
    typed.current.reset()
  }, [setSearchFocused, typed])

  const showTerms = useCallback(() => {
    dispatch(showOverlay('terms'))
  }, [dispatch])

  return (
    <Search ref={wrapper}>
      <InputWrapper>
        <Assistant visible={showAssistant}>
          <Typed
            strings={[
              '^2500Hovedpine',
              '^500Vægttab',
              '^500Ondt i halsen',
              '^500Svimmelhed',
              '^500Hjertebanken',
              '^500Udslæt',
            ]}
            typeSpeed={30}
            backSpeed={40}
            backDelay={1500}
            smartBackspace
            showCursor
            loop
            typedRef={(t: any) => (typed.current = t)}
          >
            <span />
          </Typed>
        </Assistant>
        <Input
          onChange={handleInputChange}
          onKeyPress={handleReturnKey}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={showAssistant ? undefined : 'Skriv symptom..'}
          ref={searchInput}
        />
        {!autoSearch && (
          <SearchButton color="primary" round onClick={() => setSearch(value)}>
            <IconSearch />
          </SearchButton>
        )}
        {!acceptedTerms && <InputMask onClick={showTerms} />}
      </InputWrapper>
      <SlideInOutGroup withParent={false}>
        {search && search.length > 2 && (
          <ResultsPose key="searchResults">
            <SearchResults search={search} />
          </ResultsPose>
        )}
      </SlideInOutGroup>
    </Search>
  )
}

export default SearchInput

const ResultsPose = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition,
  },
  exit: {
    opacity: 0,
    y: '-50px',
    transition,
  },
  pre: {
    opacity: 0,
    y: '-50px',
    transition,
  },
  flip: transition,
})

const Search = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 40px;
  width: 450px;
  max-width: 100%;
`

const Input = styled.input`
  ${responsiveText('md')}
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 20px;
  background-color: transparent;
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 0;
  font-weight: 100;
  position: relative;
  line-height: 1;

  ${media.xs`
    text-align: left;
    padding: 20px 40px 20px 0px;
  `}
`

const InputWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.tones.lightest};
  z-index: 2;
  text-align: center;

  ${media.xs`
    text-align: left;
  `}
`

const SearchButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
`

const InputMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: text;
`

const Assistant = styled(Text).attrs({
  size: 'md',
})<{ visible: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;
  pointer-events: none;
  line-height: 1;

  opacity: ${(props) => (props.visible ? 1 : 0)};

  ${media.xs`
    padding: 20px 40px 20px 0px;

    span {
      padding: 0;
    }
  `}
`
