import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { reset } from '../patientInfo'

const initialState: State = {
  symptoms: [],
  answered: null,
  questions: [],
}

export const parseSymptom = (symptom: InputSymptom, source?: string) => {
  // different endpoints have different ways of defining symptoms 🙄
  let name = symptom.name || ''
  if (
    symptom.__typename === 'SearchedSymptom' &&
    (symptom.commonName || symptom.name || symptom.label)
  ) {
    name = symptom.commonName || symptom.name || symptom.label || ''
  } else if (symptom.__typename === 'SuggestedSymptom' && symptom.common_name) {
    name = symptom.common_name
  }

  return {
    name,
    id: symptom.id,
    source,
  }
}

const updateSymptomsReducer = (
  state: State,
  action: PayloadAction<{ set?: SymptomUpdate[]; remove?: string[] }>
) => {
  const { set, remove } = action.payload

  let symptoms = state.symptoms.concat()

  if (set) {
    // update all specified symptoms
    set.forEach((s: SymptomUpdate) => {
      const { symptom, choiceId, source } = s

      // create new symptom object
      const newSymptom = {
        ...parseSymptom(symptom),
        choiceId,
        source,
      }

      // update or add
      const index = symptoms.findIndex(
        (existingSymptom) => existingSymptom.id === symptom.id
      )
      if (index >= 0) {
        symptoms[index] = newSymptom
      } else {
        symptoms.push(newSymptom)
      }
    })
  }

  if (remove) {
    // remove symptoms
    symptoms = symptoms.filter((s) => !remove.includes(s.id))
  }

  return {
    ...state,
    symptoms,
  }
}

const symptoms = createSlice({
  name: 'symptoms',
  initialState,
  reducers: {
    updateSymptoms: {
      reducer: updateSymptomsReducer,
      prepare: (
        set: {
          symptom: InputSymptom
          choiceId: ChoiceId
          source: Source
        }[],
        remove?: string[]
      ) => ({
        payload: { set, remove },
      }),
    },
    updateSymptom: {
      reducer: updateSymptomsReducer,
      prepare: (
        symptom: InputSymptom,
        choiceId?: ChoiceId,
        source?: Source,
        remove?: string[]
      ) => ({
        payload: {
          set: [
            {
              symptom,
              choiceId,
              source,
            },
          ],
          remove,
        },
      }),
    },
    removeSymptom: (state, action: PayloadAction<string>) => {
      state.symptoms = state.symptoms.filter((s) => s.id !== action.payload)
    },
    addQuestion: (state, action: PayloadAction<Question>) => {
      state.questions = state.questions.concat(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, (_state) => {
      return { ...initialState }
    })
  },
})

export const {
  updateSymptom,
  updateSymptoms,
  removeSymptom,
  addQuestion,
} = symptoms.actions

export default symptoms.reducer

export type ChoiceId = 'present' | 'absent' | 'unknown'
export type Source = 'initial' | 'suggest' | 'predefined' | undefined

export type Symptom = {
  name: string
  id: string
  source?: Source
  choiceId?: ChoiceId
}

export type Question = any

export type InputSymptom = {
  id: string
  name?: string
  __typename?: string
  commonName?: string
  common_name?: string
  label?: string
}

export type SymptomUpdate = {
  symptom: InputSymptom
  choiceId?: ChoiceId
  source?: Source
}

export type State = {
  symptoms: Symptom[]
  answered: string | null
  questions: Question[]
}
