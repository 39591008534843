import scrollTo from 'animated-scroll-to'

export const scrollToModule = index =>
  scrollToElement(`#module-${index}`, document.querySelector(`#overlay`))

export const scrollToElement = (selector, container) => {
  const element =
    typeof selector === 'string' ? document.querySelector(selector) : selector

  if (!element) return

  const elementTop = element.getBoundingClientRect().top
  const containerTop = container ? container.getBoundingClientRect().top : 0
  scrollTo(elementTop + containerTop, {
    elementToScroll: container,
    speed: 1000
  })
}

export default scrollToElement
