import { FC } from 'react'

const IconClose: FC<{ color?: string }> = ({ color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={12}
      fill="none"
      {...rest}
    >
      <path
        stroke={color || '#fff'}
        d="m11.686.354-11 11M11.686.354l-11 11M11.686.354l-11 11M11.686.354l-11 11M11.686.354l-11 11M.354.479l11 11"
      />
    </svg>
  )
}

export default IconClose
