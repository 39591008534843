import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

const usePatientInfo = () =>
  useSelector((state: RootState) => ({
    ...state.patientInfo,
    isChild: state.patientInfo.age! < 18,
  }))

export default usePatientInfo
