import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

import { query as HeroQuery } from 'ui/components/modules/Hero'
import { query as HeaderQuery } from 'ui/components/modules/Header'
import { query as BodyTextQuery } from 'ui/components/modules/BodyText'
import { query as LargeTextQuery } from 'ui/components/modules/LargeText'
import { query as ImageAndTextQuery } from 'ui/components/modules/ImageAndText'
import { query as ProcessQuery } from 'ui/components/modules/Process'
import { query as DistanceQuery } from 'ui/components/modules/Distance'

const parseData = (data) => ({
  ...data,
  seo: JSON.parse(data.seo),
})

export default (
  getSlugFromProps,
  getPostType,
  renderNotFound = () => <h1>Not found</h1>
) => (WrappedComponent) =>
  class WithPost extends Component {
    render() {
      const slug = getSlugFromProps(this.props)
      const endpoint = getPostType(this.props)

      return (
        <Query
          query={gql`
            {
              ${endpoint}By(uri: "${slug}"){
                id
                title
                seo
                slug
                modules {
                  ...on Modules__hero {
                    layout
                    ${HeroQuery}
                  }
                  ...on Modules__header {
                    layout
                    ${HeaderQuery}
                  }
                  ...on Modules__bodyText {
                    layout
                    ${BodyTextQuery}
                  }
                  ...on Modules__largeText {
                    layout
                    ${LargeTextQuery}
                  }
                  ...on Modules__imageAndText {
                    layout
                    ${ImageAndTextQuery}
                  }
                  ...on Modules__process {
                    layout
                    ${ProcessQuery}
                  }
                  ...on Modules__distance {
                    layout
                    ${DistanceQuery}
                  }
                }
              }
            }`}
        >
          {({ loading, error, data }) => (
            <div>
              {!error && data && (data[`${endpoint}By`] || loading) && (
                <WrappedComponent
                  {...this.props}
                  loading={loading}
                  post={
                    data[`${endpoint}By`] && parseData(data[`${endpoint}By`])
                  }
                />
              )}
              {(error || (!loading && data && !data[`${endpoint}By`])) &&
                renderNotFound()}
            </div>
          )}
        </Query>
      )
    }
  }
