import React, { FC, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import useSymptoms from 'containers/useSymptoms'
import useSuggestedSymptoms, {
  SuggestedSymptom,
} from 'containers/useSuggestedSymptoms'

import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'
import Spinner from 'ui/components/generic/Spinner'
import GroupMultipleQuestion from 'ui/components/views/GroupMultipleQuestion'
import usePatientInfo from 'containers/usePatientInfo'

const SuggestedSymptoms: FC<{ onContinue: Function }> = ({ onContinue }) => {
  const { data: suggestedSymptoms, loading } = useSuggestedSymptoms()
  const {
    symptoms,
    actions: { updateSymptoms },
  } = useSymptoms()

  const { patient, isChild } = usePatientInfo()

  const handleRedirect = useCallback(() => {
    if (suggestedSymptoms?.length === 0) {
      onContinue()
    }
  }, [suggestedSymptoms?.length, onContinue])

  const answer = useCallback(
    (selectedSymptoms) => {
      updateSymptoms(
        selectedSymptoms.map((s: SuggestedSymptom) => ({
          ...s,
          source: 'suggest',
        }))
      )
      onContinue()
    },
    [onContinue, updateSymptoms]
  )

  const getChoiceId = useCallback(
    (symptom) => {
      const matchingSymptom = symptoms.find((s) => s.id === symptom.id)
      if (matchingSymptom) {
        return matchingSymptom.choiceId
      }
      return null
    },
    [symptoms]
  )

  useEffect(() => {
    if (!loading) {
      handleRedirect()
    }
  }, [loading, handleRedirect])

  return (
    <Wrapper>
      <SlideInOutGroup>
        {loading && (
          <Content key="loading">
            <Spinner />
          </Content>
        )}
        {!loading && (
          <Content key="question">
            <GroupMultipleQuestion
              text={
                patient === 'self'
                  ? 'Oplever du nogle af følgende symptomer?'
                  : `Opleves nogle af følgende symptomer hos ${
                      isChild ? 'barnet' : 'personen'
                    }?`
              }
              items={suggestedSymptoms}
              onAnswer={answer}
              getChoiceId={getChoiceId}
            />
          </Content>
        )}
      </SlideInOutGroup>
    </Wrapper>
  )
}

export default SuggestedSymptoms

const Wrapper = styled.div`
  height: 100vh;
  padding: ${(props) => props.theme.navHeight}px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(SlideInOut)`
  width: 100%;
  margin: auto;
  text-align: center;
`
