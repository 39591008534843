import { Middleware } from 'redux'

import { RootState } from '../store'
import { initialState as overlayInitialState } from '../modules/overlay'
import { initialState as drawerInitialState } from '../modules/drawer'

const localStorage: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> = (store) => (next) => (action) => {
  const result = next(action)
  sessionStorage.setItem(
    'userInfo',
    JSON.stringify(getPersistentState(store.getState()))
  )
  return result
}

export default localStorage

export const loadPersistentState = () => {
  let userInfo = null
  try {
    userInfo = sessionStorage.getItem('userInfo')
  } catch (error) {
    console.error('Could not load state from session storage')
  }

  return userInfo ? JSON.parse(userInfo) : {}
}

export const getPersistentState = (state: RootState): RootState => ({
  patientInfo: state.patientInfo,
  symptoms: state.symptoms,
  drawer: { ...drawerInitialState },
  overlay: { ...overlayInitialState },
})
