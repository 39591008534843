import React, { FC } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { motion, AnimatePresence, MotionProps } from 'framer-motion'
import useDrawer, { ContentType } from 'containers/useDrawer'
import ConditionDetails from './routes/Interview/Results/ConditionDetails'

const Drawer: FC = () => {
  const {
    content,
    data,
    visible,
    actions: { hideDrawer },
  } = useDrawer()

  return (
    <React.Fragment>
      <AnimatePresence initial={false}>
        {visible && (
          <Backdrop
            onClick={() => {
              hideDrawer()
            }}
            {...backdropStates}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {visible && (
          <Wrapper {...drawerStates}>{getComponent(content, data)}</Wrapper>
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

const getComponent = (content: ContentType, data: any) => {
  switch (content) {
    case 'conditionDetails':
      return <ConditionDetails {...data} />
    default:
      return null
  }
}

const backdropStates: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    type: 'tween',
    duration: 0.4,
  },
}

const drawerStates: MotionProps = {
  initial: {
    x: 710,
  },
  animate: {
    x: 0,
  },
  exit: {
    x: 710,
  },
  transition: {
    type: 'spring',
    bounce: 0,
    damping: 20,
    stiffness: 100,
  },
}

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  background-color: white;
  z-index: 25;
  overflow: auto;

  ${media.md`
    width: 710px;
  `}
`

const Backdrop = styled(motion.div)`
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 24;
  cursor: pointer;
`

export default Drawer
