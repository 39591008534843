import React, { Component } from 'react'
import styled from 'styled-components'

import Module from 'ui/components/Module'
import Hero from 'ui/components/modules/Hero'
import Header from 'ui/components/modules/Header'
import BodyText from 'ui/components/modules/BodyText'
import LargeText from 'ui/components/modules/LargeText'
import ImageAndText from 'ui/components/modules/ImageAndText'
import Image from 'ui/components/modules/Image'
import Embed from 'ui/components/modules/Embed'
import Process from 'ui/components/modules/Process'
import Distance from 'ui/components/modules/Distance'

const Wrapper = styled.div``

const Missing = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.colors.tones.light};
  color: ${props => props.theme.colors.tones.dark};
  font-size: 18px;
`

export default class Modules extends Component {
  getModule(module) {
    const { post, type } = this.props
    switch (module.layout) {
      case 'hero':
        return <Hero {...module} post={post} type={type} />
      case 'header':
        return <Header {...module} post={post} type={type} />
      case 'bodyText':
        return <BodyText {...module} />
      case 'largeText':
        return <LargeText {...module} />
      case 'imageAndText':
        return <ImageAndText {...module} />
      case 'image':
        return <Image {...module} />
      case 'embed':
        return <Embed {...module} />
      case 'process':
        return <Process {...module} />
      case 'distance':
        return <Distance {...module} />
      default:
        console.log(module)
        return <Missing>Missing implementation of {module.layout}</Missing>
    }
  }

  render() {
    const { modules } = this.props
    return (
      <Wrapper>
        {modules.map((module, index) => (
          <Module
            key={index}
            id={`module-${index}`}
            index={index}
            type={module.layout}
            nextType={modules[index + 1] && modules[index + 1].layout}
          >
            {this.getModule(module)}
          </Module>
        ))}
      </Wrapper>
    )
  }
}
