import { FC } from 'react'

const IconView: FC<{ color?: string }> = ({ color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={15}
      fill="none"
      viewBox="0 0 22 15"
      {...rest}
    >
      <path
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2.587 9.44c1.78 1.899 4.883 4.532 8.413 4.532 3.53 0 6.634-2.633 8.413-4.533.47-.5.705-.752.854-1.244.107-.351.107-.98 0-1.332-.15-.492-.385-.743-.854-1.244-1.78-1.9-4.882-4.533-8.413-4.533-3.53 0-6.634 2.633-8.413 4.533-.47.501-.705.752-.854 1.244-.107.351-.107.981 0 1.332.15.492.384.743.854 1.244Z"
      />
      <path
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 7.53c0 1.185.895 2.147 2 2.147s2-.962 2-2.148c0-1.186-.895-2.148-2-2.148S9 6.343 9 7.53Z"
      />
    </svg>
  )
}

export default IconView
