import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DrawerKey = 'conditionDetails'

export type State = {
  visible: boolean
  content: DrawerKey | null
  data: any
}

export const initialState = {
  visible: false,
  content: null,
  data: null,
} as State

const drawer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    showDrawer: {
      prepare: (key: DrawerKey, data?: any) => ({
        payload: {
          key,
          data,
        },
      }),
      reducer: (
        state,
        action: PayloadAction<{ key: DrawerKey; data: any }>
      ) => {
        return {
          ...state,
          visible: true,
          content: action.payload.key || state.content,
          data: action.payload.data || state.data,
        }
      },
    },
    hideDrawer: (state, _action: AnyAction) => ({
      ...state,
      visible: false,
    }),
  },
})

export const { showDrawer, hideDrawer } = drawer.actions

export default drawer.reducer
