import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default () => WrappedComponent =>
  class WithSiteMeta extends Component {
    render() {
      return (
        <Query
          query={gql`
            query SiteMeta {
              siteMeta {
                id
                aboutSlug
                notFoundSlug
                gtmId
                enableCoronaWarning
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (!error) {
              return (
                <WrappedComponent
                  {...this.props}
                  siteMetaLoading={loading}
                  siteMeta={!loading && !error && data.siteMeta}
                />
              )
            }
            return <h1>Error: {error.message}</h1>
          }}
        </Query>
      )
    }
  }
