import React, { Component } from 'react'

import getRelativeUrl from 'utils/getRelativeUrl'
import Button from 'ui/components/generic/Button'

class Links extends Component {
  render() {
    const { links, color } = this.props

    return (
      <div>
        {links.map((button, index) => (
          <Button
            component={button.type === 'external' ? 'a' : undefined}
            to={
              button.type === 'internal'
                ? getRelativeUrl(button.internalLink.link)
                : undefined
            }
            href={button.type === 'external' ? button.externalLink : undefined}
            color={color}
            hollow={button.layout === 'secondary' ? true : undefined}
          >
            {button.text}
          </Button>
        ))}
      </div>
    )
  }
}

export default Links
