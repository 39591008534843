import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import scrollOnMount from 'utils/scrollOnMount'
import withPost from 'containers/withPost'
import withSiteMeta from 'containers/withSiteMeta'
import Modules from 'ui/components/Modules'
import Seo from 'ui/components/Seo'

const Wrapper = styled.div`
  margin-top: ${props => props.theme.navHeight}px;
  background-color: ${props => props.theme.colors.tones.lightest};
  min-height: calc(100vh - ${props => props.theme.navHeight}px);
`

class NotFound extends Component {
  render() {
    const { post, loading, type, staticContext } = this.props

    if (staticContext) {
      staticContext.statusCode = 404
    }

    return (
      <Wrapper>
        {!loading && <Seo seo={post.seo} noIndex />}

        {!loading && post.modules && (
          <Modules modules={post.modules} post={post} type={type} />
        )}
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  scrollOnMount,
  withSiteMeta(),
  withPost(
    props => props.siteMeta.notFoundSlug,
    () => 'page',
    () => (
      <h1
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          textAlign: 'center',
          marginBottom: '0px'
        }}
      >
        Neither the content you are looking for or the 404-page could be found.{' '}
        <br /> What a mess..
      </h1>
    )
  )
)(NotFound)
