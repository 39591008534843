import {
  useCallback,
  useEffect,
  useState,
} from 'react'

import { reverse } from 'named-urls'
import {
  useHistory,
  useParams,
} from 'react-router-dom'
import {
  ChoiceId,
  InputSymptom,
  Source,
} from 'redux/modules/symptoms'

import routes from '../routes'
import useDiagnosis from './useDiagnosis'
import useOverlay from './useOverlay'
import usePatientInfo from './usePatientInfo'
import useSymptoms from './useSymptoms'

let loadTimeout: any
let answerTimeout: any

const useInterviewLogic = (onContinue: Function) => {
  const { data: diagnosis, loading: diagnosisLoading } = useDiagnosis()
  const { ignoreEmergency } = usePatientInfo()
  const [loadDebounce, setLoadDebounce] = useState(false)
  const {
    visible: overlayVisible,
    actions: { showOverlay },
  } = useOverlay()

  const params = useParams<{ questionIndex: string }>()
  const { push } = useHistory()

  const {
    questions,
    symptoms,
    actions: { addQuestion, updateSymptom, updateSymptoms },
  } = useSymptoms()

  const questionIndex = params.questionIndex
    ? parseInt(params.questionIndex, 10)
    : 0

  const question = questions[questionIndex]
  const shouldStop = !question && diagnosis && diagnosis.should_stop
  const loading = diagnosisLoading || loadDebounce

  const setLoading = useCallback(() => {
    setLoadDebounce(true)
    if (loadTimeout) {
      clearTimeout(loadTimeout)
    }
    loadTimeout = setTimeout(() => {
      setLoadDebounce(false)
    }, 1000)
  }, [setLoadDebounce])

  const handleNewQuestion = useCallback(() => {
    // If we shouldn't stop
    if (diagnosis && !diagnosis.should_stop) {
      // And if we haven't already added this question
      if (
        !questions.find(
          (q) => JSON.stringify(q) === JSON.stringify(diagnosis.question)
        )
      ) {
        addQuestion(diagnosis.question)
      }

      // check for early triage
      if (
        diagnosis.has_emergency_evidence &&
        !ignoreEmergency &&
        !overlayVisible
      ) {
        showOverlay('earlyTriage')
      }
    }
  }, [
    addQuestion,
    diagnosis,
    ignoreEmergency,
    overlayVisible,
    questions,
    showOverlay,
  ])

  // Navigate to next question
  onContinue = useCallback(() => {
    const nextIndex = questionIndex + 1

    push(reverse(routes.interview.interview, { questionIndex: nextIndex }))
  }, [questionIndex, push])

  // Get selected choice id if any for the given symptom
  const getChoiceId = useCallback(
    (symptom) => {
      const answer = symptoms.find((s) => s.id === symptom.id)
      if (answer) {
        return answer.choiceId
      }
      return null
    },
    [symptoms]
  )

  // Handle questions when done loading
  useEffect(() => {
    if (!diagnosisLoading) {
      handleNewQuestion()
    }
  }, [diagnosisLoading, handleNewQuestion])

  // Clear timeouts when component unmounts
  useEffect(
    () => () => {
      clearTimeout(answerTimeout)
      clearTimeout(loadTimeout)
    },
    []
  )

  return {
    question,
    questionIndex,
    shouldStop,
    loading,
    getChoiceId,
    onAnswer: (
      symptom: InputSymptom,
      choiceId: ChoiceId,
      remove: string[] | undefined
    ) => {
      updateSymptom(symptom, choiceId, undefined, remove)
      setLoading()
      if (answerTimeout) {
        window.clearTimeout(answerTimeout)
      }
      answerTimeout = setTimeout(() => {
        onContinue()
      }, 200)
    },
    onAnswerMultiple: (
      symptoms: { symptom: InputSymptom; choiceId: ChoiceId; source: Source }[]
    ) => {
      updateSymptoms(symptoms)
      setLoading()
      if (answerTimeout) {
        window.clearTimeout(answerTimeout)
      }

      answerTimeout = setTimeout(() => {
        onContinue()
      }, 200)
    },
  }
}

export default useInterviewLogic
