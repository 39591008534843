import { FC, useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import { reset } from 'redux/modules/patientInfo'
import useNav, { MenuItem } from 'containers/useNav'
import Link from 'ui/components/generic/FlexibleLink'
import Text from 'ui/components/generic/Text'
import Logo from 'ui/components/generic/Logo'
import { RootState } from 'redux/store'
import { hideOverlay } from 'redux/modules/overlay'

const Navigation: FC = () => {
  const { loading: menuItemsLoading, data: menuItems } = useNav('MAIN_NAV')
  const overlayVisible = useSelector(
    (state: RootState) => state.overlay.visible
  )
  const location = useLocation()
  const dispatch = useDispatch()
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // Hide nav when we change location
  const handleLocationChange = useCallback(() => {
    setNavVisible(false)
  }, [setNavVisible])

  useEffect(handleLocationChange, [location.pathname, handleLocationChange])

  const toggleNav = useCallback(() => {
    setNavVisible((visible) => !visible)
  }, [setNavVisible])

  const handleHome = useCallback(() => {
    // delay reset
    setTimeout(() => {
      dispatch(reset())
    }, 1500)

    dispatch(hideOverlay())
  }, [dispatch])

  return (
    <Wrapper>
      <TopBar>
        <Container fluid>
          <Row>
            <LogoCol xs={6} sm={2}>
              <Link href="/" onClick={handleHome}>
                <Logo negative={false} />
              </Link>
            </LogoCol>

            <NavCol xs={6} sm={10} fillHeight>
              {!menuItemsLoading && menuItems && (
                <Links visible={navVisible}>
                  {menuItems.map((item: MenuItem) => (
                    <Text
                      as={Link}
                      key={item.id}
                      href={item.url}
                      size="xs"
                      onClick={toggleNav}
                      target={item.target || undefined}
                    >
                      {item.label}
                    </Text>
                  ))}
                </Links>
              )}
              <HelpIcon
                onClick={toggleNav}
                overlayVisible={navVisible || overlayVisible}
              >
                <span>?</span>
                <span>✕</span>
              </HelpIcon>
            </NavCol>
          </Row>
        </Container>
      </TopBar>
    </Wrapper>
  )
}

export default Navigation

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`

const TopBar = styled.div`
  position: relative;
  height: ${(props) => props.theme.navHeight}px;
  z-index: 2;
  border-top: 4px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.tones.lightest};

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.tones.lighter};
    left: ${(props) => props.theme.grid.container.padding}px;
    right: ${(props) => props.theme.grid.container.padding}px;
    opacity: 0.5;

    ${media.xs`
      left: ${(props: any) => props.theme.grid.container.paddingXs}px;
      right: ${(props: any) => props.theme.grid.container.paddingXs}px;
    `}
  }

  /** Make container, row fill height **/
  > div,
  > div > div {
    height: 100%;
  }
`

const NavCol = styled(Col)<{ fillHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${(props) => (props.fillHeight ? '100%' : 'auto')};
`

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;

  svg {
    width: 220px;
    height: auto;

    ${media.xs`
      width: 180px;
      height: auto;
    `}
  }
`

const HelpIcon = styled.div<{ overlayVisible: boolean }>`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  transform: rotate(${(props) => (props.overlayVisible ? 360 : 0)}deg);
  transition: 400ms;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    transition: all 400ms;
  }

  span:first-child {
    opacity: ${(props) => (props.overlayVisible ? 0 : 1)};
  }

  span:last-child {
    opacity: ${(props) => (props.overlayVisible ? 1 : 0)};
  }
`

const Links = styled.div<{ visible?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: ${(props) => props.theme.navHeight - 4}px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.tones.lightest};

  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateX(${(props) => (props.visible ? 0 : 20)}px);
  transition: 500ms;
  transition-delay: ${(props) => (props.visible ? 250 : 0)}ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};

  ${media.md`
    display: block;
    height: auto;
    width: auto;
    position: relative;
    top: 0;
    margin-right: 30px;
    right: 0;
    text-align: right;
  `}

  a {
    display: inline-block;
    color: ${(props) => props.theme.colors.tones.dark};
    font-size: 16px;
    margin-bottom: 15px;

    ${media.md`
      font-size: 14px;
      margin-left: 20px;
      margin-bottom: 0;
    `}
  }
`
