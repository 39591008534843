import { connect } from 'react-redux'
import {
  removeSymptom,
  updateSymptom,
  updateSymptoms,
} from 'redux/modules/symptoms'

import { reset } from 'redux/modules/patientInfo'

export default connect(
  (state) => ({
    symptoms: state.symptoms.symptoms,
    answered: state.symptoms.answered,
    questions: state.symptoms.questions,
  }),
  {
    removeSymptom,
    updateSymptom,
    updateSymptoms,
    reset,
  }
)
