import { FC, useState } from 'react'

import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import useSymptoms from 'containers/useSymptoms'
import useDimensions from 'containers/useDimensions'

import theme from 'ui/styles/theme'
import Button from 'ui/components/generic/Button'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'
import SearchInput from './SearchInput'
import usePatientInfo from 'containers/usePatientInfo'

const searchHeight = 60

type Props = {
  onContinue: Function
}

const Symptoms: FC<Props> = ({ onContinue }) => {
  const [search, setSearch] = useState<string>('')
  const { height: windowHeight, breakpoint } = useDimensions()
  const { patient, isChild } = usePatientInfo()
  const {
    symptoms,
    actions: { removeSymptom },
  } = useSymptoms()

  const initialSymptoms = symptoms.filter((s) => s.source === 'initial')
  const symptomsVisible =
    initialSymptoms.length > 0 && (!search || search.length === 0)
  const hint = getHint(initialSymptoms, patient, isChild)
  const hintVisible = !search || search.length === 0
  const searchY = getSearchY(windowHeight, search, initialSymptoms.length > 0)
  const hintY = getHintY(windowHeight, search, initialSymptoms.length > 0)

  return (
    <Wrapper>
      <PaddedContent>
        <Inner height={windowHeight}>
          <Hint visible={hintVisible} y={hintY}>
            {hint}
          </Hint>
          <Search y={searchY}>
            <SearchInput
              symptoms={symptoms}
              onSearch={setSearch}
              autoSearch={breakpoint !== 'xs'}
            />
          </Search>
          <Actions visible={symptomsVisible}>
            <Text size="xs" mb={20}>
              Valgte symptomer
            </Text>
            <SelectedSymptoms>
              <SymptomScroller>
                {initialSymptoms.map((symptom, index) => (
                  <Symptom
                    key={symptom.id}
                    onClick={() => removeSymptom(symptom.id)}
                    present={symptom.choiceId === 'present'}
                    style={{ order: initialSymptoms.length - index }}
                  >
                    {symptom.name}
                  </Symptom>
                ))}
              </SymptomScroller>
            </SelectedSymptoms>
            <Button
              onClick={onContinue ? () => onContinue() : null}
              color="primary"
            >
              Videre
            </Button>
          </Actions>
        </Inner>
      </PaddedContent>
    </Wrapper>
  )
}

const getHint = (
  symptoms: any[],
  patient: 'self' | 'other' | null,
  isChild: boolean
) => {
  switch (symptoms.length) {
    case 0:
      return (
        <p>
          Angiv de symptomer,{' '}
          {patient === 'self' ? 'du' : isChild ? 'barnet' : 'personen'} oplever
          ved hjælp af søgefeltet nedenfor
        </p>
      )
    case 1:
      return <p>Godt. Angiv gerne 1-2 symptomer mere</p>
    case 2:
      return <p>Oplever du andre symptomer?</p>
    default:
      return <p>Godt! Tryk på knappen nedenfor for at gå videre.</p>
  }
}

const getSearchY = (
  windowHeight: number,
  search: string,
  hasSymptoms: boolean
) => {
  let wrapperHeight = windowHeight
  const viewTop = -(wrapperHeight / 2) + searchHeight / 2 + theme.navHeight * 2
  const viewCenter = 0
  const offset = wrapperHeight / 6
  return search ? viewTop : hasSymptoms ? viewCenter : offset
}

const getHintY = (
  windowHeight: number,
  search: string,
  hasSymptoms: boolean
) => {
  let wrapperHeight = windowHeight
  const offset = wrapperHeight / 6
  return hasSymptoms ? 0 : offset
}

export default Symptoms

const Wrapper = styled.div`
  padding-bottom: ${(props) => props.theme.navHeight}px;
`

const Inner = styled.div<{ height: number }>`
  height: ${(props) => props.height - props.theme.navHeight}px;
  min-height: 460px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
`

const Search = styled.div<{ y: number }>`
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin-top: -${searchHeight}px;

  transform: translateY(${(props) => (props.y < 0 ? -100 : 0)}px);
  transition: 1000ms;

  ${media.xs`
    transform: translateY(${(props: any) => props.y}px);
  `}
`

const SelectedSymptoms = styled.div`
  margin-bottom: 20px;

  ${media.xs`
    position: relative;
    display: flex;
    justify-content: center;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      background: rgb(245,245,245);
      background: linear-gradient(90deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
      pointer-events: none;
    }
  `}
`

const SymptomScroller = styled.div`
  ${media.xs`
    display: flex;
    margin: auto;
    overflow-x: auto;
    padding: 15px 0;
  `}
`

const Symptom = styled(Text).attrs({ size: 'sm', weight: 'medium' })<{
  present: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;

  height: 50px;

  text-align: center;
  padding: 10px 30px 10px 50px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 20px;
  max-width: 300px;
  margin: 5px;
  background-color: #fff;
  border-radius: 25px;
  cursor: pointer;

  ${media.xs`
    height: 40px;
    border-radius: 20px;
  `}

  &:before {
    content: '✕';
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 20px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 8px;
  }
`

const Hint = styled(Text).attrs({ size: 'md' })<{
  visible: boolean
  y?: number
}>`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 50%;
  margin-bottom: ${searchHeight}px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : -200)}px);
  transition: 1000ms;
  font-weight: ${(props) => props.theme.fontWeights.bold};

  ${media.xs`
    transform: translateY(${(props: any) =>
      props.visible ? props.y : -200}px);
  `}

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Actions = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  margin-top: ${searchHeight / 2}px;

  transform: translateY(${(props) => (props.visible ? 0 : 30)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 500ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`
