export default id => {
  switch (id) {
    case 'c_133':
      return 'https://www.sundhed.dk/borger/patienthaandbogen/oere-naese-hals/sygdomme/bihulerne/bihulebetaendelse-akut/'
    case 'c_4':
      return 'https://www.sundhed.dk/borger/patienthaandbogen/oere-naese-hals/sygdomme/naesen/hoefeber-allergisk-naesetaethed/'
    default:
      return null
  }
}
