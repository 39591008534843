import React, { Component } from 'react'

export default (offset = 0.75) => WrappedComponent =>
  class WithScrollEntry extends Component {
    constructor(props) {
      super(props)

      this.state = {
        visible: true
      }

      this.wrapper = React.createRef()
    }

    componentDidMount() {
      // window.addEventListener('scroll', this.handleScroll)
      // this.handleScroll()
    }

    componentWillUnmount() {
      // window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
      const rect = this.wrapper.current.getBoundingClientRect()
      if (rect.top < window.innerHeight * offset) {
        window.removeEventListener('scroll', this.handleScroll)
        this.setState({ visible: true })
      }
    }

    render() {
      return (
        <div ref={this.wrapper}>
          <WrappedComponent {...this.props} visible={this.state.visible} />
        </div>
      )
    }
  }
