import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import responsiveSpacing from 'ui/styles/responsiveSpacing'
import Text from 'ui/components/generic/Text'
import PaddedContent from 'ui/components/generic/PaddedContent'

class Header extends Component {
  render() {
    const {
      introduction,
      header,
      post: { title }
    } = this.props

    return (
      <Wrapper>
        <PaddedContent>
          <Heading dangerouslySetInnerHTML={{ __html: header || title }} />
          {introduction && (
            <Introduction dangerouslySetInnerHTML={{ __html: introduction }} />
          )}
        </PaddedContent>
      </Wrapper>
    )
  }
}

export const query = `
  header
  introduction
`

Header.propTypes = {
  header: PropTypes.string,
  introduction: PropTypes.string,
  post: PropTypes.object
}

export default Header

const Wrapper = styled.div`
  ${responsiveSpacing(2, 'padding-top')};
  ${responsiveSpacing(2, 'padding-bottom')};
  background-color: ${props => props.theme.colors.primary};
`

const Heading = styled(Text).attrs({
  size: 'lg'
})`
  margin: 0;
  color: #fff;
`

const Introduction = styled(Text)`
  color: #fff;
  max-width: 800px;
  margin-top: 30px;
`.withComponent('div')
