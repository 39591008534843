import { gql, useQuery } from '@apollo/client'

const useNav = (location: string) => {
  const query = gql`
    query($location: MenuLocation) {
      menuItems(where: { location: $location }) {
        nodes {
          id
          label
          url
          target
          connectedObject {
            ... on Page {
              slug
            }
            ... on Post {
              slug
            }
          }
        }
      }
    }
  `

  const { data, loading, error } = useQuery(query, {
    variables: { location },
  })

  if (error) {
    console.error('Error fetching nav', error)
  }

  return {
    data: parseData(data),
    loading,
    error,
  }
}

export default useNav

export type MenuItem = {
  id: string
  label: string
  url: string
  target: string
  connectedObject?: {
    slug: string
  }
}

const parseData = (data: any) =>
  data
    ? data.menuItems &&
      data.menuItems.nodes.map((n: any) => ({
        ...n,
      }))
    : []
