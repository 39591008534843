import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type OverlayKey = 'about' | 'terms' | 'suggestSymptom' | 'earlyTriage'

export type State = {
  visible: boolean
  content: OverlayKey | null
  data: any
}

export const initialState = {
  visible: false,
  content: null,
  data: null,
} as State

const overlay = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    showOverlay: {
      prepare: (key: OverlayKey, data?: any) => ({
        payload: {
          key,
          data,
        },
      }),
      reducer: (
        state,
        action: PayloadAction<{ key: OverlayKey; data: any }>
      ) => {
        return {
          ...state,
          visible: true,
          content: action.payload.key || state.content,
          data: action.payload.data || state.data,
        }
      },
    },
    hideOverlay: (state, _action: AnyAction) => ({
      ...state,
      visible: false,
    }),
  },
})

export const { showOverlay, hideOverlay } = overlay.actions

export default overlay.reducer
