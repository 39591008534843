import { useState, useEffect, useRef, FC, useCallback } from 'react'

import styled from 'styled-components'
import theme from 'ui/styles/theme'
import negations from 'utils/negations'

import useSymptoms from 'containers/useSymptoms'
import usePatientInfo from 'containers/usePatientInfo'

import Text from 'ui/components/generic/Text'
import IconChevronMedium from 'ui/components/generic/IconChevronMedium'

const Recap: FC = () => {
  const [expanded, setExpanded] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const { symptoms } = useSymptoms()
  const { age, gender, isChild } = usePatientInfo()

  const updateHeight = useCallback(() => {
    if (contentRef.current) {
      const clientHeight = contentRef.current.clientHeight
      if (clientHeight !== contentHeight) {
        setContentHeight(clientHeight)
      }
    }
  }, [contentHeight])

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [updateHeight])

  const initialSymptoms = symptoms.filter((s) => s.source === 'initial')
  const inferredSymptoms = symptoms.filter((s) => s.source !== 'initial')
  const presentSymptoms = inferredSymptoms.filter((s) =>
    negations[s.id] ? s.choiceId === 'absent' : s.choiceId === 'present'
  )
  const absentSymptoms = inferredSymptoms.filter((s) =>
    negations[s.id] ? s.choiceId === 'present' : s.choiceId === 'absent'
  )
  const unknownSymptoms = inferredSymptoms.filter(
    (s) => s.choiceId === 'unknown'
  )

  const showInterview =
    presentSymptoms.length > 0 ||
    absentSymptoms.length > 0 ||
    unknownSymptoms.length > 0

  // We should not be able to be here if age or gender is unset
  if (!(age && gender)) return null

  return (
    <Wrapper>
      <HeaderBox onClick={() => setExpanded(!expanded)}>
        <Header>
          Opsummering
          <ExpandIndicator>
            <Text size="sm" as="span" weight="medium">
              {expanded ? 'Luk' : 'Fold ud'}
            </Text>
            <IconChevronMedium
              direction={expanded ? 'up' : 'down'}
              color={theme.colors.primary}
            />
          </ExpandIndicator>
        </Header>
      </HeaderBox>
      <Outer height={expanded ? contentHeight : 0}>
        <Content ref={contentRef}>
          <Section>
            <GridHeader>Baggrundsinformation</GridHeader>
            <Grid>
              <div>
                <ColumnHeader>Køn</ColumnHeader>
                <Text size="sm">
                  {gender === 'male'
                    ? isChild
                      ? 'Dreng'
                      : 'Mand'
                    : isChild
                    ? 'Pige'
                    : 'Kvinde'}{' '}
                </Text>
              </div>
              <div>
                <ColumnHeader>Alder</ColumnHeader>
                <Text size="sm">
                  {age < 1
                    ? age === 1 / 12
                      ? `1 måned`
                      : `${age * 12} måneder`
                    : `${age} år`}
                </Text>
              </div>
              <div>
                <ColumnHeader>Angivne symptomer</ColumnHeader>
                {initialSymptoms.map((s) => (
                  <Symptom key={s.id}>{s.name}</Symptom>
                ))}
              </div>
            </Grid>
          </Section>
          {showInterview && (
            <Section>
              <GridHeader>Interview</GridHeader>
              <Grid>
                <div>
                  <ColumnHeader>Bekræftede symptomer</ColumnHeader>

                  {presentSymptoms.map((s) => (
                    <Symptom key={s.id}>{s.name}</Symptom>
                  ))}
                </div>
                <div>
                  <ColumnHeader>Afviste symptomer</ColumnHeader>

                  {absentSymptoms.map((s) => (
                    <Symptom key={s.id}>{s.name}</Symptom>
                  ))}
                </div>
                {unknownSymptoms.length > 0 && (
                  <div>
                    <ColumnHeader>Ukendte symptomer</ColumnHeader>
                    {unknownSymptoms.map((symptom) => (
                      <Symptom key={symptom.id}>{symptom.name}</Symptom>
                    ))}
                  </div>
                )}
              </Grid>
            </Section>
          )}
          <Section>
            <Text size="sm">
              <p>
                Hvis du tror, vi har misforstået nogle af symptomerne kan du
                gennemse og eventuelt ændre nogle af svarene ved at bruge
                knappen nedenfor. Du kan også starte forfra.
              </p>
              <p>
                I alle tilfælde anbefales det at søge læge, hvis man er syg
                eller har ondt.
              </p>
            </Text>
          </Section>
        </Content>
      </Outer>
    </Wrapper>
  )
}

export default Recap

const Wrapper = styled.div`
  margin-bottom: 30px;
  border-radius: 25px;
  background-color: #fff;
  padding: 40px;
`

const HeaderBox = styled.div`
  cursor: pointer;
`

const Header = styled(Text).attrs({
  as: 'h1',
  size: 'lg',
  weight: 'medium',
})`
  position: relative;
  margin-bottom: 0;
  padding-right: 110px;
`

const GridHeader = styled(Text).attrs({
  size: 'md',
  as: 'h3',
  weight: 'medium',
})`
  margin-bottom: 20px;
`

const ColumnHeader = styled(Text).attrs({
  size: 'sm',
  as: 'h3',
  weight: 'medium',
})`
  margin-bottom: 20px;
`

const Symptom = styled(Text).attrs({ size: 'xs', as: 'li', weight: 'medium' })`
  position: relative;
  list-style: none;
  padding-left: 20px;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
  }
`

const Section = styled.div`
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.tones.lightest};
`

const Outer = styled.div<{ height: number }>`
  overflow: hidden;
  transition: height 500ms;
  height: ${(props) => props.height}px;
`

const Content = styled.div`
  padding-top: 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
`

const ExpandIndicator = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;

  svg {
    width: 9px;
  }

  span {
    display: block;
    margin-right: 20px;
  }
`
