import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink
} from '@apollo/client'
import { onError } from '@apollo/link-error'

import { RestLink } from 'apollo-link-rest'

import 'isomorphic-fetch'
import possibleTypes from 'possibleTypes.json'

const getApiClient = () => {
  // Setup cache
  const cache = new InMemoryCache({
    possibleTypes
  })

  // Setup content api link
  const publicUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST_NAME}/graphql`
  const localUrl = 'http://proxy/graphql'

  const contentApiLink = new HttpLink({
    uri: process.env.IS_SERVER ? localUrl : publicUrl,
    headers: process.env.IS_SERVER ? { pragma: 'no-cache' } : {}
  })

  // Setup medical api link
  const medicalApiLink = new RestLink({
    uri: process.env.IS_SERVER
      ? 'http://proxy/api'
      : process.env.REACT_APP_API_URI,
    headers: {
      'Dev-Mode': process.env.NODE_ENV === 'development'
    }
  })

  // Setup error link
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )

        // Ignore graphql errors so we can let the ui handle 404s
        if (response) {
          response.errors = null
        }

        return null
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  // Put it all together
  return new ApolloClient({
    link: ApolloLink.from([errorLink, medicalApiLink, contentApiLink]),
    cache: process.env.IS_SERVER
      ? cache
      : cache.restore(window.__APOLLO_STATE__),
    ssrMode: process.env.IS_SERVER
  })
}

export default getApiClient
