import {
  FC,
  useEffect,
} from 'react'

import { useLocation } from 'react-router'

const Analytics: FC = () => {
  const { pathname, search, hash } = useLocation()
  const location = `${pathname}${search}${hash}`

  useEffect(() => {
    if (window && window._paq) {
      // We do not want to track all the individual questions of an interview.
      const dontTrackInterviewQuestions = /^\/interview\/[^/]+$/.test(location)
      if (!dontTrackInterviewQuestions) {
        window._paq.push(['setCustomUrl', location])
        window._paq.push(['trackPageView'])
      }
    }
  }, [location])
  return null
}

export default Analytics
