import React, { Component } from 'react'
import styled from 'styled-components'
import RangeSliderComponent from 'react-rangeslider'

import Text from 'ui/components/generic/Text'

const Wrapper = styled.div`
  margin: 20px 0;
`

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`

const Slider = styled(RangeSliderComponent)`
  position: relative;
  background: ${props => props.theme.colors.tones.lighter};
  -ms-touch-action: none;
  touch-action: none;
  height: 12px;
  border-radius: 10px;
  display: block;

  .rangeslider__fill {
    display: block;
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 10px;
    top: 0;
  }
  .rangeslider__handle {
    background: #fff;
    border: 1px solid ${props => props.theme.colors.primary};
    cursor: pointer;
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    outline-style: none;
    box-shadow: none;

    .rangeslider__active {
      opacity: 1;
    }
  }

  .rangeslider__handle-tooltip {
    display: none;
  }
`

class RangeSlider extends Component {
  render() {
    const { value, onChange, min, max } = this.props
    return (
      <Wrapper>
        <Slider
          min={min}
          max={max}
          value={value}
          onChangeStart={this.handleChangeStart}
          onChange={onChange}
          onChangeComplete={this.handleChangeComplete}
        />
        <Labels>
          <Text size="lg">-</Text>
          <Text size="lg">+</Text>
        </Labels>
      </Wrapper>
    )
  }
}

export default RangeSlider
