import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import usePatientInfo from './usePatientInfo'

const useSearchedSymptoms = (search: string) => {
  const { gender, interviewId, age } = usePatientInfo()

  const result = useQuery(
    gql`
      query searchedSymptoms {
        searchedSymptoms(
          phrase: $phrase
          types: $types
          interviewId: $interviewId
          age: $age
          sex: $sex
          pathBuilder: $pathBuilder
        )
          @rest(
            type: "SearchedSymptom"
            pathBuilder: $pathBuilder
            method: "GET"
          ) {
          id
          commonName
          name
          match
          parentId
          label
        }
      }
    `,
    {
      variables: {
        phrase: search,
        sex: gender,
        age,
        interviewId,
        types: 'risk_factor,symptom',
        pathBuilder: ({
          args: { phrase, sex, age, interviewId, types },
        }: any) =>
          `search?phrase=${encodeURIComponent(phrase)}&sex=${sex}&age.value=${
            age < 1 ? age * 12 : age
          }&age.unit=${
            age < 1 ? 'month' : 'year'
          }&interviewId=${interviewId}&types=${types}&include_pro=false`,
      },
      ssr: false,
      skip: gender === null || age === null,
    }
  )

  return {
    data:
      !result.loading &&
      (result?.data?.searchedSymptoms as SearchedSymptom[] | undefined),
    loading: result.loading,
  }
}

export default useSearchedSymptoms

export type SearchedSymptom = {
  id: string
  commonName: string
  name: string
  match?: string
  parentId: string
  label?: string
}
