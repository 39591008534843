import { FC } from 'react'
import styled from 'styled-components'

import Text from 'ui/components/generic/Text'
import Spinner from 'ui/components/generic/Spinner'
import IconSundhedDK from 'ui/components/generic/IconSundhedDK'
import getConditionLink from 'utils/getConditionLink'
import { useCondition } from 'containers/useCondition'
import useSymptoms from 'containers/useSymptoms'
import { useConditionExplanation } from 'containers/useConditionExplanation'
import RoundButton from 'ui/components/generic/RoundButton'
import IconClose from 'ui/components/generic/IconClose'
import useDrawer from 'containers/useDrawer'
import { media } from 'styled-bootstrap-grid'

type Props = {
  probability: number
  customData: {
    link: string
    linkSource: string
    description: string
  }
  id: string
}

const ConditionDetails: FC<Props> = ({ probability, id, customData }) => {
  const { data: condition } = useCondition(id)
  const { symptoms } = useSymptoms()
  const {
    actions: { hideDrawer },
  } = useDrawer()

  const { data: conditionExplanation } = useConditionExplanation(id)
  const isPresent = (symptomId: string) => {
    return symptoms.find((s) => s.id === symptomId)?.choiceId === 'present'
  }

  const getProbabilityString = () => {
    if (probability > 0.8) return 'høj'
    if (probability > 0.5) return 'nogen'
    return 'lav'
  }

  if (!condition || !conditionExplanation) {
    return (
      <Loading>
        <Spinner />
      </Loading>
    )
  }

  const conditionLink = customData.link || getConditionLink(id)

  const name = condition.common_name || condition.name

  return (
    <Wrapper>
      <Header>
        <Primary>
          <Title>{getConditionTitle(condition)}</Title>
        </Primary>
        {conditionLink && !condition.source && (
          <Link href={conditionLink} target="_blank" rel="noreferrer">
            <Text size="xs" color="#fff">
              Læs mere på sundhed.dk
            </Text>
            <IconSundhedDK color="#fff" />
          </Link>
        )}
        <CloseButton onClick={hideDrawer}>
          <IconClose />
        </CloseButton>
      </Header>
      <Content>
        {customData.description && (
          <>
            <h1>Om</h1>
            <Description size="sm">{customData.description}</Description>
          </>
        )}
        <Box>
          <EvidenceHeader>
            Dine symptomer passer i{' '}
            <strong>{getProbabilityString()} grad</strong> til{' '}
            <em>{name.toLowerCase()}</em>:
          </EvidenceHeader>
          <Meter value={probability} />
          <EvidenceSymptoms>
            <SymptomList>
              {conditionExplanation.supporting_evidence &&
                conditionExplanation.supporting_evidence.map((e: any) => (
                  <Symptom key={e.id}>
                    <PresenceIndicator isPresent={isPresent(e.id)} />
                    {e.common_name}
                  </Symptom>
                ))}
            </SymptomList>
          </EvidenceSymptoms>
          {conditionExplanation.conflicting_evidence &&
            conditionExplanation.conflicting_evidence.length > 0 && (
              <EvidenceSymptoms>
                <EvidenceHeader>
                  Fravær af følgende symptomer kan tale <strong>imod</strong>{' '}
                  <em>{name.toLowerCase()}</em>
                </EvidenceHeader>

                <SymptomList>
                  {conditionExplanation.conflicting_evidence.map((e: any) => (
                    <Symptom>
                      <PresenceIndicator isPresent={isPresent(e.id)} />
                      {e.common_name}
                    </Symptom>
                  ))}
                </SymptomList>
              </EvidenceSymptoms>
            )}
          {conditionExplanation.unconfirmed_evidence &&
            conditionExplanation.unconfirmed_evidence.length > 0 && (
              <EvidenceSymptoms>
                <h1>Følgende symptomer kan også tale for {name}</h1>
                <div>
                  {conditionExplanation.unconfirmed_evidence.map((e: any) => (
                    <Symptom key={e.id}>{e.common_name}</Symptom>
                  ))}
                </div>
              </EvidenceSymptoms>
            )}
        </Box>
        <BigLink
          href={conditionLink || 'https://www.sundhed.dk/borger/borger-soeg/'}
          target="_blank"
          rel="noreferrer"
        >
          <Text size="xs">
            {conditionLink ? 'Læs om' : 'Søg efter'} {name}
            {!condition.source ? ' på sundhed.dk' : ''}
          </Text>
          <IconSundhedDK color="#fff" />
        </BigLink>
      </Content>
    </Wrapper>
  )
}

export default ConditionDetails

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 170px;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 40px;
`

const Primary = styled.div`
  flex-grow: 1;
`

const Title = styled(Text).attrs({ size: 'xl', weight: 'medium' })`
  color: #fff;
  line-height: 1.1;
`

const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;

  ${media.xs`
    display: none;
  `}

  svg {
    margin-top: 5px;
    display: block;
    width: 130px;
  }
`

const Content = styled.div`
  padding: 30px;

  h1 {
    margin-bottom: 20px;
    line-height: 1;
  }
`

const Description = styled(Text)`
  color: ${(props) => props.theme.colors.tones.medium};
  margin-bottom: 20px;
`

const Box = styled.div`
  display: block;
  padding: 30px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.colors.tones.lightest};
  margin-bottom: 20px;
  color: inherit;
  max-width: 100%;
`

const EvidenceHeader = styled(Text).attrs({ size: 'xs' })`
  margin-bottom: 20px;
`

const Meter = styled.div<{ value: number }>`
  position: relative;
  height: 8px;
  border-radius: 8px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 5px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: ${(props) => props.value * 100}%;
    background-color: ${(props) => props.color || props.theme.colors.primary};
  }
`

const EvidenceSymptoms = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  /* border-top: 1px solid ${(props) => props.theme.colors.tones.lighter}; */

  h1 {
    font-weight: ${(props) => props.theme.fontWeights.medium};
    margin-bottom: 20px;

    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
`

const SymptomList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 10px;
  row-gap: 10px;
`

const Symptom = styled(Text).attrs({ size: 'xs' })`
  display: flex;
  align-items: center;
`

const PresenceIndicator = styled.div<{ isPresent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 50%;

  background-color: ${(props) =>
    props.isPresent
      ? props.theme.colors.primary
      : props.theme.colors.tones.light};

  &:before {
    content: '${(props) => (props.isPresent ? '+' : '-')}';
    color: #fff;
    font-size: 15px;
  }

  ${media.sm`
    margin-right: 15px;
  `}
`

const BigLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }

  svg {
    width: 230px;
    margin-top: 10px;
  }
`

const CloseButton = styled(RoundButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.primaryDark};

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryDarker};
  }
`

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`

const getConditionTitle = (c: { name: string; common_name: string }) => {
  const name = c.name
  const commonName = c.common_name

  return `${commonName} ${name !== commonName ? `(${name})` : ''}`
}
