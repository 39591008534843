import React from 'react'
import theme from 'ui/styles/theme'

export default () => (
  <svg
    width={45}
    height={59}
    viewBox="0 0 45 59"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M29.9 0v18c-2.7-1.2-5.6-1.9-8.8-1.9C9.5 16 0 25.5 0 37.2c0 11.6 9.5 21.1 21.1 21.1 3.1 0 6-.7 8.7-1.9v1.4h14.7V0H29.9z"
        fill={theme.colors.primary}
      />
      <path
        d="M22.2 48.8c-1.3-2.3-5.3-4.8-5.3-4.8-7.3-4-6.8-8.6-6.8-8.6.5-6.7 6.2-6.5 6.2-6.5 4 0 5.9 3.3 5.9 3.3h.1s1.9-3.3 5.9-3.3c0 0 5.8-.2 6.2 6.5 0 0 .6 4.6-6.8 8.7 0-.1-4.1 2.4-5.4 4.7"
        fill="#FFF"
      />
    </g>
  </svg>
)
