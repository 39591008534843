import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'

import routes from 'routes'
import withSymptoms from 'containers/withSymptoms'

class ResetHandler extends Component {
  static propTypes = {
    location: PropTypes.object,
    reset: PropTypes.func
  }

  componentDidMount() {
    // this is only run on first load
    const { location, reset } = this.props

    if (location.pathname === '/' || location.pathname === routes.covid19) {
      reset()
    }
  }

  componentDidUpdate(prevProps) {
    const { location, reset } = this.props

    if (prevProps.location.pathname !== location.pathname) {
      // this is run on every route change
      if (location.pathname === routes.covid19) {
        reset()
      }
    }
  }

  render() {
    return null
  }
}

export default compose(withRouter, withSymptoms)(ResetHandler)
