import styled, { css, keyframes } from 'styled-components'

export const Relative = styled.div`
  position: relative;
`

const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const SlideIn = styled.div`
  opacity: 0;
  transform: translateY(-20px);

  ${props =>
    props.visible &&
    css`
      animation: ${slideInAnimation} 500ms ${props.delay || 0}ms both;
    `}

  ${props =>
    props.fillWidth &&
    css`
      display: block;
      width: 100%;
    `}
`

export const Margin = styled.div``
