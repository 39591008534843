import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import responsiveSpacing from 'ui/styles/responsiveSpacing'

const entry = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const getSpacing = (type, nextType) => {
  return 2
}

const Wrapper = styled.div`
  animation: ${entry} 700ms ${props => props.index * 200 + 200}ms both;
  ${props => responsiveSpacing(getSpacing(props.type, props.nextType))}
`

class Module extends Component {
  render() {
    const { type, nextType, children, ...rest } = this.props
    return (
      <Wrapper
        index={this.props.index}
        type={type}
        nextType={nextType}
        {...rest}
      >
        {children}
      </Wrapper>
    )
  }
}

export default Module
