import { useCallback, useState } from 'react'
import styled from 'styled-components'

import theme from 'ui/styles/theme'
import { SlideInOutGroup, SlideInOut } from 'ui/styles/poses'

import usePatientInfo from 'containers/usePatientInfo'
import useSubmitRating from 'containers/useSubmitRating'
import Text from 'ui/components/generic/Text'
import Button from 'ui/components/generic/Button'
import Spinner from 'ui/components/generic/Spinner'
import IconHeart from 'ui/components/generic/IconHeart'
import times from 'lodash/times'

const Feedback = () => {
  const { mutate: submitRating, loading, data: result } = useSubmitRating()
  const { interviewId } = usePatientInfo()

  const [hover, setHover] = useState<number | null>(null)
  const [rating, setRating] = useState<number | null>(null)
  const [comment, setComment] = useState('')

  const valid = rating !== null

  console.log('result', result)

  const done = result && result.success

  const submit = useCallback(() => {
    if (interviewId && (rating !== null || comment)) {
      submitRating({
        variables: {
          input: {
            interviewId,
            rating,
            comment,
          },
        },
      })
    }
  }, [interviewId, rating, comment, submitRating])

  return (
    <Wrapper>
      <Box>
        <Header>Hjælp os med at blive bedre</Header>

        <SlideInOutGroup>
          {!loading && !done && (
            <SlideInOut key="form" direction="left" amount={20}>
              <SubHeader>Hvordan var din oplevelse med servicen?</SubHeader>
              <Hearts>
                {times(5, (i) => (
                  <Heart
                    key={i}
                    onMouseEnter={() => setHover(i)}
                    onMouseLeave={() => setHover(null)}
                    onClick={() => setRating(rating === i ? null : i)}
                  >
                    <IconHeart
                      fill={
                        (hover !== null && hover >= i) ||
                        (rating !== null && rating >= i)
                          ? theme.colors.primary
                          : 'transparent'
                      }
                      stroke={theme.colors.primary}
                    />
                  </Heart>
                ))}
              </Hearts>
              <SubHeader>Uddyb gerne din feedback, hvis du har lyst</SubHeader>
              <InputLabel>
                <strong>OBS!</strong> Indtast aldrig personfølsomme oplysninger,
                som dit navn eller e-mail i dette felt.
              </InputLabel>
              <Input
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                placeholder="Skriv din kommentar her.."
              />
              <Button
                color="primary"
                disabled={!valid}
                onClick={valid ? submit : undefined}
              >
                Send feedback
              </Button>
              {loading && <Spinner />}
            </SlideInOut>
          )}
          {!loading && done && (
            <SlideInOut key="success" direction="left" amount={20}>
              <Text size="xs">Mange tak for din feedback.</Text>
            </SlideInOut>
          )}
        </SlideInOutGroup>
      </Box>
    </Wrapper>
  )
}

export default Feedback

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Box = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 25px;
  overflow: hidden;
`

const Header = styled(Text).attrs({
  as: 'h1',
  size: 'lg',
})`
  font-weight: ${(props) => props.theme.fontWeights.medium};
`

const SubHeader = styled(Text).attrs({
  as: 'h4',
  size: 'xs',
})`
  margin-bottom: 10px;
`

const Hearts = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

const Heart = styled.div`
  padding: 0 3px;
  cursor: pointer;
`

const Input = styled.textarea.attrs({ rows: 4 })`
  border: 1px solid ${(props) => props.theme.colors.tones.lightest};
  margin-bottom: 20px;
  padding: 20px;
`

const InputLabel = styled(Text).attrs({ size: 'xxs' })`
  margin-bottom: 10px;
`
