import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'

const Wrapper = styled.div`
  padding: 50px 0;
`

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

class Embed extends Component {
  render() {
    const { embed } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={10} mdOffset={1}>
              <EmbedContainer dangerouslySetInnerHTML={{ __html: embed }} />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default Embed
