import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'

import Text from 'ui/components/generic/Text'
import withScrollEntry from 'ui/styles/withScrollEntry'
import { SlideIn } from 'ui/styles/utils'
import Links from 'ui/components/generic/Links'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const CenteredCol = styled(Col)`
  /* A bug in Col causes first line of extended styles to break */
  display: flex;
  align-items: center;
`

const Header = styled(Text).attrs({ as: 'h2', size: 'md' })``

const TextCol = styled.div`
  position: relative;

  margin-bottom: 30px;

  ${media.lg`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  `}
`

const ImageCol = styled.div`
  display: flex;
  width: 100%;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    width: 200px;
  }
`

export const query = `
  header
  body
  buttons {
    text
    layout
    type
    externalLink
    internalLink {
      ...on Page {
        link
      }
    }
  }
  image {
    sourceUrl
    altText
  }
  orientation
`

class ImageAndText extends Component {
  static propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    buttons: PropTypes.array,
    image: PropTypes.object,
    orientation: PropTypes.string,
    visible: PropTypes.bool
  }

  render() {
    const { header, body, buttons, image, orientation, visible } = this.props

    return (
      <Wrapper>
        <Container fluid>
          <Row justifyContent="center">
            <CenteredCol
              md={5}
              mdOffset={orientation === 'left' ? 0 : 1}
              order={orientation === 'left' ? 2 : 1}
            >
              <SlideIn
                visible={visible}
                delay={orientation === 'left' ? 200 : 0}
              >
                <TextCol>
                  <Header>{header}</Header>
                  <Text size="sm" dangerouslySetInnerHTML={{ __html: body }} />
                  <Links links={buttons} color="primary" />
                </TextCol>
              </SlideIn>
            </CenteredCol>
            <CenteredCol
              md={5}
              mdOffset={orientation === 'left' ? 1 : 0}
              order={orientation === 'left' ? 1 : 2}
            >
              <SlideIn
                visible={visible}
                delay={orientation === 'right' ? 200 : 0}
                fillWidth
              >
                <ImageCol>
                  <img src={image.sourceUrl} alt={image.altText} />
                </ImageCol>
              </SlideIn>
            </CenteredCol>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export default withScrollEntry()(ImageAndText)
